import React from 'react'
import Header from '../../components/common/header/Header'
import Footer from '../../components/common/footer/Footer'

const Change_Password = () => {
  return (
    <div class="wrapper">
 
    <div id="content">
      <Header/>
      <div class="container">
        <div class="card tb-10">

          <div class="text-center tb-10">
            <h3>Change Password</h3>
            <span>Update Your Profile Password</span>
          </div>
          <form action="" method="POST" autocomplete="off">
            <div class="form-group">
              <label for="name">Old Password:</label>
              <input type="text" class="form-control" name="old_password" minlength="3" maxlength="50"
                placeholder="Existing Password" id="old_password" autocomplete="off" required=""/>
            </div>
            <div class="form-group">
              <label for="username">New Password:</label>
              <input type="text" class="form-control" name="new_password" minlength="3" maxlength="50"
                placeholder="Enter New Password" id="new_password" autocomplete="off" required=""/>
            </div>
            <div class="form-group">
              <label for="mobile">Confirm Password:</label>
              <input type="text" class="form-control" name="confirm_new_password" minlength="3" maxlength="50"
                placeholder="Confirm Password" id="confirm_new_password" autocomplete="off" required=""/>
            </div>
            <input type="hidden" name="api_acess_token" value=""/>
            <button type="submit" name="change_password" class="btn btn-theme btn-login">Submit</button>
          </form>
        </div>
      </div>
    </div>
    <br/>
    <br/>
    <Footer/>
    </div>
  )
}

export default Change_Password