import React, { useEffect, useState } from 'react'
import Header from '../../common/header/Header'
import full_sangam from "../../../assets/images/full_sangam.png"
import half_sangam from "../../../assets/images/half_sangam.png"
import Single_Digit from "../../../assets/images/single.png"
import double_Digit from "../../../assets/images/jodi.png"
import single_pana from "../../../assets/images/single_patti.png"
import double_Pana from "../../../assets/images/double_patti.png"
import triple_Pana from "../../../assets/images/triple_patti.png"
import { Link, useNavigate } from 'react-router-dom'
import { CheckGameStatus } from '../../../api/AllApi';

const Game_Dashboard = () => {
  const navigate = useNavigate();
  const [gameStatus, setGameStatus] = useState({});
  // const user_id = localStorage.getItem("user_id");
  const game_id = localStorage.getItem("game_id");

  const HandleGetCurrentTime = async () => {
    const GetUserCurrentTimeData = {
      app_key: "@34Y&D9(JE4dsi36f$%#(tigergames!xyz)8fe8ef8ef8",
      game_id
    }
    try {
      const response = await CheckGameStatus(GetUserCurrentTimeData)
      console.log("HandleGetCurrentTime", response?.data)
      setGameStatus(response?.data)
    } catch (error) {
      console.log("error", error)
    }
  }

  const NavigateToSingleDigitPage = () => {
    navigate("/single")
  }
  const PreventToSingleDigitPage = () => {
    alert("Sorry betting is Close")
  }
  const NavigateToJodiDigitPage = () => {
    navigate("/jodi")
  }
  const NavigateToSinglePanaPage = () => {
    navigate("/starLine_single_patti")
  }
  const NavigateToDoublePanaPage = () => {
    navigate("/double_patti")
  }
  const NavigateToTriplePanaPage = () => {
    navigate("/triple_patti")
  }

  const NavigateToHalfSangamPanaPage = () => {
    navigate("/half_sangam")
  }

  const NavigateToFullSangamPanaPage = () => {
    navigate("/full_sangam")
  }



  useEffect(() => {
    HandleGetCurrentTime()
  }, [])
  return (
    <div>
      <div className="wrapper">

        <div id="content">
          <Header />

          <div className="container">
            <div className="card-full-page tb-10">

              <div className="text-center tb-10">
                <h3 className="gdash3">Milan day Dashboard</h3>
                <span>Select Bidding Option</span>
              </div>
              <div className="tb-10">&nbsp;</div>

              <div className="row bidoptions-list tb-10">

                <div className="col-4" onClick={(e) => {
                  if (gameStatus?.game_status == "1") {
                    NavigateToSingleDigitPage()
                  }
                  else if (gameStatus?.game_status == "2") {
                    PreventToSingleDigitPage()
                  }
                }}>
                  <Link to="#" className="bidtypebox">
                    <img src={Single_Digit} />
                    <p>Single Digit</p>
                  </Link>
                </div>
                <div className="col-4" onClick={(e) => {
                  if (gameStatus?.game_status == "1") {
                    NavigateToJodiDigitPage()
                  }
                  else if (gameStatus?.game_status == "2") {
                    PreventToSingleDigitPage()
                  }
                }}>
                  <Link to="#" className="bidtypebox">
                    <img src={double_Digit} />
                    <p>Jodi Digit</p>
                  </Link>
                </div>
                <div className="col-4" onClick={(e) => {
                  if (gameStatus?.game_status == "1") {
                    NavigateToSinglePanaPage()
                  }
                  else if (gameStatus?.game_status == "2") {
                    PreventToSingleDigitPage()
                  }
                }}>
                  <Link to="#" className="bidtypebox">
                    <img src={single_pana} />
                    <p>Single Pana</p>
                  </Link>
                </div>
              </div>
              <div className="row bidoptions-list tb-10">
                <div className="col-4" onClick={(e) => {
                  if (gameStatus?.game_status == "1") {
                    NavigateToDoublePanaPage()
                  }
                  else if (gameStatus?.game_status == "2") {
                    PreventToSingleDigitPage()
                  }
                }}>
                  <Link to="#" className="bidtypebox">
                    <img src={double_Pana} />
                    <p>Double Pana</p>
                  </Link>
                </div>
                <div className="col-4" onClick={(e) => {
                  if (gameStatus?.game_status == "1") {
                    NavigateToTriplePanaPage()
                  }
                  else if (gameStatus?.game_status == "2") {
                    PreventToSingleDigitPage()
                  }
                }}>
                  <Link to="#" className="bidtypebox">
                    <img src={triple_Pana} />
                    <p>Triple Pana</p>
                  </Link>
                </div>
                <div className="col-4" onClick={(e) => {
                  if (gameStatus?.game_status == "1") {
                    NavigateToHalfSangamPanaPage()
                  }
                  else if (gameStatus?.game_status == "2") {
                    PreventToSingleDigitPage()
                  }
                }}>
                  <Link to="#" className="bidtypebox">
                    <img src={half_sangam} />
                    <p>Half Sangam</p>
                  </Link>
                </div>
              </div>
              <div className="row bidoptions-list tb-10">
                <div className="col-4" >
                </div>
                <div className="col-4" onClick={(e) => {
                  if (gameStatus?.game_status == "1") {
                    NavigateToFullSangamPanaPage()
                  }
                  else if (gameStatus?.game_status == "2") {
                    PreventToSingleDigitPage()
                  }
                }}>
                  <Link to="#" className="bidtypebox">
                    <img src={full_sangam} />
                    <p>Full Sangam</p>
                  </Link>
                </div>
                <div className="col-4">
                </div>
              </div>
              <div className="tbmar-40 text-center">
                <span>Note: Betting is Running Now </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br /><br /><br />
    </div>
  )
}

export default Game_Dashboard