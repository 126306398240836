import React from 'react'
import Header from '../../common/header/Header'
import Footer from '../../common/footer/Footer'

const Top_Winner_List_Starline = () => {
  return (
    <div>
         <div className="wrapper">
    <div id="content">
     <Header/>
      <div className="container">
        <div className="tb-10" style={{textAlign:"center"}}>
          <h1 className="gdash3" style={{fontSize:"22px"}}> Top Starline Winner List</h1>
          <span style={{fontSize:"12px"}}>List of Today's top winners with amount</span>

          <div className="row game-list-inner">
            <div className="col-12 game-rates">
              <h2 style={{fontSize:"16px",color:"var(--primary-light)"}}>Ra*****il</h2>
              <p>Amount : <span>24000</span></p>
              <p>Game : <span>12:15 PM</span></p>
              <p>Digit : <span>1</span></p>
              <p>Time : <span>2024-07-24 12:18:07 PM</span></p>

            </div>
          </div>
          <div className="row game-list-inner">
            <div className="col-12 game-rates">
              <h2 style={{fontSize:"16px",color:"var(--primary-light)"}}>Mi************************nd</h2>
              <p>Amount : <span>20000</span></p>
              <p>Game : <span>02:15 PM</span></p>
              <p>Digit : <span>4</span></p>
              <p>Time : <span>2024-07-24 02:17:07 PM</span></p>

            </div>
          </div>
          <div className="row game-list-inner">
            <div className="col-12 game-rates">
              <h2 style={{fontSize:"16px",color:"var(--primary-light)"}}>SO*********NE</h2>
              <p>Amount : <span>20000</span></p>
              <p>Game : <span>02:15 PM</span></p>
              <p>Digit : <span>4</span></p>
              <p>Time : <span>2024-07-24 02:17:07 PM</span></p>

            </div>
          </div>
          <div className="row game-list-inner">
            <div className="col-12 game-rates">
              <h2 style={{fontSize:"16px",color:"var(--primary-light)"}}>Mi************************nd</h2>
              <p>Amount : <span>20000</span></p>
              <p>Game : <span>03:15 PM</span></p>
              <p>Digit : <span>8</span></p>
              <p>Time : <span>2024-07-24 03:16:07 PM</span></p>

            </div>
          </div>
          <div className="row game-list-inner">
            <div className="col-12 game-rates">
              <h2 style={{fontSize:"16px",color:"var(--primary-light)"}}>SO*********NE</h2>
              <p>Amount : <span>20000</span></p>
              <p>Game : <span>03:15 PM</span></p>
              <p>Digit : <span>8</span></p>
              <p>Time : <span>2024-07-24 03:16:07 PM</span></p>

            </div>
          </div>
          <div className="row game-list-inner">
            <div className="col-12 game-rates">
              <h2 style={{fontSize:"16px",color:"var(--primary-light)"}}>Mi************************nd</h2>
              <p>Amount : <span>20000</span></p>
              <p>Game : <span>04:15 PM</span></p>
              <p>Digit : <span>1</span></p>
              <p>Time : <span>2024-07-24 04:16:07 PM</span></p>

            </div>
          </div>
          <div className="row game-list-inner">
            <div className="col-12 game-rates">
              <h2 style={{fontSize:"16px",color:"var(--primary-light)"}}>SO*********NE</h2>
              <p>Amount : <span>20000</span></p>
              <p>Game : <span>04:15 PM</span></p>
              <p>Digit : <span>1</span></p>
              <p>Time : <span>2024-07-24 04:16:07 PM</span></p>

            </div>
          </div>
          <div className="row game-list-inner">
            <div className="col-12 game-rates">
              <h2 style={{fontSize:"16px",color:"var(--primary-light)"}}>SO*********NE</h2>
              <p>Amount : <span>20000</span></p>
              <p>Game : <span>01:15 PM</span></p>
              <p>Digit : <span>9</span></p>
              <p>Time : <span>2024-07-24 01:17:07 PM</span></p>

            </div>
          </div>
          <div className="row game-list-inner">
            <div className="col-12 game-rates">
              <h2 style={{fontSize:"16px",color:"var(--primary-light)"}}>Mi************************nd</h2>
              <p>Amount : <span>20000</span></p>
              <p>Game : <span>12:15 PM</span></p>
              <p>Digit : <span>1</span></p>
              <p>Time : <span>2024-07-24 12:18:07 PM</span></p>

            </div>
          </div>
          <div className="row game-list-inner">
            <div className="col-12 game-rates">
              <h2 style={{fontSize:"16px",color:"var(--primary-light)"}}>Mi************************nd</h2>
              <p>Amount : <span>20000</span></p>
              <p>Game : <span>11:15 AM</span></p>
              <p>Digit : <span>8</span></p>
              <p>Time : <span>2024-07-24 11:20:07 AM</span></p>

            </div>
          </div>

        </div>
      </div>
  <br/><br/><br/>
    </div>
  </div>
  <Footer/>
    </div>
  )
}

export default Top_Winner_List_Starline