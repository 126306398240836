import React, { useEffect, useState } from 'react'
import Header from '../../common/header/Header'
import { Link, useNavigate } from 'react-router-dom'
import { GetStarlineGameList } from '../../../api/AllApi';

const StarLine_Play = () => {
  const navigate = useNavigate();
  const user_id = localStorage.getItem("user_id");
  const [StarlineGameList, setStarlineGameList] = useState([]);

  const HandleGetStarLineGameList = async () => {
    const data = {
      app_key: "@34Y&D9(JE4dsi36f$%#(tigergames!xyz)8fe8ef8ef8",
      user_id
    }
    try {
      const response = await GetStarlineGameList(data)
      setStarlineGameList(response?.data?.result)
    } catch (error) {
      console.log("error", error)
    }
  }
  console.log("StarlineGameList", StarlineGameList)

  const OpenBetting = (starLine) => {
    navigate("/starLine_game_dashBoard");
    localStorage.setItem("starline_game_id",starLine.game_id)
    localStorage.setItem("starline_game_name",starLine.game_name)

  }

  const BettingClose = () => {
    alert("Betting is closed for today")
  }

  useEffect(() => {
    HandleGetStarLineGameList();
  }, [])
  return (
    <div className="wrapper">

      <div id="content">
        <Header />
        <div id="scroll-container" className="noticebr">
          <div id="scroll-text" style={{ whiteSpace: "nowrap" }}>Withdrawal Timing 08 AM To 11 Pm Din Mein Kitni Bar Bhi
            Withdrawal Le Sakte Hain</div>
        </div>

        <div className="container text-center ">
          <div className="tb-10">
            <div className="row">
              <div className="col-6">
                <a href="#" className="home-sl-box">Play Big &amp; Win Big <br /> <span>Har Ghante Jeeto</span></a>
              </div>
              <div className="col-6">
                <Link to="/starLine_chart_history" className="home-sl-box">Starline Chart <br /> <span> View Old
                  Record</span></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container text-center">
          <div className="tb-10">



            {/* <div className="row game-list-inner">
              <div className="col-4">
                <span className="sgameName"> 09:15 AM </span>
              </div>
              <div className="col-4">
                <span className="sgameResut"> 468-8 </span>
              </div>
              <div className="col-4 splaydiv">
              </div>
            </div> */}


            {/* <div className="row game-list-inner">
              <div className="col-4">
                <span className="sgameName"> 10:15 AM </span>
              </div>
              <div className="col-4">
                <span className="sgameResut"> 135-9 </span>
              </div>
              <div className="col-4 splaydiv">

              </div>
            </div> */}


            {/* <div className="row game-list-inner">
              <div className="col-4">
                <span className="sgameName"> 11:15 AM </span>
              </div>
              <div className="col-4">
                <span className="sgameResut"> 468-8 </span>
              </div>
              <div className="col-4 splaydiv">

              </div>
            </div> */}


            {/* <div className="row game-list-inner">
              <div className="col-4">
                <span className="sgameName"> 12:15 PM </span>
              </div>
              <div className="col-4">
                <span className="sgameResut"> 128-1 </span>
              </div>
              <div className="col-4 splaydiv">

              </div>
            </div>


            <div className="row game-list-inner">
              <div className="col-4">
                <span className="sgameName"> 01:15 PM </span>
              </div>
              <div className="col-4">
                <span className="sgameResut"> 890-7 </span>
              </div>
              <div className="col-4 splaydiv">

              </div>
            </div>


            <div className="row game-list-inner">
              <div className="col-4">
                <span className="sgameName"> 02:15 PM </span>
              </div>
              <div className="col-4">
                <span className="sgameResut"> 560-1 </span>
              </div>
              <div className="col-4 splaydiv">

              </div>
            </div> */}

            {
              StarlineGameList?.map((StarlineGameListResult) => {
                return (
                  <>
                    <div className="row game-list-inner">
                      <div className="col-4">
                        <span className="sgameName">{StarlineGameListResult?.open_time}</span>
                        {
                          StarlineGameListResult?.msg_status === 1 ? (
                            <span className="sgameName text-success">{StarlineGameListResult?.msg}</span>

                          ) : (
                            <span className="sgameName text-danger">{StarlineGameListResult?.msg}</span>

                          )
                        }
                      </div>
                      <div className="col-4">
                        <span className="sgameResut"> </span>
                      </div>
                      {
                        StarlineGameListResult?.msg_status === 1 ? (
                          <>
                            <div className="col-4 splaydiv">
                              <Link to="/starLine_game_dashBoard" onClick={()=>OpenBetting(StarlineGameListResult)} className="sgame-play"> Play</Link>
                            </div>
                          </>

                        ) : (
                          <>
                            <div className="col-4 splaydiv">
                              <Link to="#" onClick={BettingClose} className="sgame-play"> Play</Link>
                            </div>
                          </>
                        )
                      }

                    </div>
                  </>
                )
              })
            }



            {/* <div className="row game-list-inner">
              <div className="col-4">
                <span className="sgameName"> 04:15 PM </span>
              </div>
              <div className="col-4">
                <span className="sgameResut"> </span>
              </div>
              <div className="col-4 splaydiv">
                <Link to="/game_dashboard" className="sgame-play"> Play</Link>
              </div>
            </div>


            <div className="row game-list-inner">
              <div className="col-4">
                <span className="sgameName"> 05:15 PM </span>
              </div>
              <div className="col-4">
                <span className="sgameResut"> </span>
              </div>
              <div className="col-4 splaydiv">
                <Link to="/game_dashboard" className="sgame-play"> Play</Link>
              </div>
            </div>


            <div className="row game-list-inner">
              <div className="col-4">
                <span className="sgameName"> 06:15 PM </span>
              </div>
              <div className="col-4">
                <span className="sgameResut"> </span>
              </div>
              <div className="col-4 splaydiv">
                <Link to="/game_dashboard" className="sgame-play"> Play</Link>
              </div>
            </div>


            <div className="row game-list-inner">
              <div className="col-4">
                <span className="sgameName"> 07:15 PM </span>
              </div>
              <div className="col-4">
                <span className="sgameResut"> </span>
              </div>
              <div className="col-4 splaydiv">
                <Link to="/game_dashboard" className="sgame-play"> Play</Link>
              </div>
            </div>


            <div className="row game-list-inner">
              <div className="col-4">
                <span className="sgameName"> 08:15 PM </span>
              </div>
              <div className="col-4">
                <span className="sgameResut"> </span>
              </div>
              <div className="col-4 splaydiv">
                <Link to="/game_dashboard" className="sgame-play"> Play</Link>
              </div>
            </div> */}


          </div>
        </div>
        <br /><br /><br />

      </div>
    </div>
  )
}

export default StarLine_Play