import React, { useState } from 'react'
import Header from '../../components/common/header/Header'
import { Link, useNavigate } from 'react-router-dom'
import { UserRegister } from '../../api/Auth'
const Register = () => {
  const navigate = useNavigate()
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfPassword] = useState("");

  const HandleUserRegister = async (e) => {
    e.preventDefault();
    if(password !== confirmpassword){
      alert("Password Do Not Match")
      return
    }
    else if(password.length < 4){
      alert("Password have atleast 4 digits !!!")
      return
    }
    else if(mobile.length < 10){
      alert("Mobile Number Must have 10 digits !!!")
      return
    }
    try {
      const userData = {
        password,
        mobile,
        email,
        name,
        app_key:"@34Y&D9(JE4dsi36f$%#(tigergames!xyz)8fe8ef8ef8",
        security_pin:"1234",
      }
    
      const response = await UserRegister(userData)
      console.log("HandleUserRegister", response?.data)
      if(response?.data?.status == true){
      alert("You are successfully register.")
      navigate("/login")
      setName("")
      setEmail("")
      setMobile("")
      setPassword("")
      setConfPassword("")
      }
    }
    catch (error) {
      console.log("error", error)
    }
  }
  return (
    <div><div class="wrapper">
      <div id="content">
        <Header />
        <div class="container">
          <div class="card tb-10">
            <div class="text-center tb-10">
              <h3>Sign Up</h3>
              <span>Create Your Account</span>
            </div>
            <form action="" method="POST" autocomplete="off" onSubmit={HandleUserRegister}>
              <div class="form-group">
                <label for="name">Your Name:</label>
                <input type="text" class="form-control" name="name" value={name}
                  onChange={(e) => setName(e.target.value)} maxlength="50" minlength="4"
                  placeholder="Enter Full Name" id="name" autocomplete="off" required="" />
              </div>
              <div class="form-group">
                <label for="username">Username:</label>
                <input type="text" class="form-control" name="email" value={email}
                  onChange={(e) => setEmail(e.target.value)} maxlength="25" minlength="4"
                  placeholder="Unique Username" id="username" autocomplete="off" required="" />
              </div>
              <div class="form-group">
                <label for="mobile">Mobile Number:</label>
                <input type="text" class="form-control" name="mobile" value={mobile}
                  onChange={(e) => setMobile(e.target.value)} maxlength="10" minlength="10"
                  placeholder="Enter 10 Digit Phone Number" id="mobile" autocomplete="off" required="" />
              </div>
              <div class="form-group">
                <label for="pwd">Password:</label>
                <input type="password" class="form-control" name="password"
                  value={password} onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter password" id="pwd"
                  autocomplete="off" required="" />
              </div>

              <div class="form-group">
                <label for="cnpwd">Confirm Password:</label>
                <input type="password" class="form-control" name="confirmpassword" value={confirmpassword}
                  onChange={(e) => setConfPassword(e.target.value)} placeholder="Confirm password"
                  id="cnpwd" autocomplete="off" required="" />
              </div>

              <button type="submit" name="signup" class="btn btn-theme btn-login">Submit</button>
            </form>

            <div class="text-center tbmar-20">
              <p>Already have an account?</p>
              <Link to="/login" class="btn btn-outline btn-login">Login Here</Link>
            </div>

          </div>
        </div>


      </div>
    </div>

      <br /><br /><br />
    </div>
  )
}

export default Register