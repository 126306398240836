import React, { useEffect, useState } from 'react'
import Header from '../../common/header/Header'
import Footer from '../../common/footer/Footer'
import { GetNotification } from '../../../api/AllApi'

const Notifications = () => {
  const [NotificationList, setNotificationList] = useState([])
  const user_id = localStorage.getItem("user_id");

  const HnadleGetNotificationList = async () => {
    const data = {
      app_key: "@34Y&D9(JE4dsi36f$%#(tigergames!xyz)8fe8ef8ef8",
      user_id: user_id
    }
    try {
      const response = await GetNotification(data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    HnadleGetNotificationList()
  }, [])
  return (
    <div className="wrapper">
      <div id="content">
        <Header />
        <div className="container">
          <div className="tb-10">
            <div className="row game-list-inner">
              {
                NotificationList?.map((NotificationListResult) => {
                  return (
                    <>
                      <div className="col-12 notifications">
                        <p className="t">PLAY BIG AND WIN BIG</p>
                        <p className="d">SUPER FAST DEPOSIT AND SUPER FAST WITHDRWAL</p>
                        <p className="time">Jul 24, 2024 05:19 PM</p>
                      </div>
                    </>
                  )
                })
              }

            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <Footer />
    </div>
  )
}

export default Notifications