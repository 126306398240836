import React from 'react'
import Header from '../../common/header/Header'
import Footer from '../../common/footer/Footer'

const StarLine_Chart_History = () => {
  return (
    <div>
        <div className="wrapper">
    <div id="content">
        <Header/>
      <div className="container">
        <div className="text-center tb-10">
          <h3 className="gdash3">Starline Chart History</h3>
          <span style={{fontSize:"12px"}}>Mumbai Starline</span>
        </div>
        <div className="tb-10">
          <div className="table-responsive">
            <table className="table table-striped starline-chart-table">
              <thead>
                <tr>
                  <th className="text-center">Date</th>
                  <th className="text-center">09 AM</th>
                  <th className="text-center">10 AM</th>
                  <th className="text-center">11 PM</th>
                  <th className="text-center">12 PM</th>
                  <th className="text-center">01 PM</th>
                  <th className="text-center">02 PM</th>
                  <th className="text-center">03 PM</th>
                  <th className="text-center">04 PM</th>
                  <th className="text-center">05 PM</th>
                  <th className="text-center">06 PM</th>
                  <th className="text-center">07 PM</th>
                  <th className="text-center">08 PM</th>


                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="f">
                    28/03/24<br/>Thu</td>
                  <td>290<br/>1</td>
                  <td>230<br/>5</td>
                  <td>478<br/>9</td>
                  <td>355<br/>3</td>
                  <td>147<br/>2</td>
                  <td>236<br/>1</td>
                  <td>479<br/>0</td>
                  <td>457<br/>6</td>
                  <td>167<br/>4</td>
                  <td>237<br/>2</td>
                  <td>580<br/>3</td>
                  <td>128<br/>1</td>
                </tr>
                <tr>
                  <td className="f">
                    29/03/24<br/>Fri</td>
                  <td>146<br/>1</td>
                  <td>467<br/>7</td>
                  <td>568<br/>9</td>
                  <td>169<br/>6</td>
                  <td>458<br/>7</td>
                  <td>166<br/>3</td>
                  <td>156<br/>2</td>
                  <td>378<br/>8</td>
                  <td>556<br/>6</td>
                  <td>234<br/>9</td>
                  <td>238<br/>3</td>
                  <td>159<br/>5</td>
                </tr>
                <tr>
                  <td className="f">
                    30/03/24<br/>Sat</td>
                  <td>256<br/>3</td>
                  <td>290<br/>1</td>
                  <td>470<br/>1</td>
                  <td>135<br/>9</td>
                  <td>280<br/>0</td>
                  <td>459<br/>8</td>
                  <td>237<br/>2</td>
                  <td>139<br/>3</td>
                  <td>145<br/>0</td>
                  <td>369<br/>8</td>
                  <td>248<br/>4</td>
                  <td>238<br/>3</td>
                </tr>
                <tr>
                  <td className="f">
                    31/03/24<br/>Sun</td>
                  <td>369<br/>8</td>
                  <td>255<br/>2</td>
                  <td>479<br/>0</td>
                  <td>356<br/>4</td>
                  <td>145<br/>0</td>
                  <td>113<br/>5</td>
                  <td>189<br/>8</td>
                  <td>458<br/>7</td>
                  <td>379<br/>9</td>
                  <td>129<br/>2</td>
                  <td>280<br/>0</td>
                  <td>680<br/>4</td>
                </tr>
                <tr>
                  <td className="f">
                    01/04/24<br/>Mon</td>
                  <td>229<br/>3</td>
                  <td>668<br/>0</td>
                  <td>680<br/>4</td>
                  <td>268<br/>6</td>
                  <td>130<br/>4</td>
                  <td>156<br/>2</td>
                  <td>590<br/>4</td>
                  <td>570<br/>2</td>
                  <td>780<br/>5</td>
                  <td>247<br/>3</td>
                  <td>135<br/>9</td>
                  <td>468<br/>8</td>
                </tr>
                <tr>
                  <td className="f">
                    02/04/24<br/>Tue</td>
                  <td>249<br/>5</td>
                  <td>134<br/>8</td>
                  <td>358<br/>6</td>
                  <td>290<br/>1</td>
                  <td>570<br/>2</td>
                  <td>568<br/>9</td>
                  <td>145<br/>0</td>
                  <td>279<br/>8</td>
                  <td>380<br/>1</td>
                  <td>670<br/>3</td>
                  <td>369<br/>8</td>
                  <td>579<br/>1</td>
                </tr>
                <tr>
                  <td className="f">
                    03/04/24<br/>Wed</td>
                  <td>190<br/>0</td>
                  <td>247<br/>3</td>
                  <td>458<br/>7</td>
                  <td>235<br/>0</td>
                  <td>270<br/>9</td>
                  <td>134<br/>8</td>
                  <td>356<br/>4</td>
                  <td>890<br/>7</td>
                  <td>455<br/>4</td>
                  <td>237<br/>2</td>
                  <td>189<br/>8</td>
                  <td>680<br/>4</td>
                </tr>
                <tr>
                  <td className="f">
                    04/04/24<br/>Thu</td>
                  <td>270<br/>9</td>
                  <td>267<br/>5</td>
                  <td>170<br/>8</td>
                  <td>147<br/>2</td>
                  <td>579<br/>1</td>
                  <td>138<br/>2</td>
                  <td>120<br/>3</td>
                  <td>459<br/>8</td>
                  <td>160<br/>7</td>
                  <td>130<br/>4</td>
                  <td>120<br/>3</td>
                  <td>225<br/>9</td>
                </tr>
                <tr>
                  <td className="f">
                    05/04/24<br/>Fri</td>
                  <td>180<br/>9</td>
                  <td>120<br/>3</td>
                  <td>279<br/>8</td>
                  <td>356<br/>4</td>
                  <td>550<br/>0</td>
                  <td>679<br/>2</td>
                  <td>660<br/>2</td>
                  <td>335<br/>1</td>
                  <td>589<br/>2</td>
                  <td>130<br/>4</td>
                  <td>578<br/>0</td>
                  <td>480<br/>2</td>
                </tr>
                <tr>
                  <td className="f">
                    06/04/24<br/>Sat</td>
                  <td>689<br/>3</td>
                  <td>118<br/>0</td>
                  <td>290<br/>1</td>
                  <td>670<br/>3</td>
                  <td>370<br/>0</td>
                  <td>368<br/>7</td>
                  <td>234<br/>9</td>
                  <td>569<br/>0</td>
                  <td>344<br/>1</td>
                  <td>459<br/>8</td>
                  <td>227<br/>1</td>
                  <td>449<br/>7</td>
                </tr>
                <tr>
                  <td className="f">
                    07/04/24<br/>Sun</td>
                  <td>670<br/>3</td>
                  <td>260<br/>8</td>
                  <td>266<br/>4</td>
                  <td>447<br/>5</td>
                  <td>130<br/>4</td>
                  <td>660<br/>2</td>
                  <td>257<br/>4</td>
                  <td>110<br/>2</td>
                  <td>400<br/>4</td>
                  <td>117<br/>9</td>
                  <td>688<br/>2</td>
                  <td>336<br/>2</td>
                </tr>
                <tr>
                  <td className="f">
                    08/04/24<br/>Mon</td>
                  <td>449<br/>7</td>
                  <td>388<br/>9</td>
                  <td>300<br/>3</td>
                  <td>234<br/>9</td>
                  <td>124<br/>7</td>
                  <td>230<br/>5</td>
                  <td>188<br/>7</td>
                  <td>170<br/>8</td>
                  <td>660<br/>2</td>
                  <td>399<br/>1</td>
                  <td>177<br/>5</td>
                  <td>155<br/>1</td>
                </tr>
                <tr>
                  <td className="f">
                    09/04/24<br/>Tue</td>
                  <td>200<br/>2</td>
                  <td>488<br/>0</td>
                  <td>115<br/>7</td>
                  <td>689<br/>3</td>
                  <td>560<br/>1</td>
                  <td>440<br/>8</td>
                  <td>110<br/>2</td>
                  <td>670<br/>3</td>
                  <td>570<br/>2</td>
                  <td>677<br/>0</td>
                  <td>145<br/>0</td>
                  <td>160<br/>7</td>
                </tr>
                <tr>
                  <td className="f">
                    10/04/24<br/>Wed</td>
                  <td>690<br/>5</td>
                  <td>468<br/>8</td>
                  <td>458<br/>7</td>
                  <td>169<br/>6</td>
                  <td>180<br/>9</td>
                  <td>128<br/>1</td>
                  <td>360<br/>9</td>
                  <td>222<br/>6</td>
                  <td>480<br/>2</td>
                  <td>134<br/>8</td>
                  <td>150<br/>6</td>
                  <td>679<br/>2</td>
                </tr>
                <tr>
                  <td className="f">
                    11/04/24<br/>Thu</td>
                  <td>568<br/>9</td>
                  <td>158<br/>4</td>
                  <td>140<br/>5</td>
                  <td>112<br/>4</td>
                  <td>157<br/>3</td>
                  <td>339<br/>5</td>
                  <td>580<br/>3</td>
                  <td>159<br/>5</td>
                  <td>289<br/>9</td>
                  <td>128<br/>1</td>
                  <td>579<br/>1</td>
                  <td>455<br/>4</td>
                </tr>
                <tr>
                  <td className="f">
                    12/04/24<br/>Fri</td>
                  <td>160<br/>7</td>
                  <td>157<br/>3</td>
                  <td>179<br/>7</td>
                  <td>689<br/>3</td>
                  <td>124<br/>7</td>
                  <td>579<br/>1</td>
                  <td>170<br/>8</td>
                  <td>166<br/>3</td>
                  <td>117<br/>9</td>
                  <td>890<br/>7</td>
                  <td>148<br/>3</td>
                  <td>170<br/>8</td>
                </tr>
                <tr>
                  <td className="f">
                    13/04/24<br/>Sat</td>
                  <td>250<br/>7</td>
                  <td>189<br/>8</td>
                  <td>470<br/>1</td>
                  <td>578<br/>0</td>
                  <td>114<br/>6</td>
                  <td>568<br/>9</td>
                  <td>126<br/>9</td>
                  <td>180<br/>9</td>
                  <td>118<br/>0</td>
                  <td>680<br/>4</td>
                  <td>469<br/>9</td>
                  <td>479<br/>0</td>
                </tr>
                <tr>
                  <td className="f">
                    14/04/24<br/>Sun</td>
                  <td>240<br/>6</td>
                  <td>178<br/>6</td>
                  <td>369<br/>8</td>
                  <td>480<br/>2</td>
                  <td>259<br/>6</td>
                  <td>157<br/>3</td>
                  <td>146<br/>1</td>
                  <td>280<br/>0</td>
                  <td>889<br/>5</td>
                  <td>248<br/>4</td>
                  <td>126<br/>9</td>
                  <td>158<br/>4</td>
                </tr>
                <tr>
                  <td className="f">
                    15/04/24<br/>Mon</td>
                  <td>126<br/>9</td>
                  <td>389<br/>0</td>
                  <td>579<br/>1</td>
                  <td>290<br/>1</td>
                  <td>390<br/>2</td>
                  <td>120<br/>3</td>
                  <td>138<br/>2</td>
                  <td>149<br/>4</td>
                  <td>137<br/>1</td>
                  <td>138<br/>2</td>
                  <td>236<br/>1</td>
                  <td>149<br/>4</td>
                </tr>
                <tr>
                  <td className="f">
                    16/04/24<br/>Tue</td>
                  <td>689<br/>3</td>
                  <td>480<br/>2</td>
                  <td>469<br/>9</td>
                  <td>590<br/>4</td>
                  <td>369<br/>8</td>
                  <td>358<br/>6</td>
                  <td>370<br/>0</td>
                  <td>135<br/>9</td>
                  <td>189<br/>8</td>
                  <td>139<br/>3</td>
                  <td>349<br/>6</td>
                  <td>169<br/>6</td>
                </tr>
                <tr>
                  <td className="f">
                    17/04/24<br/>Wed</td>
                  <td>238<br/>3</td>
                  <td>159<br/>5</td>
                  <td>469<br/>9</td>
                  <td>558<br/>8</td>
                  <td>780<br/>5</td>
                  <td>147<br/>2</td>
                  <td>789<br/>4</td>
                  <td>123<br/>6</td>
                  <td>369<br/>8</td>
                  <td>678<br/>1</td>
                  <td>370<br/>0</td>
                  <td>236<br/>1</td>
                </tr>
                <tr>
                  <td className="f">
                    18/04/24<br/>Thu</td>
                  <td>127<br/>0</td>
                  <td>257<br/>4</td>
                  <td>370<br/>0</td>
                  <td>239<br/>4</td>
                  <td>280<br/>0</td>
                  <td>579<br/>1</td>
                  <td>127<br/>0</td>
                  <td>489<br/>1</td>
                  <td>340<br/>7</td>
                  <td>240<br/>6</td>
                  <td>460<br/>0</td>
                  <td>148<br/>3</td>
                </tr>
                <tr>
                  <td className="f">
                    19/04/24<br/>Fri</td>
                  <td>569<br/>0</td>
                  <td>125<br/>8</td>
                  <td>368<br/>7</td>
                  <td>169<br/>6</td>
                  <td>124<br/>7</td>
                  <td>238<br/>3</td>
                  <td>146<br/>1</td>
                  <td>135<br/>9</td>
                  <td>300<br/>3</td>
                  <td>137<br/>1</td>
                  <td>126<br/>9</td>
                  <td>137<br/>1</td>
                </tr>
                <tr>
                  <td className="f">
                    20/04/24<br/>Sat</td>
                  <td>135<br/>9</td>
                  <td>579<br/>1</td>
                  <td>670<br/>3</td>
                  <td>560<br/>1</td>
                  <td>223<br/>7</td>
                  <td>140<br/>5</td>
                  <td>580<br/>3</td>
                  <td>139<br/>3</td>
                  <td>112<br/>4</td>
                  <td>115<br/>7</td>
                  <td>788<br/>3</td>
                  <td>140<br/>5</td>
                </tr>
                <tr>
                  <td className="f">
                    21/04/24<br/>Sun</td>
                  <td>255<br/>2</td>
                  <td>126<br/>9</td>
                  <td>220<br/>4</td>
                  <td>114<br/>6</td>
                  <td>133<br/>7</td>
                  <td>126<br/>9</td>
                  <td>125<br/>8</td>
                  <td>180<br/>9</td>
                  <td>369<br/>8</td>
                  <td>249<br/>5</td>
                  <td>117<br/>9</td>
                  <td>150<br/>6</td>
                </tr>
                <tr>
                  <td className="f">
                    22/04/24<br/>Mon</td>
                  <td>230<br/>5</td>
                  <td>126<br/>9</td>
                  <td>137<br/>1</td>
                  <td>236<br/>1</td>
                  <td>279<br/>8</td>
                  <td>127<br/>0</td>
                  <td>278<br/>7</td>
                  <td>180<br/>9</td>
                  <td>348<br/>5</td>
                  <td>289<br/>9</td>
                  <td>578<br/>0</td>
                  <td>126<br/>9</td>
                </tr>
                <tr>
                  <td className="f">
                    23/04/24<br/>Tue</td>
                  <td>116<br/>8</td>
                  <td>129<br/>2</td>
                  <td>259<br/>6</td>
                  <td>489<br/>1</td>
                  <td>330<br/>6</td>
                  <td>138<br/>2</td>
                  <td>136<br/>0</td>
                  <td>130<br/>4</td>
                  <td>249<br/>5</td>
                  <td>258<br/>5</td>
                  <td>137<br/>1</td>
                  <td>124<br/>7</td>
                </tr>
                <tr>
                  <td className="f">
                    24/04/24<br/>Wed</td>
                  <td>137<br/>1</td>
                  <td>125<br/>8</td>
                  <td>137<br/>1</td>
                  <td>159<br/>5</td>
                  <td>190<br/>0</td>
                  <td>560<br/>1</td>
                  <td>349<br/>6</td>
                  <td>590<br/>4</td>
                  <td>345<br/>2</td>
                  <td>469<br/>9</td>
                  <td>380<br/>1</td>
                  <td>679<br/>2</td>
                </tr>
                <tr>
                  <td className="f">
                    25/04/24<br/>Thu</td>
                  <td>130<br/>4</td>
                  <td>389<br/>0</td>
                  <td>366<br/>5</td>
                  <td>127<br/>0</td>
                  <td>780<br/>5</td>
                  <td>159<br/>5</td>
                  <td>129<br/>2</td>
                  <td>267<br/>5</td>
                  <td>369<br/>8</td>
                  <td>459<br/>8</td>
                  <td>348<br/>5</td>
                  <td>120<br/>3</td>
                </tr>
                <tr>
                  <td className="f">
                    26/04/24<br/>Fri</td>
                  <td>239<br/>4</td>
                  <td>110<br/>2</td>
                  <td>134<br/>8</td>
                  <td>139<br/>3</td>
                  <td>125<br/>8</td>
                  <td>369<br/>8</td>
                  <td>379<br/>9</td>
                  <td>378<br/>8</td>
                  <td>237<br/>2</td>
                  <td>256<br/>3</td>
                  <td>457<br/>6</td>
                  <td>579<br/>1</td>
                </tr>
                <tr>
                  <td className="f">
                    27/04/24<br/>Sat</td>
                  <td>130<br/>4</td>
                  <td>337<br/>3</td>
                  <td>370<br/>0</td>
                  <td>128<br/>1</td>
                  <td>369<br/>8</td>
                  <td>120<br/>3</td>
                  <td>357<br/>5</td>
                  <td>126<br/>9</td>
                  <td>467<br/>7</td>
                  <td>459<br/>8</td>
                  <td>689<br/>3</td>
                  <td>379<br/>9</td>
                </tr>
                <tr>
                  <td className="f">
                    28/04/24<br/>Sun</td>
                  <td>120<br/>3</td>
                  <td>267<br/>5</td>
                  <td>450<br/>9</td>
                  <td>690<br/>5</td>
                  <td>289<br/>9</td>
                  <td>177<br/>5</td>
                  <td>137<br/>1</td>
                  <td>227<br/>1</td>
                  <td>568<br/>9</td>
                  <td>257<br/>4</td>
                  <td>347<br/>4</td>
                  <td>347<br/>4</td>
                </tr>
                <tr>
                  <td className="f">
                    29/04/24<br/>Mon</td>
                  <td>249<br/>5</td>
                  <td>349<br/>6</td>
                  <td>136<br/>0</td>
                  <td>270<br/>9</td>
                  <td>389<br/>0</td>
                  <td>125<br/>8</td>
                  <td>160<br/>7</td>
                  <td>236<br/>1</td>
                  <td>367<br/>6</td>
                  <td>578<br/>0</td>
                  <td>368<br/>7</td>
                  <td>468<br/>8</td>
                </tr>
                <tr>
                  <td className="f">
                    30/04/24<br/>Tue</td>
                  <td>358<br/>6</td>
                  <td>458<br/>7</td>
                  <td>690<br/>5</td>
                  <td>359<br/>7</td>
                  <td>466<br/>6</td>
                  <td>115<br/>7</td>
                  <td>138<br/>2</td>
                  <td>359<br/>7</td>
                  <td>126<br/>9</td>
                  <td>146<br/>1</td>
                  <td>566<br/>7</td>
                  <td>160<br/>7</td>
                </tr>
                <tr>
                  <td className="f">
                    01/05/24<br/>Wed</td>
                  <td>126<br/>9</td>
                  <td>128<br/>1</td>
                  <td>124<br/>7</td>
                  <td>457<br/>6</td>
                  <td>257<br/>4</td>
                  <td>389<br/>0</td>
                  <td>348<br/>5</td>
                  <td>468<br/>8</td>
                  <td>127<br/>0</td>
                  <td>255<br/>2</td>
                  <td>290<br/>1</td>
                  <td>569<br/>0</td>
                </tr>
                <tr>
                  <td className="f">
                    02/05/24<br/>Thu</td>
                  <td>170<br/>8</td>
                  <td>150<br/>6</td>
                  <td>578<br/>0</td>
                  <td>259<br/>6</td>
                  <td>356<br/>4</td>
                  <td>568<br/>9</td>
                  <td>680<br/>4</td>
                  <td>369<br/>8</td>
                  <td>590<br/>4</td>
                  <td>120<br/>3</td>
                  <td>260<br/>8</td>
                  <td>460<br/>0</td>
                </tr>
                <tr>
                  <td className="f">
                    03/05/24<br/>Fri</td>
                  <td>277<br/>6</td>
                  <td>338<br/>4</td>
                  <td>123<br/>6</td>
                  <td>178<br/>6</td>
                  <td>145<br/>0</td>
                  <td>489<br/>1</td>
                  <td>349<br/>6</td>
                  <td>136<br/>0</td>
                  <td>330<br/>6</td>
                  <td>136<br/>0</td>
                  <td>266<br/>4</td>
                  <td>369<br/>8</td>
                </tr>
                <tr>
                  <td className="f">
                    04/05/24<br/>Sat</td>
                  <td>125<br/>8</td>
                  <td>267<br/>5</td>
                  <td>479<br/>0</td>
                  <td>138<br/>2</td>
                  <td>247<br/>3</td>
                  <td>680<br/>4</td>
                  <td>578<br/>0</td>
                  <td>560<br/>1</td>
                  <td>356<br/>4</td>
                  <td>235<br/>0</td>
                  <td>450<br/>9</td>
                  <td>670<br/>3</td>
                </tr>
                <tr>
                  <td className="f">
                    05/05/24<br/>Sun</td>
                  <td>347<br/>4</td>
                  <td>490<br/>3</td>
                  <td>358<br/>6</td>
                  <td>578<br/>0</td>
                  <td>246<br/>2</td>
                  <td>668<br/>0</td>
                  <td>479<br/>0</td>
                  <td>180<br/>9</td>
                  <td>334<br/>0</td>
                  <td>330<br/>6</td>
                  <td>677<br/>0</td>
                  <td>236<br/>1</td>
                </tr>
                <tr>
                  <td className="f">
                    06/05/24<br/>Mon</td>
                  <td>146<br/>1</td>
                  <td>125<br/>8</td>
                  <td>157<br/>3</td>
                  <td>289<br/>9</td>
                  <td>390<br/>2</td>
                  <td>389<br/>0</td>
                  <td>457<br/>6</td>
                  <td>458<br/>7</td>
                  <td>129<br/>2</td>
                  <td>359<br/>7</td>
                  <td>470<br/>1</td>
                  <td>559<br/>9</td>
                </tr>
                <tr>
                  <td className="f">
                    07/05/24<br/>Tue</td>
                  <td>238<br/>3</td>
                  <td>245<br/>1</td>
                  <td>345<br/>2</td>
                  <td>890<br/>7</td>
                  <td>358<br/>6</td>
                  <td>360<br/>9</td>
                  <td>350<br/>8</td>
                  <td>589<br/>2</td>
                  <td>224<br/>8</td>
                  <td>569<br/>0</td>
                  <td>236<br/>1</td>
                  <td>349<br/>6</td>
                </tr>
                <tr>
                  <td className="f">
                    08/05/24<br/>Wed</td>
                  <td>139<br/>3</td>
                  <td>259<br/>6</td>
                  <td>358<br/>6</td>
                  <td>247<br/>3</td>
                  <td>367<br/>6</td>
                  <td>240<br/>6</td>
                  <td>279<br/>8</td>
                  <td>579<br/>1</td>
                  <td>236<br/>1</td>
                  <td>357<br/>5</td>
                  <td>488<br/>0</td>
                  <td>150<br/>6</td>
                </tr>
                <tr>
                  <td className="f">
                    09/05/24<br/>Thu</td>
                  <td>470<br/>1</td>
                  <td>448<br/>6</td>
                  <td>234<br/>9</td>
                  <td>670<br/>3</td>
                  <td>220<br/>4</td>
                  <td>125<br/>8</td>
                  <td>118<br/>0</td>
                  <td>246<br/>2</td>
                  <td>266<br/>4</td>
                  <td>129<br/>2</td>
                  <td>127<br/>0</td>
                  <td>338<br/>4</td>
                </tr>
                <tr>
                  <td className="f">
                    10/05/24<br/>Fri</td>
                  <td>690<br/>5</td>
                  <td>123<br/>6</td>
                  <td>589<br/>2</td>
                  <td>125<br/>8</td>
                  <td>235<br/>0</td>
                  <td>568<br/>9</td>
                  <td>379<br/>9</td>
                  <td>110<br/>2</td>
                  <td>239<br/>4</td>
                  <td>123<br/>6</td>
                  <td>190<br/>0</td>
                  <td>370<br/>0</td>
                </tr>
                <tr>
                  <td className="f">
                    11/05/24<br/>Sat</td>
                  <td>379<br/>9</td>
                  <td>378<br/>8</td>
                  <td>270<br/>9</td>
                  <td>137<br/>1</td>
                  <td>125<br/>8</td>
                  <td>356<br/>4</td>
                  <td>890<br/>7</td>
                  <td>560<br/>1</td>
                  <td>140<br/>5</td>
                  <td>340<br/>7</td>
                  <td>468<br/>8</td>
                  <td>689<br/>3</td>
                </tr>
                <tr>
                  <td className="f">
                    12/05/24<br/>Sun</td>
                  <td>247<br/>3</td>
                  <td>122<br/>5</td>
                  <td>139<br/>3</td>
                  <td>127<br/>0</td>
                  <td>124<br/>7</td>
                  <td>389<br/>0</td>
                  <td>158<br/>4</td>
                  <td>157<br/>3</td>
                  <td>178<br/>6</td>
                  <td>789<br/>4</td>
                  <td>200<br/>2</td>
                  <td>123<br/>6</td>
                </tr>
                <tr>
                  <td className="f">
                    13/05/24<br/>Mon</td>
                  <td>125<br/>8</td>
                  <td>158<br/>4</td>
                  <td>355<br/>3</td>
                  <td>125<br/>8</td>
                  <td>117<br/>9</td>
                  <td>788<br/>3</td>
                  <td>890<br/>7</td>
                  <td>678<br/>1</td>
                  <td>448<br/>6</td>
                  <td>119<br/>1</td>
                  <td>330<br/>6</td>
                  <td>124<br/>7</td>
                </tr>
                <tr>
                  <td className="f">
                    14/05/24<br/>Tue</td>
                  <td>160<br/>7</td>
                  <td>112<br/>4</td>
                  <td>468<br/>8</td>
                  <td>446<br/>4</td>
                  <td>779<br/>3</td>
                  <td>237<br/>2</td>
                  <td>223<br/>7</td>
                  <td>126<br/>9</td>
                  <td>470<br/>1</td>
                  <td>120<br/>3</td>
                  <td>467<br/>7</td>
                  <td>168<br/>5</td>
                </tr>
                <tr>
                  <td className="f">
                    15/05/24<br/>Wed</td>
                  <td>160<br/>7</td>
                  <td>258<br/>5</td>
                  <td>234<br/>9</td>
                  <td>137<br/>1</td>
                  <td>369<br/>8</td>
                  <td>690<br/>5</td>
                  <td>279<br/>8</td>
                  <td>140<br/>5</td>
                  <td>345<br/>2</td>
                  <td>489<br/>1</td>
                  <td>267<br/>5</td>
                  <td>680<br/>4</td>
                </tr>
                <tr>
                  <td className="f">
                    16/05/24<br/>Thu</td>
                  <td>370<br/>0</td>
                  <td>124<br/>7</td>
                  <td>279<br/>8</td>
                  <td>344<br/>1</td>
                  <td>256<br/>3</td>
                  <td>789<br/>4</td>
                  <td>390<br/>2</td>
                  <td>458<br/>7</td>
                  <td>590<br/>4</td>
                  <td>135<br/>9</td>
                  <td>123<br/>6</td>
                  <td>346<br/>3</td>
                </tr>
                <tr>
                  <td className="f">
                    17/05/24<br/>Fri</td>
                  <td>123<br/>6</td>
                  <td>679<br/>2</td>
                  <td>145<br/>0</td>
                  <td>125<br/>8</td>
                  <td>457<br/>6</td>
                  <td>458<br/>7</td>
                  <td>457<br/>6</td>
                  <td>289<br/>9</td>
                  <td>127<br/>0</td>
                  <td>346<br/>3</td>
                  <td>123<br/>6</td>
                  <td>126<br/>9</td>
                </tr>
                <tr>
                  <td className="f">
                    18/05/24<br/>Sat</td>
                  <td>458<br/>7</td>
                  <td>126<br/>9</td>
                  <td>280<br/>0</td>
                  <td>489<br/>1</td>
                  <td>770<br/>4</td>
                  <td>190<br/>0</td>
                  <td>690<br/>5</td>
                  <td>145<br/>0</td>
                  <td>458<br/>7</td>
                  <td>289<br/>9</td>
                  <td>350<br/>8</td>
                  <td>349<br/>6</td>
                </tr>
                <tr>
                  <td className="f">
                    19/05/24<br/>Sun</td>
                  <td>288<br/>8</td>
                  <td>226<br/>0</td>
                  <td>388<br/>9</td>
                  <td>680<br/>4</td>
                  <td>135<br/>9</td>
                  <td>236<br/>1</td>
                  <td>457<br/>6</td>
                  <td>167<br/>4</td>
                  <td>137<br/>1</td>
                  <td>380<br/>1</td>
                  <td>690<br/>5</td>
                  <td>156<br/>2</td>
                </tr>
                <tr>
                  <td className="f">
                    20/05/24<br/>Mon</td>
                  <td>113<br/>5</td>
                  <td>550<br/>0</td>
                  <td>899<br/>6</td>
                  <td>780<br/>5</td>
                  <td>227<br/>1</td>
                  <td>490<br/>3</td>
                  <td>128<br/>1</td>
                  <td>558<br/>8</td>
                  <td>580<br/>3</td>
                  <td>368<br/>7</td>
                  <td>147<br/>2</td>
                  <td>579<br/>1</td>
                </tr>
                <tr>
                  <td className="f">
                    21/05/24<br/>Tue</td>
                  <td>449<br/>7</td>
                  <td>123<br/>6</td>
                  <td>188<br/>7</td>
                  <td>130<br/>4</td>
                  <td>179<br/>7</td>
                  <td>233<br/>8</td>
                  <td>445<br/>3</td>
                  <td>348<br/>5</td>
                  <td>245<br/>1</td>
                  <td>346<br/>3</td>
                  <td>579<br/>1</td>
                  <td>245<br/>1</td>
                </tr>
                <tr>
                  <td className="f">
                    22/05/24<br/>Wed</td>
                  <td>245<br/>1</td>
                  <td>259<br/>6</td>
                  <td>137<br/>1</td>
                  <td>358<br/>6</td>
                  <td>670<br/>3</td>
                  <td>249<br/>5</td>
                  <td>368<br/>7</td>
                  <td>246<br/>2</td>
                  <td>569<br/>0</td>
                  <td>269<br/>7</td>
                  <td>158<br/>4</td>
                  <td>458<br/>7</td>
                </tr>
                <tr>
                  <td className="f">
                    23/05/24<br/>Thu</td>
                  <td>135<br/>9</td>
                  <td>140<br/>5</td>
                  <td>557<br/>7</td>
                  <td>456<br/>5</td>
                  <td>780<br/>5</td>
                  <td>340<br/>7</td>
                  <td>170<br/>8</td>
                  <td>289<br/>9</td>
                  <td>257<br/>4</td>
                  <td>150<br/>6</td>
                  <td>117<br/>9</td>
                  <td>366<br/>5</td>
                </tr>
                <tr>
                  <td className="f">
                    24/05/24<br/>Fri</td>
                  <td>124<br/>7</td>
                  <td>156<br/>2</td>
                  <td>125<br/>8</td>
                  <td>458<br/>7</td>
                  <td>389<br/>0</td>
                  <td>468<br/>8</td>
                  <td>360<br/>9</td>
                  <td>248<br/>4</td>
                  <td>125<br/>8</td>
                  <td>359<br/>7</td>
                  <td>570<br/>2</td>
                  <td>129<br/>2</td>
                </tr>
                <tr>
                  <td className="f">
                    25/05/24<br/>Sat</td>
                  <td>570<br/>2</td>
                  <td>127<br/>0</td>
                  <td>378<br/>8</td>
                  <td>269<br/>7</td>
                  <td>140<br/>5</td>
                  <td>467<br/>7</td>
                  <td>390<br/>2</td>
                  <td>460<br/>0</td>
                  <td>138<br/>2</td>
                  <td>269<br/>7</td>
                  <td>578<br/>0</td>
                  <td>245<br/>1</td>
                </tr>
                <tr>
                  <td className="f">
                    26/05/24<br/>Sun</td>
                  <td>250<br/>7</td>
                  <td>588<br/>1</td>
                  <td>136<br/>0</td>
                  <td>230<br/>5</td>
                  <td>240<br/>6</td>
                  <td>140<br/>5</td>
                  <td>123<br/>6</td>
                  <td>247<br/>3</td>
                  <td>378<br/>8</td>
                  <td>450<br/>9</td>
                  <td>356<br/>4</td>
                  <td>560<br/>1</td>
                </tr>
                <tr>
                  <td className="f">
                    27/05/24<br/>Mon</td>
                  <td>124<br/>7</td>
                  <td>140<br/>5</td>
                  <td>568<br/>9</td>
                  <td>370<br/>0</td>
                  <td>350<br/>8</td>
                  <td>137<br/>1</td>
                  <td>279<br/>8</td>
                  <td>236<br/>1</td>
                  <td>679<br/>2</td>
                  <td>270<br/>9</td>
                  <td>578<br/>0</td>
                  <td>269<br/>7</td>
                </tr>
                <tr>
                  <td className="f">
                    28/05/24<br/>Tue</td>
                  <td>368<br/>7</td>
                  <td>236<br/>1</td>
                  <td>125<br/>8</td>
                  <td>569<br/>0</td>
                  <td>478<br/>9</td>
                  <td>246<br/>2</td>
                  <td>458<br/>7</td>
                  <td>178<br/>6</td>
                  <td>589<br/>2</td>
                  <td>147<br/>2</td>
                  <td>668<br/>0</td>
                  <td>589<br/>2</td>
                </tr>
                <tr>
                  <td className="f">
                    29/05/24<br/>Wed</td>
                  <td>170<br/>8</td>
                  <td>235<br/>0</td>
                  <td>246<br/>2</td>
                  <td>468<br/>8</td>
                  <td>460<br/>0</td>
                  <td>256<br/>3</td>
                  <td>467<br/>7</td>
                  <td>389<br/>0</td>
                  <td>890<br/>7</td>
                  <td>123<br/>6</td>
                  <td>345<br/>2</td>
                  <td>457<br/>6</td>
                </tr>
                <tr>
                  <td className="f">
                    30/05/24<br/>Thu</td>
                  <td>689<br/>3</td>
                  <td>116<br/>8</td>
                  <td>799<br/>5</td>
                  <td>590<br/>4</td>
                  <td>122<br/>5</td>
                  <td>789<br/>4</td>
                  <td>128<br/>1</td>
                  <td>480<br/>2</td>
                  <td>237<br/>2</td>
                  <td>123<br/>6</td>
                  <td>179<br/>7</td>
                  <td>279<br/>8</td>
                </tr>
                <tr>
                  <td className="f">
                    31/05/24<br/>Fri</td>
                  <td>190<br/>0</td>
                  <td>100<br/>1</td>
                  <td>135<br/>9</td>
                  <td>457<br/>6</td>
                  <td>120<br/>3</td>
                  <td>159<br/>5</td>
                  <td>347<br/>4</td>
                  <td>289<br/>9</td>
                  <td>236<br/>1</td>
                  <td>247<br/>3</td>
                  <td>268<br/>6</td>
                  <td>345<br/>2</td>
                </tr>
                <tr>
                  <td className="f">
                    01/06/24<br/>Sat</td>
                  <td>190<br/>0</td>
                  <td>590<br/>4</td>
                  <td>246<br/>2</td>
                  <td>590<br/>4</td>
                  <td>379<br/>9</td>
                  <td>158<br/>4</td>
                  <td>345<br/>2</td>
                  <td>589<br/>2</td>
                  <td>239<br/>4</td>
                  <td>360<br/>9</td>
                  <td>190<br/>0</td>
                  <td>147<br/>2</td>
                </tr>
                <tr>
                  <td className="f">
                    02/06/24<br/>Sun</td>
                  <td>180<br/>9</td>
                  <td>123<br/>6</td>
                  <td>347<br/>4</td>
                  <td>150<br/>6</td>
                  <td>126<br/>9</td>
                  <td>125<br/>8</td>
                  <td>580<br/>3</td>
                  <td>389<br/>0</td>
                  <td>289<br/>9</td>
                  <td>137<br/>1</td>
                  <td>289<br/>9</td>
                  <td>140<br/>5</td>
                </tr>
                <tr>
                  <td className="f">
                    03/06/24<br/>Mon</td>
                  <td>389<br/>0</td>
                  <td>680<br/>4</td>
                  <td>340<br/>7</td>
                  <td>237<br/>2</td>
                  <td>257<br/>4</td>
                  <td>357<br/>5</td>
                  <td>235<br/>0</td>
                  <td>236<br/>1</td>
                  <td>139<br/>3</td>
                  <td>270<br/>9</td>
                  <td>356<br/>4</td>
                  <td>568<br/>9</td>
                </tr>
                <tr>
                  <td className="f">
                    04/06/24<br/>Tue</td>
                  <td>234<br/>9</td>
                  <td>380<br/>1</td>
                  <td>689<br/>3</td>
                  <td>120<br/>3</td>
                  <td>136<br/>0</td>
                  <td>478<br/>9</td>
                  <td>560<br/>1</td>
                  <td>118<br/>0</td>
                  <td>239<br/>4</td>
                  <td>489<br/>1</td>
                  <td>235<br/>0</td>
                  <td>146<br/>1</td>
                </tr>
                <tr>
                  <td className="f">
                    05/06/24<br/>Wed</td>
                  <td>120<br/>3</td>
                  <td>370<br/>0</td>
                  <td>689<br/>3</td>
                  <td>147<br/>2</td>
                  <td>280<br/>0</td>
                  <td>135<br/>9</td>
                  <td>148<br/>3</td>
                  <td>678<br/>1</td>
                  <td>228<br/>2</td>
                  <td>289<br/>9</td>
                  <td>269<br/>7</td>
                  <td>140<br/>5</td>
                </tr>
                <tr>
                  <td className="f">
                    06/06/24<br/>Thu</td>
                  <td>568<br/>9</td>
                  <td>678<br/>1</td>
                  <td>180<br/>9</td>
                  <td>368<br/>7</td>
                  <td>120<br/>3</td>
                  <td>179<br/>7</td>
                  <td>568<br/>9</td>
                  <td>890<br/>7</td>
                  <td>490<br/>3</td>
                  <td>278<br/>7</td>
                  <td>788<br/>3</td>
                  <td>690<br/>5</td>
                </tr>
                <tr>
                  <td className="f">
                    07/06/24<br/>Fri</td>
                  <td>589<br/>2</td>
                  <td>289<br/>9</td>
                  <td>345<br/>2</td>
                  <td>267<br/>5</td>
                  <td>238<br/>3</td>
                  <td>459<br/>8</td>
                  <td>120<br/>3</td>
                  <td>467<br/>7</td>
                  <td>346<br/>3</td>
                  <td>489<br/>1</td>
                  <td>459<br/>8</td>
                  <td>337<br/>3</td>
                </tr>
                <tr>
                  <td className="f">
                    08/06/24<br/>Sat</td>
                  <td>459<br/>8</td>
                  <td>589<br/>2</td>
                  <td>388<br/>9</td>
                  <td>570<br/>2</td>
                  <td>125<br/>8</td>
                  <td>138<br/>2</td>
                  <td>679<br/>2</td>
                  <td>367<br/>6</td>
                  <td>236<br/>1</td>
                  <td>679<br/>2</td>
                  <td>249<br/>5</td>
                  <td>480<br/>2</td>
                </tr>
                <tr>
                  <td className="f">
                    09/06/24<br/>Sun</td>
                  <td>128<br/>1</td>
                  <td>580<br/>3</td>
                  <td>370<br/>0</td>
                  <td>578<br/>0</td>
                  <td>559<br/>9</td>
                  <td>580<br/>3</td>
                  <td>799<br/>5</td>
                  <td>140<br/>5</td>
                  <td>139<br/>3</td>
                  <td>146<br/>1</td>
                  <td>260<br/>8</td>
                  <td>124<br/>7</td>
                </tr>
                <tr>
                  <td className="f">
                    10/06/24<br/>Mon</td>
                  <td>130<br/>4</td>
                  <td>225<br/>9</td>
                  <td>890<br/>7</td>
                  <td>226<br/>0</td>
                  <td>440<br/>8</td>
                  <td>118<br/>0</td>
                  <td>777<br/>1</td>
                  <td>369<br/>8</td>
                  <td>334<br/>0</td>
                  <td>780<br/>5</td>
                  <td>125<br/>8</td>
                  <td>100<br/>1</td>
                </tr>
                <tr>
                  <td className="f">
                    11/06/24<br/>Tue</td>
                  <td>239<br/>4</td>
                  <td>889<br/>5</td>
                  <td>578<br/>0</td>
                  <td>129<br/>2</td>
                  <td>579<br/>1</td>
                  <td>390<br/>2</td>
                  <td>120<br/>3</td>
                  <td>347<br/>4</td>
                  <td>679<br/>2</td>
                  <td>578<br/>0</td>
                  <td>117<br/>9</td>
                  <td>579<br/>1</td>
                </tr>
                <tr>
                  <td className="f">
                    12/06/24<br/>Wed</td>
                  <td>150<br/>6</td>
                  <td>580<br/>3</td>
                  <td>578<br/>0</td>
                  <td>160<br/>7</td>
                  <td>236<br/>1</td>
                  <td>278<br/>7</td>
                  <td>235<br/>0</td>
                  <td>459<br/>8</td>
                  <td>245<br/>1</td>
                  <td>578<br/>0</td>
                  <td>358<br/>6</td>
                  <td>245<br/>1</td>
                </tr>
                <tr>
                  <td className="f">
                    13/06/24<br/>Thu</td>
                  <td>789<br/>4</td>
                  <td>239<br/>4</td>
                  <td>689<br/>3</td>
                  <td>170<br/>8</td>
                  <td>299<br/>0</td>
                  <td>346<br/>3</td>
                  <td>269<br/>7</td>
                  <td>889<br/>5</td>
                  <td>125<br/>8</td>
                  <td>337<br/>3</td>
                  <td>279<br/>8</td>
                  <td>469<br/>9</td>
                </tr>
                <tr>
                  <td className="f">
                    14/06/24<br/>Fri</td>
                  <td>257<br/>4</td>
                  <td>790<br/>6</td>
                  <td>134<br/>8</td>
                  <td>150<br/>6</td>
                  <td>130<br/>4</td>
                  <td>569<br/>0</td>
                  <td>289<br/>9</td>
                  <td>267<br/>5</td>
                  <td>359<br/>7</td>
                  <td>136<br/>0</td>
                  <td>389<br/>0</td>
                  <td>177<br/>5</td>
                </tr>
                <tr>
                  <td className="f">
                    15/06/24<br/>Sat</td>
                  <td>123<br/>6</td>
                  <td>679<br/>2</td>
                  <td>189<br/>8</td>
                  <td>156<br/>2</td>
                  <td>260<br/>8</td>
                  <td>237<br/>2</td>
                  <td>368<br/>7</td>
                  <td>349<br/>6</td>
                  <td>160<br/>7</td>
                  <td>128<br/>1</td>
                  <td>469<br/>9</td>
                  <td>120<br/>3</td>
                </tr>
                <tr>
                  <td className="f">
                    16/06/24<br/>Sun</td>
                  <td>349<br/>6</td>
                  <td>340<br/>7</td>
                  <td>450<br/>9</td>
                  <td>279<br/>8</td>
                  <td>180<br/>9</td>
                  <td>124<br/>7</td>
                  <td>133<br/>7</td>
                  <td>149<br/>4</td>
                  <td>237<br/>2</td>
                  <td>800<br/>8</td>
                  <td>679<br/>2</td>
                  <td>237<br/>2</td>
                </tr>
                <tr>
                  <td className="f">
                    17/06/24<br/>Mon</td>
                  <td>190<br/>0</td>
                  <td>456<br/>5</td>
                  <td>228<br/>2</td>
                  <td>118<br/>0</td>
                  <td>268<br/>6</td>
                  <td>200<br/>2</td>
                  <td>678<br/>1</td>
                  <td>890<br/>7</td>
                  <td>125<br/>8</td>
                  <td>169<br/>6</td>
                  <td>224<br/>8</td>
                  <td>160<br/>7</td>
                </tr>
                <tr>
                  <td className="f">
                    18/06/24<br/>Tue</td>
                  <td>170<br/>8</td>
                  <td>100<br/>1</td>
                  <td>378<br/>8</td>
                  <td>678<br/>1</td>
                  <td>116<br/>8</td>
                  <td>460<br/>0</td>
                  <td>300<br/>3</td>
                  <td>589<br/>2</td>
                  <td>340<br/>7</td>
                  <td>378<br/>8</td>
                  <td>590<br/>4</td>
                  <td>578<br/>0</td>
                </tr>
                <tr>
                  <td className="f">
                    19/06/24<br/>Wed</td>
                  <td>114<br/>6</td>
                  <td>690<br/>5</td>
                  <td>334<br/>0</td>
                  <td>225<br/>9</td>
                  <td>678<br/>1</td>
                  <td>680<br/>4</td>
                  <td>790<br/>6</td>
                  <td>590<br/>4</td>
                  <td>558<br/>8</td>
                  <td>470<br/>1</td>
                  <td>378<br/>8</td>
                  <td>144<br/>9</td>
                </tr>
                <tr>
                  <td className="f">
                    20/06/24<br/>Thu</td>
                  <td>490<br/>3</td>
                  <td>488<br/>0</td>
                  <td>567<br/>8</td>
                  <td>790<br/>6</td>
                  <td>180<br/>9</td>
                  <td>258<br/>5</td>
                  <td>689<br/>3</td>
                  <td>780<br/>5</td>
                  <td>356<br/>4</td>
                  <td>347<br/>4</td>
                  <td>357<br/>5</td>
                  <td>345<br/>2</td>
                </tr>
                <tr>
                  <td className="f">
                    21/06/24<br/>Fri</td>
                  <td>356<br/>4</td>
                  <td>260<br/>8</td>
                  <td>256<br/>3</td>
                  <td>560<br/>1</td>
                  <td>580<br/>3</td>
                  <td>380<br/>1</td>
                  <td>224<br/>8</td>
                  <td>290<br/>1</td>
                  <td>135<br/>9</td>
                  <td>668<br/>0</td>
                  <td>239<br/>4</td>
                  <td>570<br/>2</td>
                </tr>
                <tr>
                  <td className="f">
                    22/06/24<br/>Sat</td>
                  <td>170<br/>8</td>
                  <td>668<br/>0</td>
                  <td>568<br/>9</td>
                  <td>780<br/>5</td>
                  <td>247<br/>3</td>
                  <td>257<br/>4</td>
                  <td>689<br/>3</td>
                  <td>789<br/>4</td>
                  <td>147<br/>2</td>
                  <td>123<br/>6</td>
                  <td>570<br/>2</td>
                  <td>490<br/>3</td>
                </tr>
                <tr>
                  <td className="f">
                    23/06/24<br/>Sun</td>
                  <td>567<br/>8</td>
                  <td>289<br/>9</td>
                  <td>340<br/>7</td>
                  <td>567<br/>8</td>
                  <td>257<br/>4</td>
                  <td>670<br/>3</td>
                  <td>390<br/>2</td>
                  <td>667<br/>9</td>
                  <td>680<br/>4</td>
                  <td>137<br/>1</td>
                  <td>459<br/>8</td>
                  <td>568<br/>9</td>
                </tr>
                <tr>
                  <td className="f">
                    24/06/24<br/>Mon</td>
                  <td>680<br/>4</td>
                  <td>690<br/>5</td>
                  <td>678<br/>1</td>
                  <td>457<br/>6</td>
                  <td>389<br/>0</td>
                  <td>490<br/>3</td>
                  <td>569<br/>0</td>
                  <td>489<br/>1</td>
                  <td>690<br/>5</td>
                  <td>589<br/>2</td>
                  <td>460<br/>0</td>
                  <td>590<br/>4</td>
                </tr>
                <tr>
                  <td className="f">
                    25/06/24<br/>Tue</td>
                  <td>344<br/>1</td>
                  <td>368<br/>7</td>
                  <td>345<br/>2</td>
                  <td>359<br/>7</td>
                  <td>890<br/>7</td>
                  <td>340<br/>7</td>
                  <td>479<br/>0</td>
                  <td>789<br/>4</td>
                  <td>390<br/>2</td>
                  <td>490<br/>3</td>
                  <td>237<br/>2</td>
                  <td>247<br/>3</td>
                </tr>
                <tr>
                  <td className="f">
                    26/06/24<br/>Wed</td>
                  <td>137<br/>1</td>
                  <td>279<br/>8</td>
                  <td>780<br/>5</td>
                  <td>127<br/>0</td>
                  <td>124<br/>7</td>
                  <td>459<br/>8</td>
                  <td>689<br/>3</td>
                  <td>134<br/>8</td>
                  <td>117<br/>9</td>
                  <td>468<br/>8</td>
                  <td>578<br/>0</td>
                  <td>277<br/>6</td>
                </tr>
                <tr>
                  <td className="f">
                    27/06/24<br/>Thu</td>
                  <td>120<br/>3</td>
                  <td>457<br/>6</td>
                  <td>579<br/>1</td>
                  <td>349<br/>6</td>
                  <td>440<br/>8</td>
                  <td>578<br/>0</td>
                  <td>468<br/>8</td>
                  <td>225<br/>9</td>
                  <td>579<br/>1</td>
                  <td>357<br/>5</td>
                  <td>378<br/>8</td>
                  <td>357<br/>5</td>
                </tr>
                <tr>
                  <td className="f">
                    28/06/24<br/>Fri</td>
                  <td>250<br/>7</td>
                  <td>336<br/>2</td>
                  <td>679<br/>2</td>
                  <td>689<br/>3</td>
                  <td>589<br/>2</td>
                  <td>780<br/>5</td>
                  <td>556<br/>6</td>
                  <td>690<br/>5</td>
                  <td>568<br/>9</td>
                  <td>127<br/>0</td>
                  <td>357<br/>5</td>
                  <td>469<br/>9</td>
                </tr>
                <tr>
                  <td className="f">
                    29/06/24<br/>Sat</td>
                  <td>780<br/>5</td>
                  <td>137<br/>1</td>
                  <td>469<br/>9</td>
                  <td>679<br/>2</td>
                  <td>356<br/>4</td>
                  <td>236<br/>1</td>
                  <td>478<br/>9</td>
                  <td>190<br/>0</td>
                  <td>230<br/>5</td>
                  <td>347<br/>4</td>
                  <td>569<br/>0</td>
                  <td>237<br/>2</td>
                </tr>
                <tr>
                  <td className="f">
                    30/06/24<br/>Sun</td>
                  <td>180<br/>9</td>
                  <td>690<br/>5</td>
                  <td>460<br/>0</td>
                  <td>579<br/>1</td>
                  <td>246<br/>2</td>
                  <td>160<br/>7</td>
                  <td>268<br/>6</td>
                  <td>256<br/>3</td>
                  <td>112<br/>4</td>
                  <td>330<br/>6</td>
                  <td>679<br/>2</td>
                  <td>190<br/>0</td>
                </tr>
                <tr>
                  <td className="f">
                    01/07/24<br/>Mon</td>
                  <td>180<br/>9</td>
                  <td>146<br/>1</td>
                  <td>230<br/>5</td>
                  <td>560<br/>1</td>
                  <td>167<br/>4</td>
                  <td>267<br/>5</td>
                  <td>678<br/>1</td>
                  <td>270<br/>9</td>
                  <td>279<br/>8</td>
                  <td>370<br/>0</td>
                  <td>568<br/>9</td>
                  <td>345<br/>2</td>
                </tr>
                <tr>
                  <td className="f">
                    02/07/24<br/>Tue</td>
                  <td>389<br/>0</td>
                  <td>140<br/>5</td>
                  <td>460<br/>0</td>
                  <td>224<br/>8</td>
                  <td>137<br/>1</td>
                  <td>122<br/>5</td>
                  <td>370<br/>0</td>
                  <td>690<br/>5</td>
                  <td>356<br/>4</td>
                  <td>569<br/>0</td>
                  <td>168<br/>5</td>
                  <td>360<br/>9</td>
                </tr>
                <tr>
                  <td className="f">
                    03/07/24<br/>Wed</td>
                  <td>680<br/>4</td>
                  <td>357<br/>5</td>
                  <td>290<br/>1</td>
                  <td>279<br/>8</td>
                  <td>159<br/>5</td>
                  <td>345<br/>2</td>
                  <td>579<br/>1</td>
                  <td>456<br/>5</td>
                  <td>336<br/>2</td>
                  <td>780<br/>5</td>
                  <td>158<br/>4</td>
                  <td>479<br/>0</td>
                </tr>
                <tr>
                  <td className="f">
                    04/07/24<br/>Thu</td>
                  <td>479<br/>0</td>
                  <td>577<br/>9</td>
                  <td>244<br/>0</td>
                  <td>789<br/>4</td>
                  <td>567<br/>8</td>
                  <td>358<br/>6</td>
                  <td>128<br/>1</td>
                  <td>257<br/>4</td>
                  <td>679<br/>2</td>
                  <td>240<br/>6</td>
                  <td>134<br/>8</td>
                  <td>257<br/>4</td>
                </tr>
                <tr>
                  <td className="f">
                    05/07/24<br/>Fri</td>
                  <td>570<br/>2</td>
                  <td>456<br/>5</td>
                  <td>123<br/>6</td>
                  <td>280<br/>0</td>
                  <td>679<br/>2</td>
                  <td>569<br/>0</td>
                  <td>679<br/>2</td>
                  <td>128<br/>1</td>
                  <td>458<br/>7</td>
                  <td>120<br/>3</td>
                  <td>468<br/>8</td>
                  <td>579<br/>1</td>
                </tr>
                <tr>
                  <td className="f">
                    06/07/24<br/>Sat</td>
                  <td>349<br/>6</td>
                  <td>179<br/>7</td>
                  <td>167<br/>4</td>
                  <td>189<br/>8</td>
                  <td>139<br/>3</td>
                  <td>479<br/>0</td>
                  <td>166<br/>3</td>
                  <td>159<br/>5</td>
                  <td>257<br/>4</td>
                  <td>122<br/>5</td>
                  <td>135<br/>9</td>
                  <td>458<br/>7</td>
                </tr>
                <tr>
                  <td className="f">
                    07/07/24<br/>Sun</td>
                  <td>178<br/>6</td>
                  <td>257<br/>4</td>
                  <td>234<br/>9</td>
                  <td>169<br/>6</td>
                  <td>227<br/>1</td>
                  <td>268<br/>6</td>
                  <td>346<br/>3</td>
                  <td>590<br/>4</td>
                  <td>166<br/>3</td>
                  <td>236<br/>1</td>
                  <td>580<br/>3</td>
                  <td>467<br/>7</td>
                </tr>
                <tr>
                  <td className="f">
                    08/07/24<br/>Mon</td>
                  <td>458<br/>7</td>
                  <td>156<br/>2</td>
                  <td>148<br/>3</td>
                  <td>134<br/>8</td>
                  <td>458<br/>7</td>
                  <td>458<br/>7</td>
                  <td>568<br/>9</td>
                  <td>890<br/>7</td>
                  <td>234<br/>9</td>
                  <td>269<br/>7</td>
                  <td>289<br/>9</td>
                  <td>480<br/>2</td>
                </tr>
                <tr>
                  <td className="f">
                    09/07/24<br/>Tue</td>
                  <td>180<br/>9</td>
                  <td>480<br/>2</td>
                  <td>389<br/>0</td>
                  <td>167<br/>4</td>
                  <td>479<br/>0</td>
                  <td>579<br/>1</td>
                  <td>260<br/>8</td>
                  <td>137<br/>1</td>
                  <td>145<br/>0</td>
                  <td>246<br/>2</td>
                  <td>370<br/>0</td>
                  <td>134<br/>8</td>
                </tr>
                <tr>
                  <td className="f">
                    10/07/24<br/>Wed</td>
                  <td>690<br/>5</td>
                  <td>589<br/>2</td>
                  <td>123<br/>6</td>
                  <td>369<br/>8</td>
                  <td>259<br/>6</td>
                  <td>288<br/>8</td>
                  <td>180<br/>9</td>
                  <td>140<br/>5</td>
                  <td>489<br/>1</td>
                  <td>230<br/>5</td>
                  <td>137<br/>1</td>
                  <td>268<br/>6</td>
                </tr>
                <tr>
                  <td className="f">
                    11/07/24<br/>Thu</td>
                  <td>368<br/>7</td>
                  <td>280<br/>0</td>
                  <td>157<br/>3</td>
                  <td>250<br/>7</td>
                  <td>249<br/>5</td>
                  <td>168<br/>5</td>
                  <td>256<br/>3</td>
                  <td>780<br/>5</td>
                  <td>112<br/>4</td>
                  <td>169<br/>6</td>
                  <td>245<br/>1</td>
                  <td>116<br/>8</td>
                </tr>
                <tr>
                  <td className="f">
                    12/07/24<br/>Fri</td>
                  <td>690<br/>5</td>
                  <td>339<br/>5</td>
                  <td>789<br/>4</td>
                  <td>780<br/>5</td>
                  <td>345<br/>2</td>
                  <td>680<br/>4</td>
                  <td>457<br/>6</td>
                  <td>468<br/>8</td>
                  <td>157<br/>3</td>
                  <td>156<br/>2</td>
                  <td>120<br/>3</td>
                  <td>220<br/>4</td>
                </tr>
                <tr>
                  <td className="f">
                    13/07/24<br/>Sat</td>
                  <td>266<br/>4</td>
                  <td>670<br/>3</td>
                  <td>158<br/>4</td>
                  <td>779<br/>3</td>
                  <td>679<br/>2</td>
                  <td>148<br/>3</td>
                  <td>589<br/>2</td>
                  <td>239<br/>4</td>
                  <td>237<br/>2</td>
                  <td>139<br/>3</td>
                  <td>579<br/>1</td>
                  <td>257<br/>4</td>
                </tr>
                <tr>
                  <td className="f">
                    14/07/24<br/>Sun</td>
                  <td>245<br/>1</td>
                  <td>130<br/>4</td>
                  <td>279<br/>8</td>
                  <td>478<br/>9</td>
                  <td>457<br/>6</td>
                  <td>589<br/>2</td>
                  <td>468<br/>8</td>
                  <td>247<br/>3</td>
                  <td>358<br/>6</td>
                  <td>270<br/>9</td>
                  <td>790<br/>6</td>
                  <td>267<br/>5</td>
                </tr>
                <tr>
                  <td className="f">
                    15/07/24<br/>Mon</td>
                  <td>116<br/>8</td>
                  <td>445<br/>3</td>
                  <td>568<br/>9</td>
                  <td>130<br/>4</td>
                  <td>149<br/>4</td>
                  <td>236<br/>1</td>
                  <td>369<br/>8</td>
                  <td>146<br/>1</td>
                  <td>467<br/>7</td>
                  <td>249<br/>5</td>
                  <td>125<br/>8</td>
                  <td>489<br/>1</td>
                </tr>
                <tr>
                  <td className="f">
                    16/07/24<br/>Tue</td>
                  <td>120<br/>3</td>
                  <td>579<br/>1</td>
                  <td>123<br/>6</td>
                  <td>278<br/>7</td>
                  <td>460<br/>0</td>
                  <td>459<br/>8</td>
                  <td>124<br/>7</td>
                  <td>190<br/>0</td>
                  <td>789<br/>4</td>
                  <td>289<br/>9</td>
                  <td>178<br/>6</td>
                  <td>237<br/>2</td>
                </tr>
                <tr>
                  <td className="f">
                    17/07/24<br/>Wed</td>
                  <td>127<br/>0</td>
                  <td>340<br/>7</td>
                  <td>369<br/>8</td>
                  <td>158<br/>4</td>
                  <td>340<br/>7</td>
                  <td>459<br/>8</td>
                  <td>345<br/>2</td>
                  <td>490<br/>3</td>
                  <td>269<br/>7</td>
                  <td>570<br/>2</td>
                  <td>370<br/>0</td>
                  <td>237<br/>2</td>
                </tr>
                <tr>
                  <td className="f">
                    18/07/24<br/>Thu</td>
                  <td>468<br/>8</td>
                  <td>135<br/>9</td>
                  <td>468<br/>8</td>
                  <td>128<br/>1</td>
                  <td>890<br/>7</td>
                  <td>560<br/>1</td>
                  <td>148<br/>3</td>
                  <td>170<br/>8</td>
                  <td>135<br/>9</td>
                  <td>680<br/>4</td>
                  <td>379<br/>9</td>
                  <td>160<br/>7</td>
                </tr>
                <tr>
                  <td className="f">
                    19/07/24<br/>Fri</td>
                  <td>260<br/>8</td>
                  <td>157<br/>3</td>
                  <td>389<br/>0</td>
                  <td>356<br/>4</td>
                  <td>467<br/>7</td>
                  <td>237<br/>2</td>
                  <td>368<br/>7</td>
                  <td>135<br/>9</td>
                  <td>280<br/>0</td>
                  <td>290<br/>1</td>
                  <td>460<br/>0</td>
                  <td>123<br/>6</td>
                </tr>
                <tr>
                  <td className="f">
                    20/07/24<br/>Sat</td>
                  <td>144<br/>9</td>
                  <td>257<br/>4</td>
                  <td>579<br/>1</td>
                  <td>240<br/>6</td>
                  <td>136<br/>0</td>
                  <td>126<br/>9</td>
                  <td>146<br/>1</td>
                  <td>780<br/>5</td>
                  <td>138<br/>2</td>
                  <td>247<br/>3</td>
                  <td>124<br/>7</td>
                  <td>123<br/>6</td>
                </tr>
                <tr>
                  <td className="f">
                    21/07/24<br/>Sun</td>
                  <td>178<br/>6</td>
                  <td>789<br/>4</td>
                  <td>168<br/>5</td>
                  <td>570<br/>2</td>
                  <td>368<br/>7</td>
                  <td>110<br/>2</td>
                  <td>223<br/>7</td>
                  <td>237<br/>2</td>
                  <td>189<br/>8</td>
                  <td>578<br/>0</td>
                  <td>279<br/>8</td>
                  <td>479<br/>0</td>
                </tr>
                <tr>
                  <td className="f">
                    22/07/24<br/>Mon</td>
                  <td>190<br/>0</td>
                  <td>350<br/>8</td>
                  <td>578<br/>0</td>
                  <td>178<br/>6</td>
                  <td>569<br/>0</td>
                  <td>246<br/>2</td>
                  <td>578<br/>0</td>
                  <td>167<br/>4</td>
                  <td>450<br/>9</td>
                  <td><br/></td>
                  <td><br/></td>
                  <td><br/></td>
                </tr>
              </tbody>
            </table>


          </div>
        </div>

        <br/><br/><br/>
      </div>
<br/>
<br/>
<br/>
<Footer/>
    </div>
  </div>



  <br/><br/><br/>
    </div>
  )
}

export default StarLine_Chart_History