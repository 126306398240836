import React, { useState, useEffect } from 'react'
import Header from '../../common/header/Header'
import Footer from '../../common/footer/Footer'
import { FaRupeeSign } from "react-icons/fa";
import { GetCurrentDate, SubmitBid } from '../../../api/AllApi';
const Triple_Patti = () => {
    const [Amount, setAmount] = useState("");
    const [TotalAmount, setTotalAmount] = useState({});
    console.log("Amount", Amount)
    const [isAmountActive, setIsAmountActive] = useState(null);
    const [Digits, setDigits] = useState([]);
    const [grandTotal, setGrandTotal] = useState(0);
    const [bid_date, setBidDate] = useState("");
    const [game_type, setGame_Type] = useState("");
    const user_id = localStorage.getItem("user_id");
    const game_id = localStorage.getItem("game_id");
    const game_name = localStorage.getItem("game_name");
    const [isInputChanged, setIsInputChanged] = useState(false);
    const [formData, setFormData] = useState([]);
    console.log("Digits", Digits)

    console.log("HandleIsAmountActive", isAmountActive)
    const handleAmountSelection = (amountValue, index) => {
        setAmount(amountValue);
        setIsAmountActive(index);
    };


    const handleInputChange = (inputId, digit) => {
        setTotalAmount((prevState) => ({
            ...prevState,
            [inputId]: (prevState[inputId] || 0) + Amount,
        }));

        setDigits((prevState) => ({
            ...prevState,
            [inputId]: digit,
        }));

        setFormData((prevData) => ({
            ...prevData,
            [inputId]: {
                ...prevData[inputId],
                points: (prevData[inputId]?.points || 0) + Amount,
                digits: digit,
                type: 'open',
            },
        }));
        setIsInputChanged(true);
    };


    const formattedData = Object.keys(formData).map((key) => formData[key]);

    const isButtonDisabled = !game_type || !isInputChanged;
    const handleSubmit = async (e) => {
        e.preventDefault();
        const dataToSend = {
            bid_date: bid_date,
            gameid: game_id,
            user_id: user_id,
            Gamename: game_name,
            totalbit: grandTotal,
            pana: "Single Digit",
            session: game_type,
            result: formattedData,
        };
        const data = {
            new_result: dataToSend,
            app_key: "@34Y&D9(JE4dsi36f$%#(tigergames!xyz)8fe8ef8ef8"
        }

        console.log('Data to send:', data);
        try {
            const response = await SubmitBid(data)
            console.log("handleSubmit", response?.data?.status == false)
            if (response?.data?.status === false) {
                alert("Sorry You Don't Have Sufficient Amount For This Bid")
            }
        } catch (error) {
            console.log("error", error)
        }
    };


    // <---------- Get the Current Date ------------>
    const HandleGetCurrentTime = async () => {
        const dataToSend = {
            app_key: "@34Y&D9(JE4dsi36f$%#(tigergames!xyz)8fe8ef8ef8",
            game_id,
            user_id
        };
        console.log('Data to send:', dataToSend);
        try {
            const response = await GetCurrentDate(dataToSend)
            setBidDate(response?.data?.date)
        } catch (error) {
            console.log("error", error)
        }
    };
    console.log("bid_date", bid_date)
    const calculateTotalAmount = () => {
        const total = Object.values(TotalAmount).reduce((acc, value) => acc + value, 0);
        setGrandTotal(total);
    };

    useEffect(() => {
        calculateTotalAmount();
    }, [TotalAmount]);

    useEffect(() => {
        HandleGetCurrentTime();
    }, []);

    const resetAmounts = () => {
        setTotalAmount({});
    };

    return (
        <div className="wrapper">
            <div id="content">
                <Header />
                <div className="container">
                    <div className="card-full-page tb-10">

                        <form action="" method="POST" className="myform">

                            <div className="row bidoptions-list tb-10">
                                <div className="col-6">
                                    <a className="dateGameIDbox">
                                        <p>{bid_date}</p>
                                    </a>
                                </div>


                                <div className="col-6">
                                    <select className="dateGameIDbox" name="game_type" value={game_type} onChange={(e) => setGame_Type(e.target.value)} required>
                                        <option value="" disabled>Select the Game Type</option>
                                        <option value="CLOSE">{game_name} CLOSE</option>
                                        <option value="OPEN">{game_name} OPEN</option>
                                    </select>
                                </div>

                            </div>



                            <div className="tb-10"><hr className="devider" /></div>

                            <h3 className="subheading">Select Amount</h3>
                            <div className="row bidoptions-list tb-10">
                                <div className="col-3">
                                    <a id="amount_5" value="5" name="Amount" data="5"
                                        className={`bidamtbox ${isAmountActive == "0" ? "active_amounts" : ""}`}
                                        onClick={() => {
                                            handleAmountSelection(5, 0)
                                        }}>
                                        <p><FaRupeeSign size={12} /> 5</p>
                                    </a>
                                </div>

                                <div className="col-3">
                                    <a className={`bidamtbox ${isAmountActive == 1 ? "active_amounts" : ""}`}
                                        onClick={() => {
                                            handleAmountSelection(10, 1)
                                        }} id="amount_10" data="10" value="10" name="Amount">
                                        <p><FaRupeeSign size={12} /> 10</p>
                                    </a>
                                </div>

                                <div className="col-3">
                                    <a className={`bidamtbox ${isAmountActive === 2 ? "active_amounts" : ""}`}
                                        onClick={() => {
                                            handleAmountSelection(50, 2)
                                        }}
                                        id="amount_50" data="50" value="50" name="Amount">
                                        <p ><FaRupeeSign size={12} /> 50</p>
                                    </a>
                                </div>
                                <div className="col-3">
                                    <a className={`bidamtbox ${isAmountActive === 3 ? "active_amounts" : ""}`}
                                        onClick={() => {
                                            handleAmountSelection(100, 3)
                                        }} id="amount_100" data="100" value="100" name="Amount">
                                        <p><FaRupeeSign size={12} /> 100</p>
                                    </a>
                                </div>
                            </div>





                            <div className="row bidoptions-list tb-10">
                                <div className="col-3">
                                    <a className={`bidamtbox ${isAmountActive === 4 ? "active_amounts" : ""}`}
                                        onClick={() => {
                                            handleAmountSelection(200, 4)
                                        }} id="amount_200" data="200" value="200" name="Amount">
                                        <p><FaRupeeSign size={12} /> 200</p>
                                    </a>
                                </div>

                                <div className="col-3">
                                    <a className={`bidamtbox ${isAmountActive === 5 ? "active_amounts" : ""}`}
                                        onClick={() => {
                                            handleAmountSelection(500, 5)
                                        }} id="amount_500" data="500" value="500" name="Amount">
                                        <p><FaRupeeSign size={12} /> 500</p>
                                    </a>
                                </div>

                                <div className="col-3">
                                    <a className={`bidamtbox ${isAmountActive === 6 ? "active_amounts" : ""}`}
                                        onClick={() => {
                                            handleAmountSelection(1000, 6)
                                        }} id="amount_1000" data="1000" value="1000" name="Amount">
                                        <p><FaRupeeSign size={12} /> 1000</p>
                                    </a>
                                </div>
                                <div className="col-3">
                                    <a className={`bidamtbox ${isAmountActive == 7 ? "active_amounts" : ""}`}
                                        onClick={() => {
                                            handleAmountSelection(5000, 7)
                                        }} id="amount_5000" data="5000" value="5000" name="Amount">
                                        <p><FaRupeeSign size={12} /> 5000</p>
                                    </a>
                                </div>
                            </div>

                            <div className="tb-10"><hr className="devider" /></div>
                            <h3 className="subheading">Select Digits</h3>

                            <div className="row bidoptions-list tb-10">


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>000</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['triple_patti000'] || ''}
                                            className="pointinputbox"
                                            id="triple_patti000"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('triple_patti000', "000");
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>111</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['triple_patti111'] || ''}
                                            className="pointinputbox"
                                            id="triple_patti111"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('triple_patti111', 111);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>222</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['triple_patti222'] || ''}
                                            className="pointinputbox"
                                            id="triple_patti222"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('triple_patti222', 222);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>333</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['triple_patti333'] || ''}
                                            className="pointinputbox"
                                            id="triple_patti333"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('triple_patti333', 333);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>444</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['triple_patti444'] || ''}
                                            className="pointinputbox"
                                            id="triple_patti444"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('triple_patti444', 444);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>555</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['triple_patti555'] || ''}
                                            className="pointinputbox"
                                            id="triple_patti555"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('triple_patti555', 555);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>666</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['triple_patti666'] || ''}
                                            className="pointinputbox"
                                            id="triple_patti666"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('triple_patti666', 666);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>777</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['triple_patti777'] || ''}
                                            className="pointinputbox"
                                            id="triple_patti777"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('triple_patti777', 777);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>888</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['triple_patti888'] || ''}
                                            className="pointinputbox"
                                            id="triple_patti888"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('triple_patti888', 888);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>999</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['triple_patti999'] || ''}
                                            className="pointinputbox"
                                            id="triple_patti999"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('triple_patti999', 999);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <input type="hidden" id="total_point" name="total_point" value="" />
                            <input type="hidden" id="selected_amount" value="" />
                            <input type="hidden" name="gid" value="94" />
                            <input type="hidden" name="pgid" value="53" />
                            <input type="hidden" name="dgame" value="close" />
                            <div className="tbmar-20 text-center">
                                <p>Total Points : <a id="total_point2">{grandTotal}</a></p>
                            </div>

                            <div className="row bidoptions-list tb-10">
                                <div className="col-6">
                                    <button className="btn btn-light btn-streched" onClick={resetAmounts} type="reset">Reset</button>
                                </div>

                                <div className="col-6">
                                    <button className="btn btn-theme btn-streched" disabled={isButtonDisabled} type="button" onClick={handleSubmit}
                                        name="single_submit">Submit</button>
                                </div>

                            </div>
                        </form>
                        <br /><br /><br /><br /><br /><br />
                    </div>
                </div>
                <br /><br /><br />
            </div>
            <Footer />
        </div>
    )
}

export default Triple_Patti