import React from 'react'
import Header from '../../common/header/Header'
import "../../../assets/css/style.css";
import { Link } from 'react-router-dom';
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { PiHandWithdrawFill,PiHandDepositBold } from "react-icons/pi";
import { BsBank } from "react-icons/bs";
import { IoMdPhonePortrait } from "react-icons/io";
import Footer from '../../common/footer/Footer';
const Fund_History = () => {
  return (
     <div className="wrapper">
   
    <div id="content">
        <Header/>
        <div className="container">
                    <div className="tb-10">

                        {/* <div className="row game-list-inner">
                        <div className="col-12">
                                <Link to="/add_fund" className="mplist"><FaRegMoneyBillAlt size={22}/>Add Fund</Link>
                            </div>
                        </div> */}
                        <div className="row game-list-inner">
                        <div className="col-12">
                                <Link to="/withdraw" className="mplist"><PiHandWithdrawFill  size={22}/>Withdraw Fund</Link>
                            </div>
                        </div>
                        <div className="row game-list-inner">
                        <div className="col-12">
                                <Link to="/update_bank_details" className="mplist"><BsBank  size={22}/>Add Bank Details</Link>
                            </div>
                        </div>
                        {/* <div className="row game-list-inner">
                        <div className="col-12">
                                <Link to="/add_upi_details" className="mplist"><IoMdPhonePortrait   size={22}/>Add UPI Details</Link>
                            </div>
                        </div> */}
                        <div className="row game-list-inner">
                            <div className="col-12">
                                <Link to="/deposit_passbook" className="mplist"><PiHandDepositBold  size={22}/> Fund Deposit History</Link>
                            </div>
                        </div>
                        <div className="row game-list-inner">
                            <div className="col-12">
                                <Link to="/withdraw_password" className="mplist"><PiHandWithdrawFill  size={22}/> Fund Withdraw History</Link>
                            </div>
                        </div>
                    </div>
                </div>

    </div>
    <br/>
    <br/>
    <Footer/>
  </div>
  )
}

export default Fund_History