import React, { useEffect, useState } from 'react'
import Header from '../../common/header/Header'
import Footer from '../../common/footer/Footer'
import { UserAddFund } from '../../../api/AllApi'

const Add_Fund = () => {
  const [ac_holder_name, setAcHolderName] = useState("");
  const [upi_payment_id, setUpiPaymentId] = useState("");

  const GetUserFunds = async () => {
    const FundsData = {
      app_key: "@34Y&D9(JE4dsi36f$%#(tigergames!xyz)8fe8ef8ef8"
    }
    try {
      const response = await UserAddFund(FundsData)
      setAcHolderName(response?.data?.bank_details[0]?.ac_holder_name)
      setUpiPaymentId(response?.data?.bank_details[0]?.upi_payment_id)
      console.log("GetUserFunds", response)
    } catch (error) {
      console.log("error", error)
    }
  }
  useEffect(() => {
    GetUserFunds();
  }, [])
  return (
    <div className="wrapper">

      <div id="content">
        <Header />
        <div className="container">
          <div className="card tb-10">
            <div className="text-center tb-10">
              <h3>Add Fund</h3>
            </div>

            <div className="tbmar-20 text-center">
              <p>Payment add krne ke 5 minute ke andar aapke wallet me points add ho jayenge.<br /> Dont worry Wait kriye.
                <br />Your money is always safe with PhonePe Matka </p>

            </div>

            <div className="tb-10">
              <hr className="devider" />
            </div>

            <h3 className="subheading">Enter Amount</h3>
            <form action="" method="POST" autocomplete="off">
              <div className='row'>

                <div className="col-6">
                  <div className="bidinputdiv">
                    <lable>Payee Name</lable>
                    <input type="text" name='ac_holder_name' value={ac_holder_name} disabled
                      className="pointinputbox" />
                  </div>
                </div>
                <div className="col-6">
                  <div className="bidinputdiv">
                    <lable>Payee Upi id</lable>
                    <input type="text" name='upi_payment_id' value={upi_payment_id} disabled
                      className="pointinputbox" id="jodi00" />
                  </div>
                </div>
                <div className="col-6">
                  <div className="bidinputdiv">
                    <lable>Amount</lable>
                    <input type="text"
                      className="pointinputbox" id="jodi00" />
                  </div>
                </div>
                <div className="col-6">
                  <div className="bidinputdiv">
                    <lable>Description</lable>
                    <input type="text"
                      className="pointinputbox" id="jodi00" />
                  </div>
                </div>
                <div className="col-12">
                  <div className="pointinputbox text-center">
                    <buuton type="button" className="btn SUBMIT_button">SUBMIT</buuton>
                  </div>
                </div>
              </div>
            </form>

            <div className="text-center tbmar-20">
              <p>Unable to Add Fund?</p>
              <a href="https://wa.me/+91 xxxxxx7894" className="btn btn-outline btn-login">Contact Admin for help</a>
            </div>

          </div>
        </div>
        <br /><br /><br />
      </div>
      <Footer />
    </div>
  )
}

export default Add_Fund