import axios from "axios";
import { API_BASE_URL } from "../config/ApiConfig";

// <---------------- Student Register Api's Function--------------->
export const GetDashBoarddata = async (DashBoarddata) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api-get-dashboard-data`, DashBoarddata)
        return response;
    } catch (error) {
        return error
    }

}

// <---------------- Student Register Api's Function--------------->
export const GetPassbookList = async (DashBoarddata) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api-wallet-transaction-history`, DashBoarddata)
        return response;
    } catch (error) {
        return error
    }

}

// <---------------- Student Register Api's Function--------------->
export const GetBiddingHistory = async (BiddingHistorydata) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api-bid-history-data`, BiddingHistorydata)
        return response;
    } catch (error) {
        return error
    }

}

// <---------------- Student Register Api's Function--------------->
export const GetGameResult = async (BiddingHistorydata) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api-wining-history-data`, BiddingHistorydata)
        return response;
    } catch (error) {
        return error
    }

}
// <---------------- Student Register Api's Function--------------->
export const GetStarLineBidHistoryResult = async (BiddingHistorydata) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api-starline-bid-history-data`, BiddingHistorydata)
        return response;
    } catch (error) {
        return error
    }

}

// <---------------- Student Register Api's Function--------------->
export const GetBankDetails = async (BiddingHistorydata) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api-get-user-payment-details`, BiddingHistorydata)
        return response;
    } catch (error) {
        return error
    }

}

// <---------------- Student Register Api's Function--------------->
export const AddBankDetails = async (BiddingHistorydata) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api-add-user-bank-details`, BiddingHistorydata)
        return response;
    } catch (error) {
        return error
    }

}

// <---------------- User Profile section's--------------->
export const GetUserProfile = async (UserData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api-get-profile`, UserData)
        return response;
    } catch (error) {
        return error
    }

}
// <---------------- User Profile Update --------------->
export const UserProfileUpdate = async (UserProfileUpdateData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api-profile-update`, UserProfileUpdateData)
        return response;
    } catch (error) {
        return error
    }

}

// <---------------- Enquiry  --------------->
export const UserEnquiry = async (UserEnquiryData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api-submit-contact-us`, UserEnquiryData)
        return response;
    } catch (error) {
        return error
    }

}

// <---------------- Add Fund  --------------->
export const UserAddFund = async (UserAddFundData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api-admin-bank-details`, UserAddFundData)
        return response;
    } catch (error) {
        return error
    }

}

// <---------------- Add Fund  --------------->
export const GetGameRate = async (UserAddFundData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api-game-rates`, UserAddFundData)
        return response;
    } catch (error) {
        return error
    }

}

// <---------------- Get Current Time  --------------->
export const CheckGameStatus = async (GetUserCurrentTimeData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api-check-game-status`, GetUserCurrentTimeData)
        return response;
    } catch (error) {
        return error
    }

}

// <---------------- Get Current Time  --------------->
export const GetCurrentDate = async (GetUserCurrentTimeData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api-get-current-date`, GetUserCurrentTimeData)
        return response;
    } catch (error) {
        return error
    }

}

// <---------------- Submit Bid  --------------->
export const SubmitBid = async (GetUserCurrentTimeData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api-submit-bid`, GetUserCurrentTimeData)
        return response;
    } catch (error) {
        return error
    }

}
// <---------------- Submit Bid  --------------->
export const GetNotification = async (GetUserCurrentTimeData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api-get-notification`, GetUserCurrentTimeData)
        return response;
    } catch (error) {
        return error
    }

}

// <---------------- Submit Bid  --------------->
export const GetTheWithdrawMwthods = async (GetUserCurrentTimeData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api-user-payment-method-list`, GetUserCurrentTimeData)
        return response;
    } catch (error) {
        return error
    }

}

export const PostTheWithdrawMwthods = async (GetUserCurrentTimeData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api-user-withdraw-fund-request`, GetUserCurrentTimeData)
        return response;
    } catch (error) {
        return error
    }

}

export const GetStarlineGameList  = async (GetUserCurrentTimeData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api-starline-game`, GetUserCurrentTimeData)
        return response;
    } catch (error) {
        return error
    }

}

export const StarlineSubmitBid  = async (GetUserCurrentTimeData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api-starline-submit-bid`, GetUserCurrentTimeData)
        return response;
    } catch (error) {
        return error
    }

}

