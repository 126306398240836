import React,{useState,useEffect} from 'react'
import Header from '../../common/header/Header'
import Footer from '../../common/footer/Footer'
import { GetCurrentDate, SubmitBid } from '../../../api/AllApi';
const Full_Sangam = () => {
  const [digits, setDigits] = useState('');
  const [closeDigits, setCloseDigits] = useState('');
  const [amount, setAmount] = useState('');
  const [bid_date, setBidDate] = useState("");
  const [game_type, setGame_Type] = useState("");
  const user_id = localStorage.getItem("user_id");
  const game_id = localStorage.getItem("game_id");
  const game_name = localStorage.getItem("game_name");
  const [isInputChanged, setIsInputChanged] = useState(false);
  const [formData, setFormData] = useState({
    amount: "",
    digits: "",
    type: "open",
    closedigits: closeDigits
  });


  const formattedData = [
    {
      amount: formData.amount,
      digits: formData.digits,
      type: formData.type,
      closedigits: formData.closedigits,
    },
  ];


  const handleOpenDigitChange = (e) => {
    const value = e.target.value;
    if (value.length === 1 && value >= 0 && value <= 9) {
      setDigits(value);
    } else {
      alert("Please enter a single digit between 0 and 9");
    }
  };

  const handleClosePattiChange = (e) => {
    const value = e.target.value;
    if (value.length <= 3 && value >= 0 && value <= 999) {
      setCloseDigits(value);
    } else {
      alert("Please enter a number between 000 and 999");
    }
  };

  // Handle change for Amount
  const handleAmountChange = (e) => {
    const value = e.target.value;
    setAmount(value);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataToSend = {
      bid_date: bid_date,
      gameid: game_id,
      user_id: user_id,
      Gamename: game_name,
      totalbit: amount,
      pana: "Half Sangam",
      session: game_type,
      result: formattedData,
    };
    const data = {
      new_result: dataToSend,
      app_key: "@34Y&D9(JE4dsi36f$%#(tigergames!xyz)8fe8ef8ef8"
    }

    console.log('Data to send1:', data);
    try {
      const response = await SubmitBid(data)
      console.log("handleSubmit", response?.data?.status == false)
      if (response?.data?.status === false) {
        alert("Sorry You Don't Have Sufficient Amount For This Bid")
      }
    } catch (error) {
      console.log("error", error)
    }
  };


  // <---------- Get the Current Date ------------>
  const HandleGetCurrentTime = async () => {
    const dataToSend = {
      app_key: "@34Y&D9(JE4dsi36f$%#(tigergames!xyz)8fe8ef8ef8",
      game_id,
      user_id
    };
    console.log('Data to send:', dataToSend);
    try {
      const response = await GetCurrentDate(dataToSend)
      setBidDate(response?.data?.date)
    } catch (error) {
      console.log("error", error)
    }
  };
  console.log("bid_date", bid_date)

  useEffect(() => {
    HandleGetCurrentTime();
  }, []);

  useEffect(() => {
    setFormData({
      amount,
      digits,
      type: game_type,
      closedigits: closeDigits,
    });
  }, [digits, closeDigits, amount, game_type]);

  const resetAmounts = () => {
    setDigits('');
    setCloseDigits('');
    setAmount('');
    setGame_Type('');
  };
  return (
    <div className="wrapper">

      <div id="content">
        <Header />
        <div className="container">
          <div className="card-full-page tb-10">

            <form action="" method="POST" className="myform" autocomplete="off">

              <div className="row bidoptions-list tb-10">
                <div className="col-6">
                  <a className="dateGameIDbox">
                  <p>{bid_date}</p>
                  </a>
                </div>
                <div className="col-6">
                  <a className="dateGameIDbox">
                  <p>{game_name}</p>
                  </a>
                </div>

                {/* <div className="col-6">
                  <select className="dateGameIDbox" name="game_type" value={game_type} onChange={(e) => setGame_Type(e.target.value)} required>
                    <option value="" disabled>Select the Game Type</option>
                    <option value="CLOSE">{game_name} CLOSE</option>
                    <option value="OPEN">{game_name} OPEN</option>
                  </select>
                </div> */}

              </div>



              <div className="tb-10">
                <hr className="devider" />
              </div>

              <h3 className="subheading">Play Full Sangam</h3>

              <div className="row bidoptions-list tb-10">

                <div className="col-4" style={{ paddingRight: "5px", paddingLeft: "5px" }}>
                  <div className="bidinputdiv">
                    <lable>Open Patti</lable>
                    <input type="number"
                      min="0"
                      max="9"
                      placeholder="0-9"
                      name="digits"
                      value={digits}
                      onChange={handleOpenDigitChange}
                      style={{ padding: "10px 5px", cursor: "text" }}
                      required />
                  </div>
                </div>
                <div className="col-4" style={{ paddingRight: "5px", paddingLeft: "5px" }}>
                  <div className="bidinputdiv">
                    <lable>Close Patti</lable>
                    <input type="number"
                      min="000"
                      max="999"
                      placeholder="000-999"
                      name="closeDigits"
                      value={closeDigits}
                      onChange={handleClosePattiChange}
                      style={{ padding: "10px 5px", cursor: "text" }}
                      required />
                  </div>
                </div>

                <div className="col-4" style={{ paddingRight: "5px", paddingLeft: "5px" }}>
                  <div className="bidinputdiv">
                    <lable>Amount</lable>
                    <input type="number"
                      min="5"
                      name="amount"
                      value={amount}
                      onChange={handleAmountChange}
                      style={{ padding: "10px 5px", cursor: "text" }}
                      required
                      disabled={!digits || !closeDigits} />
                  </div>
                </div>

              </div>
              <br /><br /><br />

              <div className="row bidoptions-list tb-10">
                <div className="col-6">
                  <button className="btn btn-light btn-streched" onClick={resetAmounts} type="button">Reset</button>
                </div>
                <div className="col-6">
                  <button className="btn btn-theme btn-streched" type="button" onClick={handleSubmit} name="single_submit">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  )
}

export default Full_Sangam