import React, { useState, useEffect } from 'react'
import Header from '../../common/header/Header'
import Footer from '../../common/footer/Footer'
import { GetUserProfile, UserEnquiry } from '../../../api/AllApi';

const Enquiry = () => {
  const [user_name, setUser_Name] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [enquiry, setEnquiry] = useState("");
  const user_id = localStorage.getItem("user_id");

  //<------ Get the User Profile here -------> 
  const HandleGetUserProfile = async () => {
    const UserData = {
      app_key: "@34Y&D9(JE4dsi36f$%#(tigergames!xyz)8fe8ef8ef8",
      user_id
    }
    try {
      const response = await GetUserProfile(UserData)
      console.log("HandleGetUserProfile", response?.data?.profile)
      setMobile(response?.data?.profile[0]?.mobile)
      setUser_Name(response?.data?.profile[0]?.user_name)
      setEnquiry(response?.data?.profile[0]?.enquiry)
      setEmail(response?.data?.profile[0]?.email)
    }
    catch (error) {
      console.log(error)
    }
  }

  // <-------- Update the User Profile ----------->
  const HandleUserEnquiry = async (e) => {
    e.preventDefault();
    const UserProfileUpdateData = {
      email,
      user_name,
      mobile,
      app_key: "@34Y&D9(JE4dsi36f$%#(tigergames!xyz)8fe8ef8ef8",
      user_id,
      enquiry
    }
    try {
      const response = await UserEnquiry(UserProfileUpdateData)
      console.log("HandleUserProfileUpdate", response)
        alert("Your Enquiry Successfully Updated.")
        HandleGetUserProfile()
    } catch (error) {
      console.log("error", error)
    }
  }

  // <------ Render the User Profile ------------->
  useEffect(() => {
    HandleGetUserProfile()
  }, [])
  return (
    <div> <div className="wrapper">
      <div id="content">
        <Header />

        <div className="container">
          <div className="tb-10">
            <form>

              <div className="form-group">
                <label for="name">Name:</label>
                <input type="text" className="form-control" name="user_name" value={user_name} disabled
                  maxlength="50" minlength="4"
                  placeholder="Enter Name" id="account_holder_name" autocomplete="off" required />
              </div>

              <div className="form-group">
                <label for="username">Mobile Number:</label>
                <input type="text" className="form-control" name="mobile" disabled
                  value={mobile} maxlength="25" minlength="4"
                  placeholder="Mobile Number" id="account_number" autocomplete="off" required />
              </div>
              <div className="form-group">
                <label for="username">Email:</label>
                <input type="text" className="form-control" name="email" disabled
                  value={email} maxlength="25" minlength="4"
                  placeholder="Email" id="account_number" autocomplete="off" required />
              </div>

              <div className="form-group">
                <label for="mobile">Enquiry:</label>
                <textarea value={enquiry} name="enquiry"
                  onChange={(e) => setEnquiry(e.target.value)}
                  className="form-control" placeholder='Enter Enquiry' />
              </div>


              <button type='button' onClick={HandleUserEnquiry}
                className="btn btn-theme btn-login">Submit</button>
            </form>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div></div>
  )
}

export default Enquiry