import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../components/pages/home/Home'
import Login from '../auth/login/Login'
import Register from '../auth/register/Register'
import My_History from '../components/pages/my_history/My_History'
import Fund_History from '../components/pages/fund_history/Fund_History'
import Bidding_History from '../components/pages/bidding_history/Bidding_History'
import Starline_Bidding_History from '../components/pages/starline_bidding_history/Starline_Bidding_History'
import Transtion_History from '../components/pages/transtion_history/Transtion_History'
import My_Profile from '../components/pages/my_profile/My_Profile'
import Update_Bank_Details from '../components/pages/update_bank_details/Update_Bank_Details'
import Change_Password from '../auth/change_password/Change_Password'
import Add_Fund from '../components/pages/add_fund/Add_Fund'
import Double_Patti from '../components/pages/double_patti/Double_Patti'
import Download_App from '../components/pages/download_app/Download_App'
import Full_Sangam from '../components/pages/full_sangam/Full_Sangam'
import Game_Dashboard from '../components/pages/game_dashboard/Game_Dashboard'
import Game_Rates from '../components/pages/game_rates/Game_Rates'
import Half_Sangam from '../components/pages/half_sangam/Half_Sangam'
import Jodi from '../components/pages/jodi/Jodi'
import Notifications from '../components/pages/notifications/Notifications'
import Single from '../components/pages/single/Single'
import StarLine_Chart_History from '../components/pages/starline_chart_history/StarLine_Chart_History'
import StarLine_Play from '../components/pages/starline_play/StarLine_Play'
import StarLine_Single_Patti from '../components/pages/starline_single_patti/StarLine_Single_Patti'
import Support from '../components/pages/support/Support'
import Top_Winner_List_Starline from '../components/pages/top_winner_list_starline/Top_Winner_List_Starline'
import Top_Winner_List from '../components/pages/top_winner_list/Top_Winner_List'
import Triple_Patti from '../components/pages/triple_patti/Triple_Patti'
import Withdraw from '../components/pages/withdraw/Withdraw'
import All_Passbook from '../components/pages/passbook/all_passbook/All_Passbook'
import Deposit_Passbook from '../components/pages/passbook/deposit_passbook/Deposit_Passbook'
import Withdraw_Password from '../components/pages/passbook/withdraw_passbook/Withdraw_Password'
import Enquiry from '../components/pages/enquiry/Enquiry'
import My_Bid from '../components/pages/my_bid/My_Bid'
import Add_Upi_Details from '../components/pages/add_upi_details/Add_Upi_Details'
import StarLine_Game_DashBoard from '../components/pages/starline_game_dashboard/StarLine_Game_DashBoard'

const AllRoutes = () => {
  return (
    <div>
        <Routes>
            <Route path='/' element={<Home/>}/>
            <Route path='/login' element={<Login/>}/>
            <Route path='/register' element={<Register/>}/>
            <Route path='/my_history' element={<My_History/>}/>
            <Route path='/fund_history' element={<Fund_History/>}/>
            <Route path='/bidding_history' element={<Bidding_History/>}/>
            <Route path='/my_bid' element={<My_Bid/>}/>
            <Route path='/starline_bidding_history' element={<Starline_Bidding_History/>}/>
            <Route path='/transtion_history' element={<Transtion_History/>}/>
            <Route path='/my_profile' element={<My_Profile/>}/>
            <Route path='/update_bank_details' element={<Update_Bank_Details/>}/>
            <Route path='/add_upi_details' element={<Add_Upi_Details/>}/>
            <Route path='/add_fund' element={<Add_Fund/>}/>
            <Route path='/change_password' element={<Change_Password/>}/>
            <Route path='/double_patti' element={<Double_Patti/>}/>
            <Route path='/download_app' element={<Download_App/>}/>
            <Route path='/full_sangam' element={<Full_Sangam/>}/>
            <Route path='/game_dashboard' element={<Game_Dashboard/>}/>
            <Route path='/game_rates' element={<Game_Rates/>}/>
            <Route path='/half_sangam' element={<Half_Sangam/>}/>
            <Route path='/jodi' element={<Jodi/>}/>
            <Route path='/notifications' element={<Notifications/>}/>
            <Route path='/single' element={<Single/>}/>
            <Route path='/starLine_game_dashBoard' element={<StarLine_Game_DashBoard/>}/>
            <Route path='/starLine_chart_history' element={<StarLine_Chart_History/>}/>
            <Route path='/starline_play' element={<StarLine_Play/>}/>
            <Route path='/starLine_single_patti' element={<StarLine_Single_Patti/>}/>
            <Route path='/support' element={<Support/>}/>
            <Route path='/top_winner_list_starline' element={<Top_Winner_List_Starline/>}/>
            <Route path='/top_winner_list' element={<Top_Winner_List/>}/>
            <Route path='/triple_patti' element={<Triple_Patti/>}/>
            <Route path='/withdraw' element={<Withdraw/>}/>
            <Route path='/all_passbook' element={<All_Passbook/>}/>
            <Route path='/deposit_passbook' element={<Deposit_Passbook/>}/>
            <Route path='/withdraw_password' element={<Withdraw_Password/>}/>
            <Route path='/enquiry' element={<Enquiry/>}/>
        </Routes>
    </div>
  )
}

export default AllRoutes