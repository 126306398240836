import React, { useEffect, useState } from 'react'
import Header from '../../common/header/Header'
import Footer from '../../common/footer/Footer'
import { AddBankDetails, GetBankDetails } from '../../../api/AllApi';

const Update_Bank_Details = () => {

  const [BankDetailsList, setBankDetailsList] = useState([]);
  const [ac_holder_name, setAc_Holder_Name] = useState("");
  const [ac_number, setAc_Number] = useState("");
  const [ifsc_code, setIfsc_Code] = useState("");
  const [bank_name, setBank_Name] = useState("");
  const [branch_address, setBranch_Address] = useState("");
  const user_id = localStorage.getItem("user_id");

  

  const HandleAddBankDetails = async () => {
    const BankDetailsData = {
      "app_key": "@34Y&D9(JE4dsi36f\$%#(tigergames!xyz)8fe8ef8ef8",
      user_id,
      ac_holder_name:ac_holder_name,
      ac_number: ac_number,
      ifsc_code: ifsc_code,
      bank_name: bank_name,
      branch_address: branch_address
    }
    const response = await AddBankDetails(BankDetailsData);
    console.log("HandleGetBankDetails", response?.data?.payment_details)
    if(response?.data?.status === true){
      alert("Bank Details Update Successfully !!!")
    }
    GetBankDetails()
  }

  // <---------- Fetch the User Data ------------>
  useEffect(() => {
    const fetchData = async () => {
      const BankDetailsData = {
        app_key: "@34Y&D9(JE4dsi36f$%#(tigergames!xyz)8fe8ef8ef8",
        user_id
      }
      const response = await GetBankDetails(BankDetailsData);
      setAc_Holder_Name(response?.data?.payment_details[0]?.ac_holder_name)
      setAc_Number(response?.data?.payment_details[0]?.ac_number)
      setIfsc_Code(response?.data?.payment_details[0]?.ifsc_code)
      setBank_Name(response?.data?.payment_details[0]?.bank_name)
      setBranch_Address(response?.data?.payment_details[0]?.branch_address)
    };
    fetchData();
  }, []);
console.log("ac_holder_name",BankDetailsList[0]?.ac_holder_name)
 
  return (
    <div className="wrapper">

      <div id="content">
        <Header />
        <div className="container">
          <div className="card tb-10">
            <div className="text-center tb-10">
              <h3>Bank Details</h3>
              <span>Provide Valid Bank Details</span>
            </div>
            <form>

              <div className="form-group">
                <label for="name">A/c Holder Name:</label>
                <input type="text" className="form-control" name="ac_holder_name" value={ac_holder_name}
                 maxlength="50" minlength="4" onChange={(e)=>setAc_Holder_Name(e.target.value)}
                  placeholder="Enter Name" id="account_holder_name" autocomplete="off" required />
              </div>

              <div className="form-group">
                <label for="username">Bank Account Number:</label>
                <input type="text" className="form-control" name="ac_number"
                 value={ac_number} maxlength="25" minlength="4" onChange={(e)=>setAc_Number(e.target.value)}
                  placeholder="Enter Account Number" id="account_number" autocomplete="off" required />
              </div>

              <div className="form-group">
                <label for="mobile">IFSC Code:</label>
                <input type="text" className="form-control" name="ifsc_code" value={ifsc_code} 
                 maxlength="11" minlength="11" onChange={(e)=>setIfsc_Code(e.target.value)}
                  placeholder="Enter IFSC Code" id="ifsc" autocomplete="off" required />
              </div>

              <div className="form-group">
                <label for="mobile">Bank Name:</label>
                <input type="text" className="form-control" name="bank_name" value={bank_name} 
                onChange={(e)=>setBank_Name(e.target.value)} maxlength="25" minlength="3"
                  placeholder="Enter Bank Name" id="bank_name" autocomplete="off" required />
              </div>

              <div className="form-group">
                <label for="mobile">Branch Name:</label>
                <input type="text" className="form-control" name="branch_address" value={branch_address}
                  onChange={(e) => setBranch_Address(e.target.value)} maxlength="25" minlength="3"
                  placeholder="Enter Branch Name" id="bank_name" autocomplete="off" required />
              </div>

              <input type="hidden" name="api_acess_token" value="" />


              <button onClick={HandleAddBankDetails} type='button' className="btn btn-theme btn-login">Submit</button>
            </form>

            <div className="text-center tbmar-20">
              <p>Unable to update?</p>
              <a href="https://wa.me/+91 xxxxxx7894" className="btn btn-outline btn-login">Contact Admin</a>
            </div>

          </div>
        </div>
        <br />
        <br />
        <Footer />
      </div>
    </div>
  )
}

export default Update_Bank_Details