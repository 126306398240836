import React, { useEffect, useState } from 'react'
import Header from '../../common/header/Header'
import Footer from '../../common/footer/Footer'
import { GetTheWithdrawMwthods } from '../../../api/AllApi';

const Withdraw = () => {
  const [WithdrawList, setWithdrawList] = useState([]);
  const [request_amount, setRequest_Amount] = useState("")
  const [payment_method, setPayment_Method] = useState("")
  console.log("payment_method", payment_method)
  const user_id = localStorage.getItem("user_id");
  const HandleGetWithdrawList = async () => {
    const data = {
      app_key: "@34Y&D9(JE4dsi36f$%#(tigergames!xyz)8fe8ef8ef8",
      user_id
    }
    try {
      const response = await GetTheWithdrawMwthods(data)
      setWithdrawList(response?.data?.result)
      if (response?.data?.result?.type === 1) {
        setPayment_Method(response?.data?.result)
      }
    }
    catch (error) {
      console.log("error", error)
    }
  }

  const HandlePostTheWithdrawMwthods = async () => {
    const data = {
      app_key: "@34Y&D9(JE4dsi36f$%#(tigergames!xyz)8fe8ef8ef8",
      user_id: user_id,
      payment_method: payment_method,
      request_amount: request_amount
    }
    try {
      const response = await GetTheWithdrawMwthods(data)
      setWithdrawList(response?.data?.result)
      if (response?.data?.result?.type === 1) {
        setPayment_Method(response?.data?.result)
      }
      if(response?.data?.status === true){
        alert("Successfully Withdraw the Amount")
        setPayment_Method("")
        setRequest_Amount("")
      }
    }
    catch (error) {
      console.log("error", error)
    }
  }
  console.log("WithdrawList", WithdrawList)
  useEffect(() => {
    HandleGetWithdrawList()
  }, [])
  return (
    <div className="wrapper">
      <div id="content">
        <Header />
        <div className="container">
          <div className="card tb-10">
            <div className="text-center tb-10">
              <h3>Withdraw Fund</h3>
              <span>Send money to your bank account.</span>
            </div>
            <div className="tbmar-20 text-center">
              <p>Withdrawal requests may take up to 24 hours. <br /> Your funds will be credited to your bank account within 24 hours.
                Please rest assured that your money is always safe with us.</p>
              <p style={{ color: "red" }}>Note: Sunday withdrawals are off</p>
            </div>
            <form>
              <input className='form-control mt-3' type='text' placeholder='Withdraw Amount' name='request_amount' value={request_amount}
                onChange={(e) => setRequest_Amount(e.target.value)} />
              {
                WithdrawList?.map((WithdrawListResult) => {
                  if (WithdrawListResult?.type === 1) {
                    return (
                      <div key={WithdrawListResult?.id}>
                        <select className="dateGameIDbox form-control mt-3" name="payment_method" value={payment_method} onChange={(e) => setPayment_Method(e.target.value)} required>
                          <option value="" disabled>Select the Payment Method</option>
                          <option value={WithdrawListResult?.name}> {WithdrawListResult?.name}</option>
                        </select>
                      </div>
                    );
                  }
                  return null;
                })
              }
              <p style={{ color: "red" }} className="text-center"> NOTE: If Not Update Your bank details then you can't withdraw 
                Amount from the account </p>
                <div className='text-center'>
                  
              <button className='btn btn-primary' style={{backgroundColor:"#67279e"}} type='button'  disabled={!request_amount || !payment_method}
              onClick={HandlePostTheWithdrawMwthods}>Submit</button>

                </div>
            </form>




            <div className="text-center tbmar-20">
              <p>Unable to Withdraw Fund?</p>
              <a href="https://wa.me/+91 xxxxxx7894" className="btn btn-outline btn-login">Contact Admin</a>
            </div>
          </div>
        </div>
        <br /><br /><br />
      </div>
      <Footer />
    </div>
  )
}

export default Withdraw