import React, { useState, useEffect } from 'react'
import Header from '../../common/header/Header'
import Footer from '../../common/footer/Footer'
import { FaRupeeSign } from "react-icons/fa";
import { GetCurrentDate, SubmitBid } from '../../../api/AllApi';
const Double_Patti = () => {
    const [Amount, setAmount] = useState("");
    const [TotalAmount, setTotalAmount] = useState({});
    console.log("Amount", Amount)
    const [isAmountActive, setIsAmountActive] = useState(null);
    const [Digits, setDigits] = useState([]);
    const [grandTotal, setGrandTotal] = useState(0);
    const [bid_date, setBidDate] = useState("");
    const [game_type, setGame_Type] = useState("");
    const user_id = localStorage.getItem("user_id");
    const game_id = localStorage.getItem("game_id");
    const game_name = localStorage.getItem("game_name");
    const [isInputChanged, setIsInputChanged] = useState(false);
    const [formData, setFormData] = useState([]);
    console.log("Digits", Digits)

    console.log("HandleIsAmountActive", isAmountActive)
    const handleAmountSelection = (amountValue, index) => {
        setAmount(amountValue);
        setIsAmountActive(index);
    };


    const handleInputChange = (inputId, digit) => {
        setTotalAmount((prevState) => ({
            ...prevState,
            [inputId]: (prevState[inputId] || 0) + Amount,
        }));

        setDigits((prevState) => ({
            ...prevState,
            [inputId]: digit,
        }));

        setFormData((prevData) => ({
            ...prevData,
            [inputId]: {
                ...prevData[inputId],
                points: (prevData[inputId]?.points || 0) + Amount,
                digits: digit,
                type: 'open',
            },
        }));
        setIsInputChanged(true);
    };


    const formattedData = Object.keys(formData).map((key) => formData[key]);

    const isButtonDisabled = !game_type || !isInputChanged;
    const handleSubmit = async (e) => {
        e.preventDefault();
        const dataToSend = {
            bid_date: bid_date,
            gameid: game_id,
            user_id: user_id,
            Gamename: game_name,
            totalbit: grandTotal,
            pana: "Double Patti",
            session: game_type,
            result: formattedData,
        };
        const data = {
            new_result: dataToSend,
            app_key: "@34Y&D9(JE4dsi36f$%#(tigergames!xyz)8fe8ef8ef8"
        }

        console.log('Data to send:', data);
        try {
            const response = await SubmitBid(data)
            console.log("handleSubmit", response?.data?.status == false)
            if (response?.data?.status === false) {
                alert("Sorry You Don't Have Sufficient Amount For This Bid")
            }
        } catch (error) {
            console.log("error", error)
        }
    };


    // <---------- Get the Current Date ------------>
    const HandleGetCurrentTime = async () => {
        const dataToSend = {
            app_key: "@34Y&D9(JE4dsi36f$%#(tigergames!xyz)8fe8ef8ef8",
            game_id,
            user_id
        };
        console.log('Data to send:', dataToSend);
        try {
            const response = await GetCurrentDate(dataToSend)
            setBidDate(response?.data?.date)
        } catch (error) {
            console.log("error", error)
        }
    };
    console.log("bid_date", bid_date)
    const calculateTotalAmount = () => {
        const total = Object.values(TotalAmount).reduce((acc, value) => acc + value, 0);
        setGrandTotal(total);
    };

    useEffect(() => {
        calculateTotalAmount();
    }, [TotalAmount]);

    useEffect(() => {
        HandleGetCurrentTime();
    }, []);

    const resetAmounts = () => {
        setTotalAmount({});
    };
    return (
        <div>
            <div id="content">
                <Header />
                <div className="container">
                    <div className="card-full-page tb-10">

                        <form action="" method="POST" className="myform">

                            <div className="row bidoptions-list tb-10">
                                <div className="col-6">
                                    <a className="dateGameIDbox">
                                        <p>{bid_date}</p>
                                    </a>
                                </div>

                                <div className="col-6">
                                    <select className="dateGameIDbox" name="game_type" value={game_type} onChange={(e) => setGame_Type(e.target.value)} required>
                                        <option value="" disabled>Select the Game Type</option>
                                        <option value="CLOSE">{game_name} CLOSE</option>
                                        <option value="OPEN">{game_name} OPEN</option>
                                    </select>
                                </div>

                            </div>



                            <div className="tb-10"><hr className="devider" /></div>

                            <h3 className="subheading">Select Amount</h3>
                            <div className="row bidoptions-list tb-10">
                                <div className="col-3">
                                    <a className={`bidamtbox ${isAmountActive == "0" ? "active_amounts" : ""}`}
                                        onClick={() => {
                                            handleAmountSelection(5, 0)
                                        }} id="amount_5" data="5">
                                        <p><FaRupeeSign size={12} /> 5</p>
                                    </a>
                                </div>

                                <div className="col-3">
                                    <a className={`bidamtbox ${isAmountActive == "1" ? "active_amounts" : ""}`}
                                        onClick={() => {
                                            handleAmountSelection(10, 1)
                                        }} id="amount_10" data="10">
                                        <p><FaRupeeSign size={12} /> 10</p>
                                    </a>
                                </div>

                                <div className="col-3">
                                    <a className={`bidamtbox ${isAmountActive == "2" ? "active_amounts" : ""}`}
                                        onClick={() => {
                                            handleAmountSelection(50, 2)
                                        }} id="amount_50" data="50">
                                        <p><FaRupeeSign size={12} /> 50</p>
                                    </a>
                                </div>
                                <div className="col-3">
                                    <a className={`bidamtbox ${isAmountActive == "3" ? "active_amounts" : ""}`}
                                        onClick={() => {
                                            handleAmountSelection(100, 3)
                                        }} id="amount_100" data="100">
                                        <p><FaRupeeSign size={12} /> 100</p>
                                    </a>
                                </div>
                            </div>




                            <div className="row bidoptions-list tb-10">
                                <div className="col-3">
                                    <a className={`bidamtbox ${isAmountActive == "4" ? "active_amounts" : ""}`}
                                        onClick={() => {
                                            handleAmountSelection(200, 4)
                                        }} id="amount_200" data="200">
                                        <p><FaRupeeSign size={12} /> 200</p>
                                    </a>
                                </div>

                                <div className="col-3">
                                    <a className={`bidamtbox ${isAmountActive == "5" ? "active_amounts" : ""}`}
                                        onClick={() => {
                                            handleAmountSelection(500, 5)
                                        }} id="amount_500" data="500">
                                        <p><FaRupeeSign size={12} /> 500</p>
                                    </a>
                                </div>

                                <div className="col-3">
                                    <a className={`bidamtbox ${isAmountActive == "6" ? "active_amounts" : ""}`}
                                        onClick={() => {
                                            handleAmountSelection(1000, 6)
                                        }} id="amount_1000" data="1000">
                                        <p><FaRupeeSign size={12} /> 1000</p>
                                    </a>
                                </div>
                                <div className="col-3">
                                    <a className={`bidamtbox ${isAmountActive == "7" ? "active_amounts" : ""}`}
                                        onClick={() => {
                                            handleAmountSelection(1000, 7)
                                        }} id="amount_5000" data="5000">
                                        <p><FaRupeeSign size={12} /> 5000</p>
                                    </a>
                                </div>
                            </div>

                            <div className="tb-10"><hr className="devider" /></div>
                            <h3 className="subheading">Select Digits</h3>
                            <div className="tb-10"><hr className="devider" /></div>
                            <h3 className="subheading">Panna of ank 0</h3>
                            <div className="row bidoptions-list tb-10">


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>118</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti118'] || ''}
                                            className="pointinputbox"
                                            id="double_patti118"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti118', 118);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>226</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti226'] || ''}
                                            className="pointinputbox"
                                            id="double_patti226"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti226', 226);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>244</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti244'] || ''}
                                            className="pointinputbox"
                                            id="double_patti244"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti244', 244);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>299</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti299'] || ''}
                                            className="pointinputbox"
                                            id="double_patti299"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti299', 299);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>334</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti334'] || ''}
                                            className="pointinputbox"
                                            id="double_patti334"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti334', 334);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>488</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti488'] || ''}
                                            className="pointinputbox"
                                            id="double_patti488"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti488', 488);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>550</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti550'] || ''}
                                            className="pointinputbox"
                                            id="double_patti550"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti550', 550);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>668</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti668'] || ''}
                                            className="pointinputbox"
                                            id="double_patti668"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti668', 668);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>677</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti677'] || ''}
                                            className="pointinputbox"
                                            id="double_patti677"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti677', 677);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                            </div>
                            <div className="tb-10"><hr className="devider" /></div>
                            <h3 className="subheading">Panna of ank 1</h3>
                            <div className="row bidoptions-list tb-10">


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>100</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti100'] || ''}
                                            className="pointinputbox"
                                            id="double_patti100"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti100', 100);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>119</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti119'] || ''}
                                            className="pointinputbox"
                                            id="double_patti119"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti119', 119);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>155</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti155'] || ''}
                                            className="pointinputbox"
                                            id="double_patti155"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti155', 155);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>227</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti227'] || ''}
                                            className="pointinputbox"
                                            id="double_patti227"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti227', 227);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>335</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti335'] || ''}
                                            className="pointinputbox"
                                            id="double_patti335"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti335', 335);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>344</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti344'] || ''}
                                            className="pointinputbox"
                                            id="double_patti344"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti344', 344);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>399</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti399'] || ''}
                                            className="pointinputbox"
                                            id="double_patti399"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti399', 399);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>588</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti588'] || ''}
                                            className="pointinputbox"
                                            id="double_patti588"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti588', 588);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>669</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti669'] || ''}
                                            className="pointinputbox"
                                            id="double_patti669"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti669', 669);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                            </div>

                            <div className="tb-10"><hr className="devider" /></div>
                            <h3 className="subheading">Panna of ank 2</h3>
                            <div className="row bidoptions-list tb-10">


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>110</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti110'] || ''}
                                            className="pointinputbox"
                                            id="double_patti110"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti110', 110);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>200</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti200'] || ''}
                                            className="pointinputbox"
                                            id="double_patti200"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti200', 200);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>228</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti228'] || ''}
                                            className="pointinputbox"
                                            id="double_patti228"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti228', 228);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>255</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti255'] || ''}
                                            className="pointinputbox"
                                            id="double_patti255"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti255', 255);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>336</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti336'] || ''}
                                            className="pointinputbox"
                                            id="double_patti336"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti336', 336);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>499</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti499'] || ''}
                                            className="pointinputbox"
                                            id="double_patti499"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti499', 499);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>660</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti660'] || ''}
                                            className="pointinputbox"
                                            id="double_patti660"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti660', 660);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>688</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti688'] || ''}
                                            className="pointinputbox"
                                            id="double_patti688"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti688', 688);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>778</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti778'] || ''}
                                            className="pointinputbox"
                                            id="double_patti778"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti778', 778);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                            </div>

                            <div className="tb-10"><hr className="devider" /></div>
                            <h3 className="subheading">Panna of ank 3</h3>
                            <div className="row bidoptions-list tb-10">


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>166</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti166'] || ''}
                                            className="pointinputbox"
                                            id="double_patti166"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti166', 166);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>229</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti229'] || ''}
                                            className="pointinputbox"
                                            id="double_patti229"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti229', 229);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>300</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti300'] || ''}
                                            className="pointinputbox"
                                            id="double_patti300"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti300', 300);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>337</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti337'] || ''}
                                            className="pointinputbox"
                                            id="double_patti337"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti337', 337);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>355</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti355'] || ''}
                                            className="pointinputbox"
                                            id="double_patti355"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti355', 355);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>445</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti445'] || ''}
                                            className="pointinputbox"
                                            id="double_patti445"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti445', 445);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>599</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti599'] || ''}
                                            className="pointinputbox"
                                            id="double_patti599"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti599', 599);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>779</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti779'] || ''}
                                            className="pointinputbox"
                                            id="double_patti779"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti779', 779);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>788</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti788'] || ''}
                                            className="pointinputbox"
                                            id="double_patti788"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti788', 788);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                            </div>
                            <div className="tb-10"><hr className="devider" /></div>
                            <h3 className="subheading">Panna of ank 4</h3>
                            <div className="row bidoptions-list tb-10">


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>112</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti112'] || ''}
                                            className="pointinputbox"
                                            id="double_patti112"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti112', 112);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>220</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti220'] || ''}
                                            className="pointinputbox"
                                            id="double_patti220"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti220', 220);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>266</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti266'] || ''}
                                            className="pointinputbox"
                                            id="double_patti266"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti266', 266);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>338</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti338'] || ''}
                                            className="pointinputbox"
                                            id="double_patti338"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti338', 338);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>400</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti400'] || ''}
                                            className="pointinputbox"
                                            id="double_patti400"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti400', 400);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>446</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti446'] || ''}
                                            className="pointinputbox"
                                            id="double_patti446"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti446', 446);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>455</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti455'] || ''}
                                            className="pointinputbox"
                                            id="double_patti455"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti455', 455);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>699</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti699'] || ''}
                                            className="pointinputbox"
                                            id="double_patti699"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti699', 699);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>770</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti770'] || ''}
                                            className="pointinputbox"
                                            id="double_patti770"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti770', 770);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                            </div>
                            <div className="tb-10"><hr className="devider" /></div>
                            <h3 className="subheading">Panna of ank 5</h3>
                            <div className="row bidoptions-list tb-10">


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>113</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti113'] || ''}
                                            className="pointinputbox"
                                            id="double_patti113"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti113', 113);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>122</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti122'] || ''}
                                            className="pointinputbox"
                                            id="double_patti122"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti122', 122);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>177</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti177'] || ''}
                                            className="pointinputbox"
                                            id="double_patti177"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti177', 177);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>339</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti339'] || ''}
                                            className="pointinputbox"
                                            id="double_patti339"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti339', 339);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>366</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti366'] || ''}
                                            className="pointinputbox"
                                            id="double_patti366"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti366', 366);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>447</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti447'] || ''}
                                            className="pointinputbox"
                                            id="double_patti447"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti447', 447);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>500</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti500'] || ''}
                                            className="pointinputbox"
                                            id="double_patti500"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti500', 500);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>799</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti799'] || ''}
                                            className="pointinputbox"
                                            id="double_patti799"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti799', 799);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>889</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti889'] || ''}
                                            className="pointinputbox"
                                            id="double_patti889"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti889', 889);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                            </div>
                            <div className="tb-10"><hr className="devider" /></div>
                            <h3 className="subheading">Panna of ank 6</h3>
                            <div className="row bidoptions-list tb-10">


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>114</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti114'] || ''}
                                            className="pointinputbox"
                                            id="double_patti114"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti114', 114);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>277</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti277'] || ''}
                                            className="pointinputbox"
                                            id="double_patti277"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti277', 277);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>330</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti330'] || ''}
                                            className="pointinputbox"
                                            id="double_patti330"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti330', 330);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>448</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti448'] || ''}
                                            className="pointinputbox"
                                            id="double_patti448"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti448', 448);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>466</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti466'] || ''}
                                            className="pointinputbox"
                                            id="double_patti466"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti466', 466);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>556</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti556'] || ''}
                                            className="pointinputbox"
                                            id="double_patti556"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti556', 556);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>600</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti600'] || ''}
                                            className="pointinputbox"
                                            id="double_patti600"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti600', 600);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>880</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti880'] || ''}
                                            className="pointinputbox"
                                            id="double_patti880"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti880', 880);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>899</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti899'] || ''}
                                            className="pointinputbox"
                                            id="double_patti899"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti899', 899);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                            </div>

                            <div className="tb-10"><hr className="devider" /></div>
                            <h3 className="subheading">Panna of ank 7</h3>
                            <div className="row bidoptions-list tb-10">


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>115</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti115'] || ''}
                                            className="pointinputbox"
                                            id="double_patti115"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti115', 115);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>133</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti133'] || ''}
                                            className="pointinputbox"
                                            id="double_patti133"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti133', 133);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>188</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti188'] || ''}
                                            className="pointinputbox"
                                            id="double_patti188"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti188', 188);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>223</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti223'] || ''}
                                            className="pointinputbox"
                                            id="double_patti223"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti223', 223);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>377</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti377'] || ''}
                                            className="pointinputbox"
                                            id="double_patti377"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti377', 377);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>449</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti449'] || ''}
                                            className="pointinputbox"
                                            id="double_patti449"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti449', 449);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>557</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti557'] || ''}
                                            className="pointinputbox"
                                            id="double_patti557"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti557', 557);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>566</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti566'] || ''}
                                            className="pointinputbox"
                                            id="double_patti566"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti566', 566);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>700</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti700'] || ''}
                                            className="pointinputbox"
                                            id="double_patti700"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti700', 700);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                            </div>

                            <div className="tb-10"><hr className="devider" /></div>
                            <h3 className="subheading">Panna of ank 8</h3>
                            <div className="row bidoptions-list tb-10">


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>116</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti116'] || ''}
                                            className="pointinputbox"
                                            id="double_patti116"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti116', 116);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>224</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti224'] || ''}
                                            className="pointinputbox"
                                            id="double_patti224"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti224', 224);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>233</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti233'] || ''}
                                            className="pointinputbox"
                                            id="double_patti233"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti233', 233);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>288</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti288'] || ''}
                                            className="pointinputbox"
                                            id="double_patti288"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti288', 288);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>440</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti440'] || ''}
                                            className="pointinputbox"
                                            id="double_patti440"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti440', 440);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>477</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti477'] || ''}
                                            className="pointinputbox"
                                            id="double_patti477"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti477', 477);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>558</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti558'] || ''}
                                            className="pointinputbox"
                                            id="double_patti558"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti558', 558);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>800</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti800'] || ''}
                                            className="pointinputbox"
                                            id="double_patti800"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti800', 800);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>990</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti990'] || ''}
                                            className="pointinputbox"
                                            id="double_patti118"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti990', 990);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                            </div>

                            <div className="tb-10"><hr className="devider" /></div>
                            <h3 className="subheading">Panna of ank 9</h3>
                            <div className="row bidoptions-list tb-10">


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>117</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti117'] || ''}
                                            className="pointinputbox"
                                            id="double_patti117"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti117', 117);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>144</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti144'] || ''}
                                            className="pointinputbox"
                                            id="double_patti144"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti144', 144);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>199</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti199'] || ''}
                                            className="pointinputbox"
                                            id="double_patti199"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti199', 199);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>225</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti225'] || ''}
                                            className="pointinputbox"
                                            id="double_patti225"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti225', 225);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>388</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti388'] || ''}
                                            className="pointinputbox"
                                            id="double_patti388"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti388', 388);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>559</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti559'] || ''}
                                            className="pointinputbox"
                                            id="double_patti559"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti559', 559);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>577</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti577'] || ''}
                                            className="pointinputbox"
                                            id="double_patti577"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti577', 577);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>667</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti667'] || ''}
                                            className="pointinputbox"
                                            id="double_patti667"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti667', 667);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                                <div className="col-3">
                                    <div className="bidinputdiv">
                                        <lable>900</lable>
                                        <input
                                            type="text"
                                            value={TotalAmount['double_patti900'] || ''}
                                            className="pointinputbox"
                                            id="double_patti900"
                                            name="Digits"
                                            readOnly
                                            onClick={() => {
                                                if (!Amount) {
                                                    alert('Please select an amount before proceeding.');
                                                } else {
                                                    handleInputChange('double_patti900', 900);
                                                }
                                            }}
                                        />
                                    </div>
                                </div>


                            </div>



                            <input type="hidden" id="total_point" name="total_point" value="" />
                            <input type="hidden" id="selected_amount" value="" />

                            <input type="hidden" name="gid" value="94" />
                            <input type="hidden" name="pgid" value="53" />
                            <input type="hidden" name="dgame" value="close" />




                            <div className="tbmar-20 text-center">
                                <p>Total Points : <a id="total_point2">{grandTotal}</a></p>
                            </div>

                            <div className="row bidoptions-list tb-10">
                                <div className="col-6">
                                    <button className="btn btn-light btn-streched" onClick={resetAmounts} type="reset">Reset</button>
                                </div>

                                <div className="col-6">
                                    <button className="btn btn-theme btn-streched" disabled={isButtonDisabled} type="button" onClick={handleSubmit}
                                        name="single_submit">Submit</button>
                                </div>

                            </div>



                        </form>

                        <br /><br /><br /><br /><br /><br />
                    </div>
                </div>
                <br />
                <br />
                <br />
                <Footer />
            </div></div>
    )
}

export default Double_Patti