import React from 'react'
import { FaHome } from "react-icons/fa";
import { MdWorkHistory } from "react-icons/md";
import { FaUserAlt } from "react-icons/fa";
import { SiBookstack } from "react-icons/si";
import { IoMdRefreshCircle } from "react-icons/io";
import { Link } from 'react-router-dom';
const Footer = () => {
    
  const is_user = localStorage.getItem("is_user");

  const checkUserLoggedIn = (event) => {
    if (!is_user) {
      event.preventDefault(); 
      alert('User not logged in.');
    }
  };
    return (
        <div>
            {/* <!-- footer --> */}
            <div id="footer-bar" class="footer-bar-1">
                <Link  to="/" class="active-nav"><FaHome  size={20}/><span>Home</span></Link>
                <Link onClick={(e) => checkUserLoggedIn(e)} to="/fund_history"><MdWorkHistory size={20}/><span>Funds</span></Link>
                <Link onClick={(e) => checkUserLoggedIn(e)} to="/my_profile"><FaUserAlt size={20}/><span>Profile</span></Link>
                <Link onClick={(e) => checkUserLoggedIn(e)} to="/all_passbook"><SiBookstack size={22}/><span>Passbook</span></Link>
                <Link onClick={(e) => checkUserLoggedIn(e)} to="/my_bid"><IoMdRefreshCircle size={22}/><span>My Bids</span></Link>
            </div>
        </div>
    )
}

export default Footer