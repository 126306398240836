import React, { useEffect, useState } from 'react'
import "../../../assets/css/style.css"
import Header from '../../common/header/Header';
import { CiMoneyBill } from "react-icons/ci";
import { PiHandWithdrawFill } from "react-icons/pi";
import { BiSolidMessageRoundedDots } from "react-icons/bi";
import { TbExclamationMark } from "react-icons/tb";
import { RiStarSFill } from "react-icons/ri";
import { BsFillPlayCircleFill } from "react-icons/bs";
import { FiClock } from "react-icons/fi";
import { MdFileDownload } from "react-icons/md";
import Modal from 'react-bootstrap/Modal';
import appbanner from "../../../assets/images/app-banner.png"
import Footer from '../../common/footer/Footer';
import { Link, useNavigate } from 'react-router-dom';
import { GetDashBoarddata } from '../../../api/AllApi';

const Home = () => {
  const navigate = useNavigate()
  const [show, setShow] = useState(true);
  const [ShowGameDetails, setShowGameDetails] = useState(false);
  const [DashBoarddataList, setDashBoarddataList] = useState([]);
  const [GameDetails, setGameDetails] = useState({});
  const is_user = localStorage.getItem("is_user");
  const GetDashBoarddataList = async () => {
    const DashBoarddata = {
      user_id: "",
      app_key: "@34Y&D9(JE4dsi36f$%#(tigergames!xyz)8fe8ef8ef8"
    }
    try {
      const response = await GetDashBoarddata(DashBoarddata)
      setDashBoarddataList(response?.data?.result)
    } catch (error) {
      console.log("error", error)
    }
  }

  const GetGameDeatils = (id) => {
    setGameDetails(id)
  }

  const GetGameDashBoardPage = (game) => {
    navigate("/game_dashboard")
    localStorage.setItem("game_id",game?.game_id)
    localStorage.setItem("game_name",game?.game_name)
  }

  console.log("GameDetails", GameDetails)
  useEffect(() => {
    GetDashBoarddataList();
  }, [])

  const checkUserLoggedIn = (event) => {
    if (!is_user) {
      event.preventDefault(); 
      alert('User not logged in.');
    }
  };
  return (
    <div>
      <div className="wrapper">

        <div id="content">
          <Header />
          <div className="container text-center">
            <div className="tb-10">
              <div className="row">
                <div className="col-3" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                  <Link to="/add_fund" onClick={(e) => checkUserLoggedIn(e)} className="home-sl2-box"> <CiMoneyBill size={20} /> <br /> <span>Add Fund</span></Link>
                </div>
                <div className="col-3" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                  <Link to="/withdraw" onClick={(e) => checkUserLoggedIn(e)} className="home-sl2-box"> <PiHandWithdrawFill size={20} /> <br />
                    <span>Withdraw</span></Link>
                </div>

                <div className="col-3" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                  <Link to="/support" onClick={(e) => checkUserLoggedIn(e)} className="home-sl2-box"><BiSolidMessageRoundedDots size={20} /><br /> <span>Support</span></Link>
                </div>

                <div className="col-3" style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                  <a href="#" className="home-sl2-box"><TbExclamationMark size={20} /> <br /> <span>How ?</span></a>
                </div>

              </div>
            </div>
          </div>
          <div id="scroll-container" className="noticebr">
            <div id="scroll-text" style={{ whiteSpace: "nowrap" }}>Withdrawal Timing 08 AM To 11 Pm Din Mein Kitni Bar Bhi
              Withdrawal Le Sakte Hain</div>
          </div>

          <div className="container text-center">
            <div className="tb-10">

              <div className="row game-list-inner" style={{ background: "#1f1f1f", color: "#ffe301" }}>
                <div className="col-3">
                  <RiStarSFill size={30} />
                </div>
                <div className="col-6">
                  <div className="game-list-box">
                    <span className="gameName"> MUMBAI STARLINE </span>

                    <span className="gameResult" style={{ color: "white", fontSize: "12px", paddingTop: "10px" }}>Play and Win
                      Hourly</span>
                  </div>
                </div>
                <div className="col-3">
                  <Link to="/starline_play" className="game-play"> <BsFillPlayCircleFill size={25} /><br />Play
                    Starline</Link>
                </div>

              </div>
              <h3 className="gdash3" style={{ fontSize: "16px" }}>WORLD KA SABSE TRUSTED MATKA PLAY</h3>

              {
                DashBoarddataList?.map((DashBoarddataListResult) => {
                  return (
                    <>
                      <div className="row game-list-inner" style={{cursor:"pointer"}} onClick={(e) => {
                        if(!is_user){
                     alert("User not logged in !!!")
                     return
                        }
                       else if (DashBoarddataListResult?.msg_status == "2") {
                          setShowGameDetails(true)
                          GetGameDeatils(DashBoarddataListResult)
                        }
                        else if(DashBoarddataListResult?.msg_status == "1"){
                          GetGameDashBoardPage(DashBoarddataListResult)
                        }
                      }}>
                        <div className="col-3">
                          <a to="#" className="game-time" data-toggle="modal" data-target="#gameTimeModal51"><FiClock size={22} /> <br />Game Time</a>
                        </div>
                        <div className="col-6">
                          <div className="game-list-box">
                            <span className="gameName">{DashBoarddataListResult?.game_name} </span>
                            {
                              DashBoarddataListResult?.msg_status == "2" ? (
                                <>

                                  <p className="gameoff text-danger">{DashBoarddataListResult?.msg}</p>
                                </>

                              ) : (
                                <>
                                  <p className="gameoff text-success">{DashBoarddataListResult?.msg}</p>
                                </>
                              )
                            }
                            {
                              (DashBoarddataListResult?.open_result === null || DashBoarddataListResult?.open_result === "") &&
                                (DashBoarddataListResult?.close_result === null || DashBoarddataListResult?.close_result === "") ? (
                                <span className="gameResult">***_**_***</span>
                              ) : DashBoarddataListResult?.open_result === null || DashBoarddataListResult?.open_result === "" ? (
                                <span className="gameResult">***_*</span>
                              ) : DashBoarddataListResult?.close_result === null || DashBoarddataListResult?.close_result === "" ? (
                                <span className="gameResult">*_***</span>
                              ) : (
                                <span className="gameResult">{DashBoarddataListResult?.open_result} {DashBoarddataListResult?.close_result}</span>
                              )
                            }


                          </div>
                        </div>
                        <div className="col-3">
                          <a to="#" className="game-play gray"> <BsFillPlayCircleFill size={20} /><br />Play Game</a>
                        </div>

                      </div>
                    </>
                  )
                })
              }

              <div className="modal" id="gameTimeModal58">
                <div className="modal-dialog">
                  <div className="modal-content">

                    {/* <!-- Modal Header --> */}
                    <div className="modal-header">
                      <p className="modal-title">MAIN BAZAR</p>
                      <button type="button" className="close" data-dismiss="modal">&times;</button>
                    </div>

                    {/* <!-- Modal body --> */}
                    <div className="modal-body">
                      <div className="HomegameTimetable">
                        <div><i className="fa fa-clock-o"></i> <span>Open Bid Ends</span> <span className="timeR">09:50 PM</span>
                        </div>
                        <div><i className="fa fa-clock-o"></i> <span>Close Bid Ends</span> <span className="timeR">11:59 PM</span>
                        </div>
                        <div><i className="fa fa-clock-o"></i> <span>Open Result</span> <span className="timeR">09:58 PM</span>
                        </div>
                        <div><i className="fa fa-clock-o"></i> <span>Close Result</span> <span className="timeR">12:08 AM</span>
                        </div>
                      </div>
                    </div>
                    {/* <!-- Modal footer --> */}
                    <div className="modal-footer">
                      <button type="button" className="btn btn-theme" data-dismiss="modal">Close</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br /><br /><br />

        </div>
      </div>
      {/* <--------- Get Android Application's ------------> */}
      <Modal className="modal" id="noticeboard" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>GET ANDROID APPLICATION</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="downloadpopup">
            <img src={appbanner} width="120" className="img-fluid" alt="" />
            <div>
              <h4>DOWNLOAD OUR APP</h4>

              <p>Enjoy advanced features and options by downloading our Android app. access all the exciting
                features anytime, anywhere!</p>
              <a href="https://kalyan777.co/admin_backend/build.apk" style={{cursor:"pointer"}}>
                <MdFileDownload size={20}  /> Download Now</a>
            </div>
          </div></Modal.Body>
      </Modal>

      {/* <------------ Show the Game Detail's -------------> */}
      <Modal className="modal" id="noticeboard" show={ShowGameDetails} onHide={() => setShowGameDetails(false)}>
        <Modal.Header closeButton onClick={() => setShowGameDetails(false)}>
        </Modal.Header>
        <Modal.Body>
          <div className="downloadpopup">
            <div>
              <h6 className='text-danger'>{GameDetails?.msg}</h6>
              <h4>{GameDetails?.game_name}</h4>
              <ul style={{ listStyle: "none" }}>
                <li className='mt-2'>Open Result Time: {GameDetails?.open_time}</li>
                <li className='mt-2'>Open Bid Last Time: 09:05 AM</li>
                <li className='mt-2'>Close Result Time: {GameDetails?.close_time}</li>
                <li className='mt-2'>Close Bid Last Time: 09:05 AM</li>
              </ul>
            </div>
          </div></Modal.Body>
      </Modal>
      <Footer />
    </div>
  )
}

export default Home