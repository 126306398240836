import React from 'react'
import Header from '../../common/header/Header'
import Footer from '../../common/footer/Footer'
const Transtion_History = () => {
  return (
    <div>
         <div className="wrapper">
   
   <div id="content">
       <Header/>
     <div className="container">
       <div className="text-center tb-10">
         <h3 className="gdash3">Transaction History</h3>
         <span style={{fontSize:"12px"}}>Deposit and Withdraw History</span>
       </div>
       <div className="tb-10">
         <div className="table-responsive">
           <table className="table table-striped starline-chart-table">
             <thead>
               <tr>
                 <th className="text-center">Transaction ID</th>
                 <th className="text-center">Amount</th>
                 <th className="text-center">User Name</th>
                 <th className="text-center">User Number</th>
                 <th className="text-center">Receiver UPI ID</th>
                 <th className="text-center">Transaction Status</th>
                 <th className="text-center">Status</th>
               </tr>
             </thead>
             <tbody>
               <tr>
                 <td>#784d245</td>
                 <td>Rs.50</td>
                 <td>Shyam</td>
                 <td>7897897890</td>
                 <td>UPI@rr7842</td>
                 <td><span>success</span></td>
                 <td>active</td>
               </tr>
               <tr>
                 <td>#784d245</td>
                 <td>Rs.50</td>
                 <td>Shyam</td>
                 <td>7897897890</td>
                 <td>UPI@rr7842</td>
                 <td><span >success</span></td>
                 <td>active</td>
               </tr>
               <tr>
                 <td>#784d245</td>
                 <td>Rs.50</td>
                 <td>Shyam</td>
                 <td>7897897890</td>
                 <td>UPI@rr7842</td>
                 <td><span>success</span></td>
                 <td>active</td>
               </tr>
               <tr>
                 <td>#784d245</td>
                 <td>Rs.50</td>
                 <td>Shyam</td>
                 <td>7897897890</td>
                 <td>UPI@rr7842</td>
                 <td><span>success</span></td>
                 <td>active</td>
               </tr>
               <tr>
                 <td>#784d245</td>
                 <td>Rs.50</td>
                 <td>Shyam</td>
                 <td>7897897890</td>
                 <td>UPI@rr7842</td>
                 <td><span>success</span></td>
                 <td>active</td>
               </tr>

             </tbody>
           </table>
         </div>
       </div>

       <br/><br/><br/>
     </div>
<br/>
<br/>
<Footer/>
   </div>
 </div>
    </div>
  )
}

export default Transtion_History