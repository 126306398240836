import React, { useState } from 'react'
import Header from '../../common/header/Header'
import Footer from '../../common/footer/Footer'
import { GetBiddingHistory } from '../../../api/AllApi';

const Bidding_History = () => {
  const [bid_from, setBid_From] = useState("");
  const [bid_to, setBid_To] = useState("");
  const user_id = localStorage.getItem("user_id");
  const [BiddingHistoryList, setBiddingHistoryList] = useState([])
  const [noData, setNoData] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const SubmitBiddingHistory = async () => {
    setIsLoading(true)
    const BiddingHistoryData = {
      app_key: "@34Y&D9(JE4dsi36f$%#(tigergames!xyz)8fe8ef8ef8",
      bid_from,
      bid_to,
      user_id
    }
    console.log("BiddingHistoryData", BiddingHistoryData)
    try {
      const response = await GetBiddingHistory(BiddingHistoryData)
      setBiddingHistoryList(response?.data?.bid_data)
      setIsLoading(false)
      console.log("SubmitBiddingHistory", response?.data?.bid_data)
      if (!response?.data?.bid_data || response?.data?.bid_data?.length === 0) {
        setNoData(true);
      } else {
        setNoData(false);
      }
    } catch (error) {
      console.log("error", error)
      setNoData(true);
      setIsLoading(true);
    }
  }
  return (
    <div className="wrapper">
      <div id="content">
        <Header />
        <div className="container">

          <form action="" method="POST" autocomplete="off">
            <div className='row'>

              <div className="col-6">
                <div className="bidinputdiv">
                  <lable>Form Date</lable>
                  <input type="date" value={bid_from} name="bid_from" onChange={(e) => setBid_From(e.target.value)}
                    className="pointinputbox" />
                </div>
              </div>
              <div className="col-6">
                <div className="bidinputdiv">
                  <lable>To Date</lable>
                  <input type="date" value={bid_to} name="bid_to" onChange={(e) => setBid_To(e.target.value)}
                    className="pointinputbox" id="jodi00" />
                </div>
              </div>
              <div className="col-12">
                <div className="pointinputbox text-center">
                  <buuton type="button" onClick={SubmitBiddingHistory} className="btn SUBMIT_button">SUBMIT</buuton>
                </div>
              </div>
            </div>
          </form>
          {
            isLoading ? (
              <>
                <div className="chat-window text-center">
                  <div style={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    margin: "auto",
                  }} className="spinner-border" role="status">
                    <span className="sr-only"></span>
                  </div>
                </div>
              </>
            ) : (
              <>
                {
                  noData ? (
                    <>
                      <div className='text-center mt-5'>
                        <p>No Data Found Here</p>
                      </div>
                    </>
                  ) : (

                    BiddingHistoryList?.length > 0 && (
                      <div className="tb-10">
                        <div className="table-responsive">
                          <table className="table table-striped starline-chart-table">
                            <thead>
                              <tr>
                                <th className="text-center">Game Name</th>
                                <th className="text-center">Pana</th>
                                <th className="text-center">Paana</th>
                                <th className="text-center">Session</th>
                                <th className="text-center">Points</th>
                                <th className="text-center">Bid</th>
                                <th className="text-center">Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                BiddingHistoryList?.map((BiddingHistoryListResult) => {
                                  return (
                                    <>
                                      <tr>
                                        <td>{BiddingHistoryListResult?.game_name}</td>
                                        <td>{BiddingHistoryListResult?.pana}</td>
                                        <td>{BiddingHistoryListResult?.digits}</td>
                                        <td>{BiddingHistoryListResult?.session}</td>
                                        <td>{BiddingHistoryListResult?.points}</td>
                                        <td><span>{BiddingHistoryListResult?.bid_tx_id}</span></td>
                                        <td>{BiddingHistoryListResult?.bid_date}</td>
                                      </tr>
                                    </>
                                  )
                                })
                              }

                            </tbody>
                          </table>
                        </div>
                      </div>
                    )


                  )
                }
              </>
            )
          }



        </div>

      </div>
      <br />
      <br />
      <Footer />
    </div>
  )
}

export default Bidding_History