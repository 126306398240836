import React, { useEffect, useState } from 'react'
import Header from '../../common/header/Header'
import Footer from '../../common/footer/Footer'
import { GetGameRate } from '../../../api/AllApi';

const Game_Rates = () => {
    const [GameRatesList, setGameRatesList] = useState([]);

    const HandleGameRatesList = async () => {
        const GameResultData = {
            app_key: "@34Y&D9(JE4dsi36f\$%#(tigergames!xyz)8fe8ef8ef8"
        }
        try {
            const response = await GetGameRate(GameResultData)
            setGameRatesList(response?.data?.game_rates[0])
        }
        catch (error) {
            console.log("error")
        }
    }
    console.log("GameRatesList", GameRatesList)
    useEffect(() => {
        HandleGameRatesList();
    }, [])
    return (
        <div className="wrapper">

            <div id="content">
                <Header />
                <div className="container">
                    <div className="tb-10" style={{ textAlign: "center" }}>
                        <h1 className="gdash3" style={{ fontSize: "22px" }}> Game Rate List</h1>
                        <span style={{ fontSize: "12px" }}>We Offer Best Rate in market - Full rate</span>
                        <div className="row game-list-inner">
                            <div className="col-12 game-rates">
                                <h2 style={{ fontSize: "16px", color: "var(--primary-light)" }}>Game Win Ratio For All Bids</h2>
                                <p>Single Digit : <span>{GameRatesList?.single_digit_val_1} - {GameRatesList?.single_digit_val_2}</span></p>
                                <p>jodi Digit: <span>{GameRatesList?.jodi_digit_val_1} - {GameRatesList?.jodi_digit_val_2}</span></p>
                                <p>Single Pana : <span>{GameRatesList?.single_pana_val_1} - {GameRatesList?.single_pana_val_2}</span></p>
                                <p>Double Pana : <span>{GameRatesList?.double_pana_val_1} - {GameRatesList?.double_pana_val_2}</span></p>
                                <p>Triple Pana : <span>{GameRatesList?.tripple_pana_val_1} - {GameRatesList?.tripple_pana_val_2}</span></p>
                                <p>half Sangam : <span>{GameRatesList?.half_sangam_val_1} - {GameRatesList?.half_sangam_val_2}</span></p>
                                <p>Full Sangam : <span>{GameRatesList?.full_sangam_val_1} - {GameRatesList?.full_sangam_val_2}</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br /><br /><br />
            <Footer />
        </div>
    )
}

export default Game_Rates