import React, { useState } from 'react'
import Header from '../../components/common/header/Header'
import Footer from '../../components/common/footer/Footer'
import logo from "../../assets/images/logo3.png"
import { Link, useNavigate } from 'react-router-dom'
import { UserLogin } from '../../api/Auth';
const Login = () => {
  const navigate = useNavigate()
  const [mobile, setMobile] = useState("")
  const [password, setPassword] = useState("")

  const HandleUserLogin = async (e) => {
    e.preventDefault();
    try {
      const credentials = {
        mobile,
        password,
        app_key:"@34Y&D9(JE4dsi36f$%#(tigergames!xyz)8fe8ef8ef8"
      }
      const response = await UserLogin(credentials)
      if(response?.data?.status == false){
        alert("Invalid login details")
      }
      else if(response?.data?.status == true){
        alert("Login successful")
        setMobile("")
        setPassword("")
        localStorage.setItem("user_id",response?.data?.user_id)
        localStorage.setItem("is_user",true)
        navigate("/")
      }
      console.log("HandleUserLogin",response)
    } catch (error) {
console.log("error",error)
    }

  }
  return (
    <div><div className="wrapper">
      {/* <!-- Sidebar  --> */}
      <div id="content">
        <Header />
        <div className="container">
          <div className="card tb-10">

            <div className="logo-center"><img src={logo} />
            </div>

            <div className="text-center tb-10">
              <h3>Welcome</h3>
              <span>Sign in to Continue</span>
            </div>
            <form action="" method="POST" onSubmit={HandleUserLogin}>
              <div className="form-group">
                <label for="mobile">Mobile Number:</label>
                <input type="text" className="form-control" name="mobile" value={mobile}
                  onChange={(e) => setMobile(e.target.value)} maxlength="10" minlength="10"
                  placeholder="Enter 10 Digit Phone Number" id="mobile" autocomplete="off" required />
              </div>
              <div className="form-group">
                <label for="pwd">Password:</label>
                <input type="password" className="form-control" name="password" value={password}
                  onChange={(e) => setPassword(e.target.value)} placeholder="Enter password" id="pwd"
                  autocomplete="off" required />
              </div>
              <button type="submit" className="btn btn-theme btn-login" name="login">Submit</button>

            </form>

            <div className="text-center tbmar-20">
              <p>Dont have an account?</p>
              <Link to="/register" className="btn btn-outline btn-login">Create Account Now</Link>
            </div>

          </div>
        </div>


      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div></div>
  )
}

export default Login