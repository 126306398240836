import React from 'react'
import Header from '../../common/header/Header'
import app_banner from "../../../assets/images/app-banner.png"
import Footer from '../../common/footer/Footer';
import { IoMdDownload } from "react-icons/io";
const Download_App = () => {
  return (
    <div className="wrapper">

    <div id="content">
      <Header/>
      <div className="container">
        <div className="tb-10">
          <div className="row">
            <div className="col-12">
              <div style={{display:"flex",alignItems:"center"}}>
                <img src={app_banner} style={{width:"150px",height:"auto",marginRight:"20px"}}/>
                <div>             
                  <p>DOWNLOAD OUR APP</p>
                  <p>Enjoy advanced features and options by downloading our Android app. access all the exciting
                    features anytime, anywhere!</p>
                  <a href="matka_103.apk">
                  <IoMdDownload size={22}/> Download Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br/><br/><br/>
    <Footer/>
    </div>
  )
}

export default Download_App