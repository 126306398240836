import React, { useEffect, useState } from 'react'
import Header from '../../common/header/Header'
import Footer from '../../common/footer/Footer'
import { GetUserProfile, UserProfileUpdate } from '../../../api/AllApi';

const My_Profile = () => {
  const [user_name, setUser_Name] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [wallet_balance, setWallet_Balance] = useState("");
  const user_id = localStorage.getItem("user_id");

  //<------ Get the User Profile here -------> 
  const HandleGetUserProfile = async () => {
    const UserData = {
      app_key: "@34Y&D9(JE4dsi36f$%#(tigergames!xyz)8fe8ef8ef8",
      user_id
    }
    try {
      const response = await GetUserProfile(UserData)
      console.log("HandleGetUserProfile", response?.data?.profile)
      setWallet_Balance(response?.data?.profile[0]?.wallet_balance)
      setMobile(response?.data?.profile[0]?.mobile)
      setEmail(response?.data?.profile[0]?.email)
      setUser_Name(response?.data?.profile[0]?.user_name)
    }
    catch (error) {
      console.log(error)
    }
  }

// <-------- Update the User Profile ----------->
  const HandleUserProfileUpdate = async (e) => {
    e.preventDefault();
    const UserProfileUpdateData = {
      email,
      user_name,
      app_key: "@34Y&D9(JE4dsi36f$%#(tigergames!xyz)8fe8ef8ef8",
      user_id
    }
    try {
      const response = await UserProfileUpdate(UserProfileUpdateData)
      console.log("HandleUserProfileUpdate", response)
      if (response?.data?.msg == "Your Profile Successfully Updated.") {
        alert("Your Profile Successfully Updated.")
        HandleGetUserProfile()
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  // <------ Render the User Profile ------------->
  useEffect(() => {
    HandleGetUserProfile()
  }, [])
  return (
    <div class="wrapper">

      <div id="content">
        <Header />
        <div class="container">
          <div class="tb-10">
            <form>

              <div className="form-group">
                <label for="name">Name:</label>
                <input type="text" className="form-control" name="user_name" value={user_name}
                  onChange={(e) => setUser_Name(e.target.value)}
                  maxlength="50" minlength="4"
                  placeholder="Enter Name" id="account_holder_name" autocomplete="off" required />
              </div>

              <div className="form-group">
                <label for="username">Mobile Number:</label>
                <input type="text" className="form-control" name="mobile" value={mobile}
                  maxlength="25" minlength="4" disabled
                  placeholder="Mobile Number" id="account_number" autocomplete="off" required />
              </div>

              <div className="form-group">
                <label for="username">Email:</label>
                <input type="text" className="form-control" name="email" value={email}
                  maxlength="25" minlength="4" disabled
                  placeholder="Email" id="account_number" autocomplete="off" required />
              </div>

              <div className="form-group">
                <label for="mobile">Wallet Amount:</label>
                <input type="text" className="form-control" value={wallet_balance} disabled
                  maxlength="11" minlength="11"
                  placeholder="Wallet Amount" id="ifsc" autocomplete="off" required />
              </div>
              <button type='button' onClick={HandleUserProfileUpdate}
                className="btn btn-theme btn-login">Submit</button>
            </form>
          </div>
        </div>
        <br />
        <br />
        <Footer />
      </div>
    </div>
  )
}

export default My_Profile