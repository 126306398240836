import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Header from '../../../common/header/Header'
import { GetPassbookList } from '../../../../api/AllApi';
import Footer from '../../../common/footer/Footer';
const All_Passbook = () => {
    const [AllPassbookList, setAllPassbookList] = useState([]);
    const user_id = localStorage.getItem("user_id");

    const HandleGetPassbookList = async () => {
        const GetDashBoarddata = {
            app_key: "@34Y&D9(JE4dsi36f$%#(tigergames!xyz)8fe8ef8ef8",
            user_id
        }
        const response = await GetPassbookList(GetDashBoarddata)
        console.log("HandleGetPassbookList", response?.data?.transaction_history)
        setAllPassbookList(response?.data?.transaction_history)
    }

    useEffect(() => {
        HandleGetPassbookList();
    }, [])
    return (
        <div className="wrapper">
            <div id="content">
                <Header />
                <div className='container'>
                    <div className='row'>
                        <div className="col-12">
                            <ul className="nav tabs_signup justify-content-start gap-2 mb-3">
                                <li className="nav-item">
                                    <Link className="nav-link active" aria-current="page" to="/all_passbook">ALL</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/deposit_passbook">IN</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/withdraw_password">OUT</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {
                        AllPassbookList?.length > 0 ? (
                            <>
                             <div className="text-center tb-10">
                        <h3 className="gdash3">Transaction History</h3>
                        <span style={{ fontSize: "12px" }}>Deposit and Withdraw History</span>
                    </div>
                    <div className="tb-10">
                        <div className="table-responsive">
                            <table className="table table-striped starline-chart-table">
                                <thead>
                                    <tr>
                                        <th className="text-center">Transaction ID</th>
                                        <th className="text-center">Amount</th>
                                        <th className="text-center">User Number</th>
                                        <th className="text-center">Transaction Status</th>
                                        <th className="text-center">Date & Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        AllPassbookList?.map((AllPassbookListResult) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td>{AllPassbookListResult?.transaction_id}</td>
                                                        <td>{AllPassbookListResult?.amount}</td>
                                                        <td>{AllPassbookListResult?.tx_request_number}</td>
                                                        <td><span>{AllPassbookListResult?.amount_status}</span></td>
                                                        <td>{AllPassbookListResult?.insert_date}</td>
                                                    </tr>
                                                </>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                            </>
                        ) : (
                            <div className='text-center mt-5'>
                            <p>Not data here...</p>
                            </div>

                        )
                    }
                   
                </div>
            </div>
<br/>
<br/>
<Footer/>
        </div>
    )
}

export default All_Passbook