import React from 'react'
import Header from '../../common/header/Header'

const Support = () => {
  return (
    <div className="wrapper">
    <div id="content">
     <Header/>
      <div className="container">
        <div className="tb-10">

         


          <div className="row game-list-inner">
            <div className="col-12">
              <a href="https://wa.me/+91 xxxxxx7894"
                className="mplist"><i className="fa fa-whatsapp"></i> Whatsapp Chat (Add Fund)</a>
            </div>
          </div>

          <div className="row game-list-inner">
            <div className="col-12">
              <a href="https://wa.me/+91 xxxxxx7894"
                className="mplist"><i className="fa fa-whatsapp"></i> Whatsapp Chat (Other Issues)</a>
            </div>
          </div>

          <div className="row game-list-inner">
            <div className="col-12">
              <a href="https://telegram.me/helpphonepaymatka" className="mplist"><i className="fa fa-telegram"></i> Telegram
                Chat</a>
            </div>
          </div>

        </div>

        <div className="tbmar-40 text-center">
          <span>Please use <b>Telegram</b> for a secure and safe chat experience. </span>

        </div>
      </div>
    </div>
    </div>
  )
}

export default Support