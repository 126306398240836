import axios from "axios";
import { API_BASE_URL } from "../config/ApiConfig";

// <---------------- Student Register Api's Function--------------->
export const UserRegister = async (userData) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api-user-registration`, userData)
        return response;
    } catch (error) {
        return error
    }

}

// <---------------- Student Register Api's Function--------------->
export const UserLogin = async (credentials) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api-user-login`, credentials)
        return response;
    } catch (error) {
        return error
    }

}