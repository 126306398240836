import React, { useState } from 'react'
import Header from '../../common/header/Header'
import Footer from '../../common/footer/Footer'
import { GetGameResult } from '../../../api/AllApi';

const Top_Winner_List = () => {
  const [bid_from, setBid_From] = useState("");
  const [bid_to, setBid_To] = useState("");
  const user_id = localStorage.getItem("user_id");

  const SubmitGameResult = async (e) => {
    e.preventDefault();
    const BiddingHistoryData = {
      app_key: "@34Y&D9(JE4dsi36f$%#(tigergames!xyz)8fe8ef8ef8",
        bid_from,
      bid_to,
      user_id
    }
    try {
      const response = await GetGameResult(BiddingHistoryData)
    } catch (error) {
      console.log("error", error)
    }
  }
  return (
    <div>
      <div className="wrapper">
        <div id="content">
          <Header />
          <div className="container">
            <form >
              <div className='row'>

                <div className="col-6">
                  <div className="bidinputdiv">
                    <lable>Form Date</lable>
                    <input type="date" value={bid_from} name="bid_from" onChange={(e) => setBid_From(e.target.value)}
                      className="pointinputbox" />
                  </div>
                </div>
                <div className="col-6">
                  <div className="bidinputdiv">
                    <lable>To Date</lable>
                    <input type="date" value={bid_to} name="bid_to" onChange={(e) => setBid_To(e.target.value)}
                      className="pointinputbox" id="jodi00" />
                  </div>
                </div>
                <div className="col-12">
                  <div className="bidinputdiv text-center">
                    <buuton type="button" onClick={SubmitGameResult} className=" btn SUBMIT_button">SUBMIT</buuton>
                  </div>
                </div>
              </div>
            </form>
            <div className="tb-10" style={{ textAlign: "center" }}>
              <h1 className="gdash3" style={{ fontSize: "22px" }}> Top Winner List</h1>
              <span style={{ fontSize: "12px" }}>List of Today's top winners with amount</span>

              <div className="row game-list-inner">
                <div className="col-12 game-rates">
                  <h2 style={{ fontSize: "16px", color: "var(--primary-light)" }}>Sh***********ya</h2>
                  <p>Amount : <span>760000</span></p>
                  <p>Game : <span>MILAN DAY CLOSE</span></p>
                  <p>Digit : <span>7</span></p>
                  <p>Time : <span>2024-07-24 05:19:52 PM</span></p>

                </div>
              </div>
              <div className="row game-list-inner">
                <div className="col-12 game-rates">
                  <h2 style={{ fontSize: "16px", color: "var(--primary-light)" }}>Sh***********ya</h2>
                  <p>Amount : <span>760000</span></p>
                  <p>Game : <span>KALYAN OPEN </span></p>
                  <p>Digit : <span>3</span></p>
                  <p>Time : <span>2024-07-24 04:31:22 PM</span></p>

                </div>
              </div>
              <div className="row game-list-inner">
                <div className="col-12 game-rates">
                  <h2 style={{ fontSize: "16px", color: "var(--primary-light)" }}>Sh*************ri</h2>
                  <p>Amount : <span>608000</span></p>
                  <p>Game : <span>MILAN DAY OPEN </span></p>
                  <p>Digit : <span>5</span></p>
                  <p>Time : <span>2024-07-24 03:23:07 PM</span></p>

                </div>
              </div>
              <div className="row game-list-inner">
                <div className="col-12 game-rates">
                  <h2 style={{ fontSize: "16px", color: "var(--primary-light)" }}>PR************ B</h2>
                  <p>Amount : <span>570000</span></p>
                  <p>Game : <span>MILAN DAY CLOSE</span></p>
                  <p>Digit : <span>7</span></p>
                  <p>Time : <span>2024-07-24 05:19:52 PM</span></p>

                </div>
              </div>
              <div className="row game-list-inner">
                <div className="col-12 game-rates">
                  <h2 style={{ fontSize: "16px", color: "var(--primary-light)" }}>PR************ B</h2>
                  <p>Amount : <span>399000</span></p>
                  <p>Game : <span>TIME BAZAR MORNING OPEN </span></p>
                  <p>Digit : <span>0</span></p>
                  <p>Time : <span>2024-07-24 12:15:43 PM</span></p>

                </div>
              </div>
              <div className="row game-list-inner">
                <div className="col-12 game-rates">
                  <h2 style={{ fontSize: "16px", color: "var(--primary-light)" }}>PR************ B</h2>
                  <p>Amount : <span>399000</span></p>
                  <p>Game : <span>KALYAN MORNING OPEN</span></p>
                  <p>Digit : <span>2</span></p>
                  <p>Time : <span>2024-07-24 11:11:35 AM</span></p>

                </div>
              </div>
              <div className="row game-list-inner">
                <div className="col-12 game-rates">
                  <h2 style={{ fontSize: "16px", color: "var(--primary-light)" }}>PR************ B</h2>
                  <p>Amount : <span>399000</span></p>
                  <p>Game : <span>TIME BAZAR OPEN</span></p>
                  <p>Digit : <span>0</span></p>
                  <p>Time : <span>2024-07-24 01:18:11 PM</span></p>

                </div>
              </div>
              <div className="row game-list-inner">
                <div className="col-12 game-rates">
                  <h2 style={{ fontSize: "16px", color: "var(--primary-light)" }}>PR************ B</h2>
                  <p>Amount : <span>399000</span></p>
                  <p>Game : <span>TIME BAZAR MORNING OPEN </span></p>
                  <p>Digit : <span>6</span></p>
                  <p>Time : <span>2024-07-24 12:39:53 PM</span></p>

                </div>
              </div>
              <div className="row game-list-inner">
                <div className="col-12 game-rates">
                  <h2 style={{ fontSize: "16px", color: "var(--primary-light)" }}>PR************ B</h2>
                  <p>Amount : <span>399000</span></p>
                  <p>Game : <span>MILAN MORNING OPEN</span></p>
                  <p>Digit : <span>5</span></p>
                  <p>Time : <span>2024-07-24 10:38:45 AM</span></p>

                </div>
              </div>
              <div className="row game-list-inner">
                <div className="col-12 game-rates">
                  <h2 style={{ fontSize: "16px", color: "var(--primary-light)" }}>Sh***********ya</h2>
                  <p>Amount : <span>380000</span></p>
                  <p>Game : <span>TIME BAZAR CLOSE</span></p>
                  <p>Digit : <span>5</span></p>
                  <p>Time : <span>2024-07-24 02:18:09 PM</span></p>

                </div>
              </div>
            </div>
          </div>
          <br /><br /><br />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Top_Winner_List