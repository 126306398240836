import React, { useState, useEffect } from 'react'
import Header from '../../common/header/Header'
import Footer from '../../common/footer/Footer'
import { FaRupeeSign } from "react-icons/fa";
import { GetCurrentDate, SubmitBid } from '../../../api/AllApi';
const Jodi = () => {
  const [Amount, setAmount] = useState("");
  const [TotalAmount, setTotalAmount] = useState({});
  console.log("Amount", Amount)
  const [isAmountActive, setIsAmountActive] = useState(null);
  const [Digits, setDigits] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [bid_date, setBidDate] = useState("");
  const [game_type, setGame_Type] = useState("");
  const user_id = localStorage.getItem("user_id");
  const game_id = localStorage.getItem("game_id");
  const game_name = localStorage.getItem("game_name");
  const [isInputChanged, setIsInputChanged] = useState(false);
  const [formData, setFormData] = useState([]);
  console.log("Digits", Digits)

  console.log("HandleIsAmountActive", isAmountActive)
  const handleAmountSelection = (amountValue, index) => {
    setAmount(amountValue);
    setIsAmountActive(index);
  };


  const handleInputChange = (inputId, digit) => {
    setTotalAmount((prevState) => ({
      ...prevState,
      [inputId]: (prevState[inputId] || 0) + Amount,
    }));

    setDigits((prevState) => ({
      ...prevState,
      [inputId]: digit,
    }));

    setFormData((prevData) => ({
      ...prevData,
      [inputId]: {
        ...prevData[inputId],
        points: (prevData[inputId]?.points || 0) + Amount,
        digits: digit,
        type: 'open',
      },
    }));
    setIsInputChanged(true);
  };


  const formattedData = Object.keys(formData).map((key) => formData[key]);

  const isButtonDisabled = !game_type || !isInputChanged;
  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataToSend = {
      bid_date: bid_date,
      gameid: game_id,
      user_id: user_id,
      Gamename: game_name,
      totalbit: grandTotal,
      pana: "Jodi",
      session: game_type,
      result: formattedData,
    };
    const data = {
      new_result: dataToSend,
      app_key: "@34Y&D9(JE4dsi36f$%#(tigergames!xyz)8fe8ef8ef8"
    }

    console.log('Data to send:', data);
    try {
      const response = await SubmitBid(data)
      console.log("handleSubmit", response?.data?.status == false)
      if (response?.data?.status === false) {
        alert("Sorry You Don't Have Sufficient Amount For This Bid")
      }
    } catch (error) {
      console.log("error", error)
    }
  };


  // <---------- Get the Current Date ------------>
  const HandleGetCurrentTime = async () => {
    const dataToSend = {
      app_key: "@34Y&D9(JE4dsi36f$%#(tigergames!xyz)8fe8ef8ef8",
      game_id,
      user_id
    };
    console.log('Data to send:', dataToSend);
    try {
      const response = await GetCurrentDate(dataToSend)
      setBidDate(response?.data?.date)
    } catch (error) {
      console.log("error", error)
    }
  };
  console.log("bid_date", bid_date)
  const calculateTotalAmount = () => {
    const total = Object.values(TotalAmount).reduce((acc, value) => acc + value, 0);
    setGrandTotal(total);
  };

  useEffect(() => {
    calculateTotalAmount();
  }, [TotalAmount]);

  useEffect(() => {
    HandleGetCurrentTime();
  }, []);

  const resetAmounts = () => {
    setTotalAmount({});
  };
  return (
    <div className="wrapper">

      <div id="content">
        <Header />
        <div className="container">
          <div className="card-full-page tb-10">

            <form action="" method="POST" className="myform">

              <div className="row bidoptions-list tb-10">
                <div className="col-6">
                  <a className="dateGameIDbox">
                    <p>{bid_date}</p>
                  </a>
                </div>

                <div className="col-6">
                  <select className="dateGameIDbox" name="game_type" onChange={(e) => setGame_Type(e.target.value)}>
                    <option value="CLOSE">{game_name} CLOSE</option>
                    <option value="OPEN">{game_name} OPEN</option>
                  </select>
                </div>

              </div>

              <div className="tb-10">
                <hr className="devider" />
              </div>

              <h3 className="subheading">Select Amount</h3>
              <div className="row bidoptions-list tb-10">
                <div className="col-3">
                  <a id="amount_5" value="5" name="Amount" data="5"
                    className={`bidamtbox ${isAmountActive == "0" ? "active_amounts" : ""}`}
                    onClick={() => {
                      handleAmountSelection(5, 0)
                    }}>
                    <p><FaRupeeSign size={12} /> 5</p>
                  </a>
                </div>

                <div className="col-3">
                  <a id="amount_5" value="10" name="Amount" data="5"
                    className={`bidamtbox ${isAmountActive == "1" ? "active_amounts" : ""}`}
                    onClick={() => {
                      handleAmountSelection(10, 1)
                    }}>
                    <p><FaRupeeSign size={12} /> 10</p>
                  </a>
                </div>

                <div className="col-3">
                  <a id="amount_5" value="50" name="Amount" data="5"
                    className={`bidamtbox ${isAmountActive == "2" ? "active_amounts" : ""}`}
                    onClick={() => {
                      handleAmountSelection(50, 2)
                    }}>
                    <p><FaRupeeSign size={12} /> 50</p>
                  </a>
                </div>
                <div className="col-3">
                  <a id="amount_100" data="100"
                    className={`bidamtbox ${isAmountActive == "3" ? "active_amounts" : ""}`}
                    onClick={() => {
                      handleAmountSelection(100, 3)
                    }}>
                    <p><FaRupeeSign size={12} /> 100</p>
                  </a>
                </div>
              </div>




              <div className="row bidoptions-list tb-10">
                <div className="col-3">
                  <a id="amount_200" data="200"
                    className={`bidamtbox ${isAmountActive == "3" ? "active_amounts" : ""}`}
                    onClick={() => {
                      handleAmountSelection(200, 3)
                    }}>
                    <p><FaRupeeSign size={12} /> 200</p>
                  </a>
                </div>

                <div className="col-3">
                  <a id="amount_500" data="500"
                    className={`bidamtbox ${isAmountActive == "4" ? "active_amounts" : ""}`}
                    onClick={() => {
                      handleAmountSelection(500, 4)
                    }}>
                    <p><FaRupeeSign size={12} /> 500</p>
                  </a>
                </div>

                <div className="col-3">
                  <a className={`bidamtbox ${isAmountActive == "5" ? "active_amounts" : ""}`}
                    onClick={() => {
                      handleAmountSelection(1000, 5)
                    }} id="amount_1000" data="1000">
                    <p><FaRupeeSign size={12} /> 1000</p>
                  </a>
                </div>
                <div className="col-3">
                  <a className={`bidamtbox ${isAmountActive == "6" ? "active_amounts" : ""}`}
                    onClick={() => {
                      handleAmountSelection(5000, 6)
                    }} id="amount_5000" data="5000">
                    <p><FaRupeeSign size={12} /> 5000</p>
                  </a>
                </div>
              </div>

              <div className="tb-10">
                <hr className="devider" />
              </div>
              <h3 className="subheading">Select Digits</h3>

              <div className="row bidoptions-list tb-10">


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>00</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi00'] || ''}
                      className="pointinputbox"
                      id="jodi00"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi00', "00");
                        }
                      }}
                    />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>01</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi01'] || ''}
                      className="pointinputbox"
                      id="jodi01"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi01', "01");
                        }
                      }}
                    />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>02</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi02'] || ''}
                      className="pointinputbox"
                      id="jodi02"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi02', "02");
                        }
                      }}
                    />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>03</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi03'] || ''}
                      className="pointinputbox"
                      id="jodi03"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi03', "03");
                        }
                      }}
                    />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>04</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi04'] || ''}
                      className="pointinputbox"
                      id="jodi04"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi04', "04");
                        }
                      }}
                    />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>05</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi05'] || ''}
                      className="pointinputbox"
                      id="jodi05"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi05', "05");
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>06</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi06'] || ''}
                      className="pointinputbox"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi06', "06");
                        }
                      }} id="jodi06" readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>07</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi07'] || ''}
                      className="pointinputbox"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi07', "07");
                        }
                      }} id="jodi07"  readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>08</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi08'] || ''}
                      className="pointinputbox"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi08', "08");
                        }
                      }} id="jodi08" readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>09</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi09'] || ''}
                      className="pointinputbox"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi09', "09");
                        }
                      }} id="jodi09" readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>10</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi10'] || ''}
                      className="pointinputbox"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi10', 10);
                        }
                      }} id="jodi10"  readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>11</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi11'] || ''}
                      className="pointinputbox"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi11', 11);
                        }
                      }} id="jodi11"  readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>12</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi12'] || ''}
                      className="pointinputbox"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi12', 12);
                        }
                      }} id="jodi12" readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>13</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi13'] || ''}
                      className="pointinputbox"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi13', 13);
                        }
                      }} id="jodi13"  readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>14</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi14'] || ''}
                      className="pointinputbox"
                      id="jodi14"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi14', 14);
                        }
                      }}  readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>15</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi15'] || ''}
                      className="pointinputbox"
                      id="jodi15"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi15', 15);
                        }
                      }}  readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>16</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi16'] || ''}
                      className="pointinputbox"
                      id="jodi16"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi16', 16);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>17</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi17'] || ''}
                      className="pointinputbox"
                      id="jodi17"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi17', 17);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>18</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi18'] || ''}
                      className="pointinputbox"
                      id="jodi18"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi18', 18);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>19</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi19'] || ''}
                      className="pointinputbox"
                      id="jodi19"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi19', 19);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>20</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi20'] || ''}
                      className="pointinputbox"
                      id="jodi20"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi20', 20);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>21</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi21'] || ''}
                      className="pointinputbox"
                      id="jodi21"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi21', 21);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>22</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi22'] || ''}
                      className="pointinputbox"
                      id="jodi22"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi22', 22);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>23</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi23'] || ''}
                      className="pointinputbox"
                      id="jodi23"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi23', 23);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>24</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi24'] || ''}
                      className="pointinputbox"
                      id="jodi24"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi24', "24");
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>25</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi25'] || ''}
                      className="pointinputbox"
                      id="jodi25"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi25', "25");
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>26</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi26'] || ''}
                      className="pointinputbox"
                      id="jodi26"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi26', "26");
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>27</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi27'] || ''}
                      className="pointinputbox"
                      id="jodi27"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi27', 27);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>28</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi28'] || ''}
                      className="pointinputbox"
                      id="jodi28"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi28', 28);
                        }
                      }}  readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>29</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi29'] || ''}
                      className="pointinputbox"
                      id="jodi29"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi29', 29);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>30</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi30'] || ''}
                      className="pointinputbox"
                      id="jodi30"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi30', 30);
                        }
                      }}readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>31</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi31'] || ''}
                      className="pointinputbox"
                      id="jodi31"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi31', 31);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>32</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi32'] || ''}
                      className="pointinputbox"
                      id="jodi32"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi32',32);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>33</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi33'] || ''}
                      className="pointinputbox"
                      id="jodi33"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi33', 33);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>34</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi34'] || ''}
                      className="pointinputbox"
                      id="jodi34"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi34', 34);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>35</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi35'] || ''}
                      className="pointinputbox"
                      id="jodi35"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi35', 35);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>36</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi36'] || ''}
                      className="pointinputbox"
                      id="jodi36"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi36', 36);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>37</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi37'] || ''}
                      className="pointinputbox"
                      id="jodi37"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi37', 37);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>38</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi38'] || ''}
                      className="pointinputbox"
                      id="jodi38"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi38', 38);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>39</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi39'] || ''}
                      className="pointinputbox"
                      id="jodi39"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi39', 39);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>40</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi40'] || ''}
                      className="pointinputbox"
                      id="jodi40"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi40', 40);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>41</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi41'] || ''}
                      className="pointinputbox"
                      id="jodi41"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi41', 41);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>42</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi42'] || ''}
                      className="pointinputbox"
                      id="jodi04"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi42', 42);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>43</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi43'] || ''}
                      className="pointinputbox"
                      id="jodi43"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi43', 43);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>44</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi44'] || ''}
                      className="pointinputbox"
                      id="jodi44"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi44', 44);
                        }
                      }}  readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>45</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi45'] || ''}
                      className="pointinputbox"
                      id="jodi45"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi45', 45);
                        }
                      }}  readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>46</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi46'] || ''}
                      className="pointinputbox"
                      id="jodi46"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi46', 46);
                        }
                      }}  readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>47</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi47'] || ''}
                      className="pointinputbox"
                      id="jodi47"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi47', 47);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>48</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi48'] || ''}
                      className="pointinputbox"
                      id="jodi48"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi48', 48);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>49</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi49'] || ''}
                      className="pointinputbox"
                      id="jodi49"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi49', 49);
                        }
                      }}  readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>50</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi50'] || ''}
                      className="pointinputbox"
                      id="jodi50"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi50', 50);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>51</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi51'] || ''}
                      className="pointinputbox"
                      id="jodi51"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi51', 51);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>52</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi52'] || ''}
                      className="pointinputbox"
                      id="jodi52"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi52', 52);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>53</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi53'] || ''}
                      className="pointinputbox"
                      id="jodi53"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi53', 53);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>54</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi54'] || ''}
                      className="pointinputbox"
                      id="jodi54"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi54', 54);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>55</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi55'] || ''}
                      className="pointinputbox"
                      id="jodi55"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi55', 55);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>56</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi56'] || ''}
                      className="pointinputbox"
                      id="jodi56"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi56', 56);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>57</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi57'] || ''}
                      className="pointinputbox"
                      id="jodi57"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi57', 57);
                        }
                      }}  readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>58</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi58'] || ''}
                      className="pointinputbox"
                      id="jodi58"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi58', 58);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>59</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi59'] || ''}
                      className="pointinputbox"
                      id="jodi59"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi59', 59);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>60</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi60'] || ''}
                      className="pointinputbox"
                      id="jodi60"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi60', 60);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>61</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi61'] || ''}
                      className="pointinputbox"
                      id="jodi61"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi61', 61);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>62</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi62'] || ''}
                      className="pointinputbox"
                      id="jodi62"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi62', 62);
                        }
                      }}  readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>63</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi63'] || ''}
                      className="pointinputbox"
                      id="jodi63"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi63', 63);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>64</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi64'] || ''}
                      className="pointinputbox"
                      id="jodi64"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi64', "64");
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>65</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi65'] || ''}
                      className="pointinputbox"
                      id="jodi65"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi65', 65);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>66</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi66'] || ''}
                      className="pointinputbox"
                      id="jodi66"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi66', 66);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>67</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi67'] || ''}
                      className="pointinputbox"
                      id="jodi67"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi67', 67);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>68</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi68'] || ''}
                      className="pointinputbox"
                      id="jodi68"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi68', 68);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>69</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi69'] || ''}
                      className="pointinputbox"
                      id="jodi69"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi69', 69);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>70</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi70'] || ''}
                      className="pointinputbox"
                      id="jodi04"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi70', 70);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>71</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi71'] || ''}
                      className="pointinputbox"
                      id="jodi71"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi71', 71);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>72</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi72'] || ''}
                      className="pointinputbox"
                      id="jodi72"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi72', 72);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>73</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi73'] || ''}
                      className="pointinputbox"
                      id="jodi73"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi73', 73);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>74</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi74'] || ''}
                      className="pointinputbox"
                      id="jodi74"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi74', 74);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>75</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi75'] || ''}
                      className="pointinputbox"
                      id="jodi75"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi75', 75);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>76</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi76'] || ''}
                      className="pointinputbox"
                      id="jodi76"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi76', 76);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>77</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi77'] || ''}
                      className="pointinputbox"
                      id="jodi77"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi77', 77);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>78</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi78'] || ''}
                      className="pointinputbox"
                      id="jodi78"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi78', 78);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>79</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi79'] || ''}
                      className="pointinputbox"
                      id="jodi79"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi79', 79);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>80</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi80'] || ''}
                      className="pointinputbox"
                      id="jodi80"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi80', 80);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>81</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi81'] || ''}
                      className="pointinputbox"
                      id="jodi81"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi81', 81);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>82</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi82'] || ''}
                      className="pointinputbox"
                      id="jodi82"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi82', 82);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>83</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi83'] || ''}
                      className="pointinputbox"
                      id="jodi83"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi83', 83);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>84</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi84'] || ''}
                      className="pointinputbox"
                      id="jodi84"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi84', 84);
                        }
                      }} readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>85</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi85'] || ''}
                      className="pointinputbox"
                      id="jodi85"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi85', 85);
                        }
                      }}  readonly="" />
                  </div>
                </div>


                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>86</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi86'] || ''}
                      className="pointinputbox"
                      id="jodi86"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi86', 86);
                        }
                      }} readonly="" />
                  </div>
                </div>
                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>87</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi87'] || ''}
                      className="pointinputbox"
                      id="jodi87"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi87', 87);
                        }
                      }} readonly="" />
                  </div>
                </div>
                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>88</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi88'] || ''}
                      className="pointinputbox"
                      id="jodi88"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi88', 88);
                        }
                      }}  readonly="" />
                  </div>
                </div>
                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>89</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi89'] || ''}
                      className="pointinputbox"
                      id="jodi89"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi89', 89);
                        }
                      }}  readonly="" />
                  </div>
                </div>
                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>90</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi90'] || ''}
                      className="pointinputbox"
                      id="jodi90"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi90', 90);
                        }
                      }}  readonly="" />
                  </div>
                </div>
                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>91</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi91'] || ''}
                      className="pointinputbox"
                      id="jodi91"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi91', 91);
                        }
                      }} readonly="" />
                  </div>
                </div>
                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>92</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi92'] || ''}
                      className="pointinputbox"
                      id="jodi92"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi92', 92);
                        }
                      }} readonly="" />
                  </div>
                </div>
                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>93</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi93'] || ''}
                      className="pointinputbox"
                      id="jodi93"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi93', 93);
                        }
                      }} readonly="" />
                  </div>
                </div>
                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>94</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi94'] || ''}
                      className="pointinputbox"
                      id="jodi94"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi94', 94);
                        }
                      }} readonly="" />
                  </div>
                </div>
                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>95</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi95'] || ''}
                      className="pointinputbox"
                      id="jodi95"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi95', 95);
                        }
                      }} readonly="" />
                  </div>
                </div>
                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>96</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi96'] || ''}
                      className="pointinputbox"
                      id="jodi96"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi96', 96);
                        }
                      }} readonly="" />
                  </div>
                </div>
                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>97</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi97'] || ''}
                      className="pointinputbox"
                      id="jodi97"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi97', 97);
                        }
                      }} readonly="" />
                  </div>
                </div>
                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>98</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi98'] || ''}
                      className="pointinputbox"
                      id="jodi98"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi98', 98);
                        }
                      }} readonly="" />
                  </div>
                </div>
                <div className="col-3">
                  <div className="bidinputdiv">
                    <lable>99</lable>
                    <input
                      type="text"
                      value={TotalAmount['jodi99'] || ''}
                      className="pointinputbox"
                      id="jodi99"
                      name="Digits"
                      readOnly
                      onClick={() => {
                        if (!Amount) {
                          alert('Please select an amount before proceeding.');
                        } else {
                          handleInputChange('jodi99', 99);
                        }
                      }}  readonly="" />
                  </div>
                </div>
              </div>
              <input type="hidden" id="total_point" name="total_point" value="" />
              <input type="hidden" id="selected_amount" value="" />
              <input type="hidden" name="gid" value="91" />
              <input type="hidden" name="pgid" value="52" />
              <input type="hidden" name="dgame" value="open" />
              <div className="tbmar-20 text-center">
                <p>Total Points : <a id="total_point2">{grandTotal}</a></p>
              </div>
              <div className="row bidoptions-list tb-10">
                                <div className="col-6">
                                    <button className="btn btn-light btn-streched" onClick={resetAmounts} type="reset">Reset</button>
                                </div>

                                <div className="col-6">
                                    <button className="btn btn-theme btn-streched" disabled={isButtonDisabled} type="button" onClick={handleSubmit}
                                     name="single_submit">Submit</button>
                                </div>

                            </div>
            </form>

            <br /><br /><br /><br /><br /><br />
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Footer />
    </div>
  )
}

export default Jodi