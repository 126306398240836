import React, { useState } from 'react'
import { FaArrowLeft } from "react-icons/fa";
import { FaBarsStaggered } from "react-icons/fa6";
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { FaHome } from "react-icons/fa";
import { SiBookstack } from "react-icons/si";
import { TbBooks } from "react-icons/tb";
import { ImBooks } from "react-icons/im";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { FaBell } from "react-icons/fa";
import { PiQuestionMarkBold } from "react-icons/pi";
import { CgGames } from "react-icons/cg";
import { FaUserAlt } from "react-icons/fa";
import { MdSupportAgent } from "react-icons/md";
import "../../../assets/css/responsive.css"
import "../../../assets/css/style.css";
import { FaArrowRight } from "react-icons/fa";
import { Link,useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import logo from "../../../assets/images/logo.png"
const Header = () => {
  const navigate = useNavigate()
  const [show, setShow] = useState(false);
  const is_user = localStorage.getItem("is_user");

  const [LogoutShow, setLogoutShow] = useState(false);

  const UserLogout = () => {
    localStorage.removeItem("is_user")
    navigate("/login")
    setLogoutShow(false)
    localStorage.getItem("is_user")
  }

  const checkUserLoggedIn = (event) => {
    if (!is_user) {
      event.preventDefault(); 
      alert('User not logged in.');
    }
  };
  

  return (
    <div class="wrapper">
      {/* <!-- Sidebar  --> */}
      <nav id="sidebar">
        <Offcanvas show={show} onHide={() => setShow(false)}>
          <Offcanvas.Header>
            <div id="dismiss">
              <FaArrowLeft size={22} onClick={() => setShow(false)} />
            </div>
            <Offcanvas.Title class="sidebar-header"> <span class="Uname">Hello User</span>
              <span class="Umobile">Welcome Back</span></Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul className="list-unstyled components sideMenu">
              <li>
                <Link to="/">
                  <FaHome size={22} /> <span>Home</span>
                </Link>
              </li>
              <li>
                <Link to="/my_profile" onClick={(e) => checkUserLoggedIn(e)}>
                  <FaUserAlt size={22} /> <span>Profile</span>
                </Link>
              </li>
              <li>
                <Link to="/my_bid" onClick={(e) => checkUserLoggedIn(e)}>
                  <TbBooks size={22} /> <span>My Bid</span>
                </Link>
              </li>
              <li>
                <Link to="/all_passbook" onClick={(e) => checkUserLoggedIn(e)}>
                  <SiBookstack size={22} /> <span>Passbook</span>
                </Link>
              </li>
              <li>
                <a href={`https://www.whatsapp.com/download`} onClick={(e) => checkUserLoggedIn(e)}>
                  <MdSupportAgent size={22} /> <span>Support</span>
                </a>
              </li>
              <li>
                <Link to="/fund_history" onClick={(e) => checkUserLoggedIn(e)}>
                  <FaRegMoneyBillAlt size={22} /><span>Funds</span>
                </Link>
              </li>
              <li>
                <Link to="/notifications" onClick={(e) => checkUserLoggedIn(e)}>
                  <FaBell size={22} /> <span>Notifications</span>
                </Link>
              </li>
              <li>
                <Link to="/game_rates" onClick={(e) => checkUserLoggedIn(e)}>
                  <CgGames size={22} /> <span>Game Rates</span>
                </Link>
              </li>
              <li>
                <Link to="/enquiry" onClick={(e) => checkUserLoggedIn(e)}>
                  <PiQuestionMarkBold size={22} /> <span>Enquiry</span>
                </Link>
              </li>
            </ul>

            {
              is_user  && (
            <ul class="list-unstyled CTAs">
              <li>
                <Link to="/my_profile" class="download">My Profile</Link>
              </li>

              <li>
                <a href="https://kalyan777.co/admin_backend/build.apk" style={{cursor:"pointer"}} class="download">Download</a>
              </li>
            </ul>
              )
            }
          </Offcanvas.Body>
        </Offcanvas>
      </nav>
      <nav class="navbar navbar-expand-lg">
        <div class="container-fluid">

          <button type="button" id="sidebarCollapse" class="btn menu-btn" title="Menu">
            <i class="fa fa-align-left" style={{ WebkitTextStrokeWidth: "2px var(--primary-light)" }}></i>
            <span onClick={() => setShow(true)}><FaBarsStaggered size={23} /></span>
          </button>

          <span style={{gap:"20px"}}><img src={logo} 
          style={{width:"50px",height:"50px",borderRadius:"50%"}}/></span>
          {
            is_user ? (
              <p class="btn btn-white d-inline-block ml-auto" type="button" onClick={() => setLogoutShow(true)}>
                <FaArrowRight size={22} />&nbsp;&nbsp;<span>Logout</span>
              </p>
            ) : (
              <Link to="/login" class="btn btn-white d-inline-block ml-auto" type="button">
                <FaArrowRight size={22} />&nbsp;&nbsp;<span>Login</span>
              </Link>
            )
          }

        </div>
      </nav>
      {/* <------- Accept Modal here ---------> */}
      <Modal show={LogoutShow} onHide={() => setLogoutShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Are You Sure For Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are You Sure For Logout !!!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setLogoutShow(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={() => UserLogout()}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Header