import React from 'react'
import Header from '../../common/header/Header'
import Footer from '../../common/footer/Footer'
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { SiBookstack } from "react-icons/si";
import { TbBooks } from "react-icons/tb";
import { ImBooks } from "react-icons/im";
import { Link } from 'react-router-dom';

const My_Bid = () => {
  return (
    <div className="wrapper">
   
    <div id="content">
        <Header/>
        <div className="container">
                    <div className="tb-10">

                        <div className="row game-list-inner">
                            <div className="col-12">
                                <Link to="/bidding_history" className="mplist"><FaRegMoneyBillAlt size={22}/> Bid History</Link>
                            </div>
                        </div>
                        {/* <div className="row game-list-inner">
                            <div className="col-12">
                                <Link to="/top_winner_list" className="mplist"><SiBookstack size={22}/>Game Result</Link>
                            </div>
                        </div> */}

                        <div className="row game-list-inner">
                            <div className="col-12">
                                <Link to="/starline_bidding_history" className="mplist"><TbBooks size={22}/>King Starline Bidding History</Link>
                            </div>
                        </div>
                    </div>
                </div>

    </div>
    <br/>
    <br/>
    <Footer/>
  </div>
  )
}

export default My_Bid